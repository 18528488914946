/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as msRest from "@azure/ms-rest-js";
import * as Models from "./models";
import * as Mappers from "./models/mappers";
import * as Parameters from "./models/parameters";
import { TeamGateApiSecuredContext } from "./teamGateApiSecuredContext";
var TeamGateApiSecured = /** @class */ (function (_super) {
    __extends(TeamGateApiSecured, _super);
    /**
     * Initializes a new instance of the TeamGateApiSecured class.
     * @param credentials Subscription credentials which uniquely identify client subscription.
     * @param [options] The parameter options
     */
    function TeamGateApiSecured(credentials, options) {
        return _super.call(this, credentials, options) || this;
    }
    TeamGateApiSecured.prototype.getActionItems = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getActionItemsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.createActionItem = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, createActionItemOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.deleteActionItem = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, deleteActionItemOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getActionItemDocuments = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getActionItemDocumentsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.hasActionItems = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, hasActionItemsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateActionItemMainPart = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateActionItemMainPartOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateActionItemWorkerPart = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateActionItemWorkerPartOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.prepareActionItem = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, prepareActionItemOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.denyActionItem = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, denyActionItemOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.approveActionItem = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, approveActionItemOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.uploadActionItemDocument = function (actionItemId, options, callback) {
        return this.sendOperationRequest({
            actionItemId: actionItemId,
            options: options
        }, uploadActionItemDocumentOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.deleteActionItemDocument = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, deleteActionItemDocumentOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getActionItems1 = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getActionItems1OperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getApiKeys = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getApiKeysOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.addApiKey = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, addApiKeyOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateApiKey = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateApiKeyOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.generateKey = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, generateKeyOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getApprovalsForGate = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getApprovalsForGateOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getApprovalsByUniqueId = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getApprovalsByUniqueIdOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateApproval = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateApprovalOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.deleteApproval = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, deleteApprovalOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.createChecklistApproval = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, createChecklistApprovalOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.createCheckListItemDeliverableApproval = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, createCheckListItemDeliverableApprovalOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getApprovals = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getApprovalsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getAppTasks = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getAppTasksOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateTaskState = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateTaskStateOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getAttachments = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getAttachmentsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getLog = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getLogOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getFunctionalOnlyChecklists = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getFunctionalOnlyChecklistsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateChecklist = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateChecklistOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.deleteChecklist = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, deleteChecklistOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateChecklistPostFix = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateChecklistPostFixOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getChecklistInfo = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getChecklistInfoOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getAllCheckLists = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getAllCheckListsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getChecklistsSmallModel = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getChecklistsSmallModelOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.prepareFunctionChecklist = function (id, options, callback) {
        return this.sendOperationRequest({
            id: id,
            options: options
        }, prepareFunctionChecklistOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.approveFunctionChecklist = function (id, options, callback) {
        return this.sendOperationRequest({
            id: id,
            options: options
        }, approveFunctionChecklistOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.denyFunctionChecklist = function (id, options, callback) {
        return this.sendOperationRequest({
            id: id,
            options: options
        }, denyFunctionChecklistOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.undoChecklistApproval = function (id, options, callback) {
        return this.sendOperationRequest({
            id: id,
            options: options
        }, undoChecklistApprovalOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getChecklistsLookupInfo = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getChecklistsLookupInfoOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getFunctionalChecklistsLookupInfo = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getFunctionalChecklistsLookupInfoOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.copyChecklist = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, copyChecklistOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getChecklistStatusOverview = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getChecklistStatusOverviewOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getDeliverableChecklistItems = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getDeliverableChecklistItemsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getQuestionChecklistItems = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getQuestionChecklistItemsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getChecklistItemsLookupInfo = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getChecklistItemsLookupInfoOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getDeliverableApprovedChecklistItems = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getDeliverableApprovedChecklistItemsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getQuestionApprovedChecklistItems = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getQuestionApprovedChecklistItemsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getApprovedChecklistItem = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getApprovedChecklistItemOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateFunctionListGatePreparationItem = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateFunctionListGatePreparationItemOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateAssessorGatePreparationItem = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateAssessorGatePreparationItemOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateAssessorNeedAttention = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateAssessorNeedAttentionOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updatePrjManagerPartGatePreparationItem = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updatePrjManagerPartGatePreparationItemOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updatePrjManagerNeedAttention = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updatePrjManagerNeedAttentionOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getAllChecklistItemDeliverableDocuments = function (checkListItemId, options, callback) {
        return this.sendOperationRequest({
            checkListItemId: checkListItemId,
            options: options
        }, getAllChecklistItemDeliverableDocumentsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.uploadChecklistItemDeliverableDocument = function (checkListItemId, options, callback) {
        return this.sendOperationRequest({
            checkListItemId: checkListItemId,
            options: options
        }, uploadChecklistItemDeliverableDocumentOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.removeChecklistItemDeliverableDocument = function (checkListItemId, options, callback) {
        return this.sendOperationRequest({
            checkListItemId: checkListItemId,
            options: options
        }, removeChecklistItemDeliverableDocumentOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateChecklistItemDeliverableFileComment = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateChecklistItemDeliverableFileCommentOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.addChecklistItemDeliverableLink = function (checkListItemId, options, callback) {
        return this.sendOperationRequest({
            checkListItemId: checkListItemId,
            options: options
        }, addChecklistItemDeliverableLinkOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.allowChecklistItemDeliverableWithoutDocument = function (checkListItemId, options, callback) {
        return this.sendOperationRequest({
            checkListItemId: checkListItemId,
            options: options
        }, allowChecklistItemDeliverableWithoutDocumentOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.copyChecklistFunctionComments = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, copyChecklistFunctionCommentsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getMasterCheckListItemFunctions = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getMasterCheckListItemFunctionsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.okPrjManagerPartGatePreparationItems = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, okPrjManagerPartGatePreparationItemsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.okPrjManagerPartGatePreparationItem = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, okPrjManagerPartGatePreparationItemOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.okAssessorPartGatePreparationItems = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, okAssessorPartGatePreparationItemsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.okAssessorPartGatePreparationItem = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, okAssessorPartGatePreparationItemOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.okFunctionPartGatePreparationItem = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, okFunctionPartGatePreparationItemOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.isFunctionOkButtonVisiable = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, isFunctionOkButtonVisiableOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getGateDeliverables = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getGateDeliverablesOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getGateDeliverableShortModels = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getGateDeliverableShortModelsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.prepareDeliverable = function (id, options, callback) {
        return this.sendOperationRequest({
            id: id,
            options: options
        }, prepareDeliverableOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.approveDeliverable = function (id, options, callback) {
        return this.sendOperationRequest({
            id: id,
            options: options
        }, approveDeliverableOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.denyDeliverable = function (id, options, callback) {
        return this.sendOperationRequest({
            id: id,
            options: options
        }, denyDeliverableOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.undoApproval = function (id, options, callback) {
        return this.sendOperationRequest({
            id: id,
            options: options
        }, undoApprovalOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.undoDeliverableApprovalWithoutInput = function (id, options, callback) {
        return this.sendOperationRequest({
            id: id,
            options: options
        }, undoDeliverableApprovalWithoutInputOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.undoMasterDeliverable = function (id, options, callback) {
        return this.sendOperationRequest({
            id: id,
            options: options
        }, undoMasterDeliverableOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateChecklistItemDeliverableDenyComment = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateChecklistItemDeliverableDenyCommentOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.setApprovalApplicationForDeliverable = function (id, options, callback) {
        return this.sendOperationRequest({
            id: id,
            options: options
        }, setApprovalApplicationForDeliverableOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getGateDeliverablesLookupInfo = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getGateDeliverablesLookupInfoOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.downloadDeliverableProviderEmailTemplate = function (id, options, callback) {
        return this.sendOperationRequest({
            id: id,
            options: options
        }, downloadDeliverableProviderEmailTemplateOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getChecklistItems = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getChecklistItemsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getDeliverablesTemplateStatusConfiguration = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getDeliverablesTemplateStatusConfigurationOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getDeliverablesTemplateApprovalConfiguration = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getDeliverablesTemplateApprovalConfigurationOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.changeCLITemplateDeliverableGateAssessmentStatus = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, changeCLITemplateDeliverableGateAssessmentStatusOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.createDeliverableTemplateFunctionsRange = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, createDeliverableTemplateFunctionsRangeOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.deleteDeliverableTemplateFunctionsRange = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, deleteDeliverableTemplateFunctionsRangeOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateDeliverableTemplateFunctionsRange = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateDeliverableTemplateFunctionsRangeOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateDocumentDefinition = function (id, options, callback) {
        return this.sendOperationRequest({
            id: id,
            options: options
        }, updateDocumentDefinitionOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getDeliverablesDocumentConfiguration = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getDeliverablesDocumentConfigurationOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getChecklists = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getChecklistsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getChecklistsRestApi = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getChecklistsRestApiOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.downloadChecklist = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, downloadChecklistOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.uploadChecklistTemplateAsync = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, uploadChecklistTemplateAsyncOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getChecklistsTemplateApprovalConfiguration = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getChecklistsTemplateApprovalConfigurationOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.createChecklistTemplateFunctionsRange = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, createChecklistTemplateFunctionsRangeOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.deleteChecklistTemplateFunctionsRange = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, deleteChecklistTemplateFunctionsRangeOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateChecklistTemplateFunctionsRange = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateChecklistTemplateFunctionsRangeOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getConfiguration = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getConfigurationOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.putPrincipalConfigurationModel = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, putPrincipalConfigurationModelOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getPrincipalConfigurationModel = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getPrincipalConfigurationModelOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getAppConfigurations = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getAppConfigurationsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getOrgUnitConfigurations = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getOrgUnitConfigurationsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateAppConfiguration = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateAppConfigurationOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateOrgUnitConfiguration = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateOrgUnitConfigurationOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.addOrgUnitConfiguration = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, addOrgUnitConfigurationOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.deleteOrgUnitConfiguration = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, deleteOrgUnitConfigurationOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getUseColorBlindSchemeValue = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getUseColorBlindSchemeValueOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.addOrUpdateUseColorBlindSchemeValue = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, addOrUpdateUseColorBlindSchemeValueOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getPrincipalFilterConfiguration = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getPrincipalFilterConfigurationOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.addOrUpdatePrincipalFilterConfiguration = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, addOrUpdatePrincipalFilterConfigurationOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.deletePrincipalFilterConfiguration = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, deletePrincipalFilterConfigurationOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getAssignmentsForCurrentPrincipal = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getAssignmentsForCurrentPrincipalOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getNotificationsForCurrentPrincipal = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getNotificationsForCurrentPrincipalOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.dismissNotification = function (id, options, callback) {
        return this.sendOperationRequest({
            id: id,
            options: options
        }, dismissNotificationOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getManagedAndAccessibleProjectsForCurrentPrincipal = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getManagedAndAccessibleProjectsForCurrentPrincipalOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getProjectsForCurrentPrincipal = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getProjectsForCurrentPrincipalOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getFunctionalChecklistsForCurrentPrincipal = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getFunctionalChecklistsForCurrentPrincipalOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getDeliverablesRestApi = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getDeliverablesRestApiOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getNotRelevantExternalDeliverableIdsRestApi = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getNotRelevantExternalDeliverableIdsRestApiOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getExternalDeliverablesWithRelatedPrincipalsRestApi = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getExternalDeliverablesWithRelatedPrincipalsRestApiOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.setExternalRestApi = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, setExternalRestApiOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.resetExternalRestApi = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, resetExternalRestApiOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.setPreparedRestApi = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, setPreparedRestApiOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.resetPreparedRestApi = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, resetPreparedRestApiOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.setDraftStatusRestApi = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, setDraftStatusRestApiOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.setInApprovalStatusRestApi = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, setInApprovalStatusRestApiOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.setApprovedStatusStepOneRestApi = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, setApprovedStatusStepOneRestApiOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.setApprovedStatusStepTwoRestApi = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, setApprovedStatusStepTwoRestApiOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.undoRestApi = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, undoRestApiOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getDocumentCategoriesForProject = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getDocumentCategoriesForProjectOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.createDocumentCategory = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, createDocumentCategoryOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateDocumentCategory = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateDocumentCategoryOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.deleteDocumentCategory = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, deleteDocumentCategoryOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.setDocumentCategoryToProject = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, setDocumentCategoryToProjectOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.deleteDocumentCategoryFromProject = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, deleteDocumentCategoryFromProjectOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getDocumentCategoriesForProjectLookup = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getDocumentCategoriesForProjectLookupOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getDocumentDefinitions = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getDocumentDefinitionsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getStatusEnum = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getStatusEnumOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getCheckListItemRatingEnum = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getCheckListItemRatingEnumOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getGateRecommendationEnum = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getGateRecommendationEnumOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getActionItemDeadlineTypeEnum = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getActionItemDeadlineTypeEnumOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getProjectSecurityLevelEnum = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getProjectSecurityLevelEnumOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getProjectCategoryEnum = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getProjectCategoryEnumOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getProjectStatusEnum = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getProjectStatusEnumOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getAssignmentStateEnum = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getAssignmentStateEnumOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.addFavoriteProjectForCurrentPrincipal = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, addFavoriteProjectForCurrentPrincipalOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.deleteFavoriteProjectForCurrentPrincipal = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, deleteFavoriteProjectForCurrentPrincipalOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getFavoriteProjectsForCurrentPrincipal = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getFavoriteProjectsForCurrentPrincipalOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getFileInformation = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getFileInformationOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getFunctions = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getFunctionsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getGateFunctions = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getGateFunctionsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getProjectTemplateFunctions = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getProjectTemplateFunctionsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getProjectFunctions = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getProjectFunctionsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getGate = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getGateOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.createGate = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, createGateOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateGate = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateGateOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.deleteGate = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, deleteGateOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.gateDisplayNameLookupModels = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, gateDisplayNameLookupModelsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getGatesList = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getGatesListOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getGatesListLookup = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getGatesListLookupOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.redoGate = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, redoGateOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.splitGate = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, splitGateOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.skipGate = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, skipGateOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.joinGate = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, joinGateOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.setGateOnHold = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, setGateOnHoldOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.undoGateOnHold = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, undoGateOnHoldOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getApprovedGatesList = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getApprovedGatesListOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.prepareGate = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, prepareGateOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.denyGate = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, denyGateOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.approveGate = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, approveGateOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getGatesLookupInfo = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getGatesLookupInfoOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getNextGateOptions = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getNextGateOptionsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getGateInitData = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getGateInitDataOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getGateDescriptionData = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getGateDescriptionDataOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateRelatedFunction = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateRelatedFunctionOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.enableDisableRelatedFunction = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, enableDisableRelatedFunctionOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.putCurrentProjectGate = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, putCurrentProjectGateOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getCurrentProjectGate = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getCurrentProjectGateOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.hasGateMFilesDeliverables = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, hasGateMFilesDeliverablesOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getGateAssessmentKeyDeliverables = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getGateAssessmentKeyDeliverablesOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getGateAssessmentKeyDeliverable = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getGateAssessmentKeyDeliverableOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateAssessorDeliverable = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateAssessorDeliverableOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateProjectManagerDeliverable = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateProjectManagerDeliverableOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getGateAssessmentFunctionSummaries = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getGateAssessmentFunctionSummariesOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateAssessorFunctionSummary = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateAssessorFunctionSummaryOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateProjectManagerFunctionSummary = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateProjectManagerFunctionSummaryOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getGateAssessmentExecutiveQuestions = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getGateAssessmentExecutiveQuestionsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getGateAssessmentExecutiveQuestion = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getGateAssessmentExecutiveQuestionOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateAssessorExecutiveQuestion = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateAssessorExecutiveQuestionOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateProjectManagerExecutiveQuestion = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateProjectManagerExecutiveQuestionOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.okDeliverableAssessorPart = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, okDeliverableAssessorPartOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.okAssessmentMasterCheckListItemAssessorParts = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, okAssessmentMasterCheckListItemAssessorPartsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.okExecutiveQuestionAssessorPart = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, okExecutiveQuestionAssessorPartOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.okFunctionSummaryAssessorPart = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, okFunctionSummaryAssessorPartOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getGateMeeting = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getGateMeetingOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateGateMeetingDecision = function (id, options, callback) {
        return this.sendOperationRequest({
            id: id,
            options: options
        }, updateGateMeetingDecisionOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.setGateMeetingStatusToInApproval = function (id, options, callback) {
        return this.sendOperationRequest({
            id: id,
            options: options
        }, setGateMeetingStatusToInApprovalOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.approveGateMeetingDecision = function (id, options, callback) {
        return this.sendOperationRequest({
            id: id,
            options: options
        }, approveGateMeetingDecisionOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.denyGateMeetingDecision = function (id, options, callback) {
        return this.sendOperationRequest({
            id: id,
            options: options
        }, denyGateMeetingDecisionOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.createGateMeeting = function (gateId, options, callback) {
        return this.sendOperationRequest({
            gateId: gateId,
            options: options
        }, createGateMeetingOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getGateKeyDeliverables = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getGateKeyDeliverablesOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateAssessorMeetingDeliverable = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateAssessorMeetingDeliverableOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getGateFunctionSummaries = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getGateFunctionSummariesOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateAssessorMeetingFunctionSummary = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateAssessorMeetingFunctionSummaryOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getGateExecutiveQuestions = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getGateExecutiveQuestionsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateAssessorMeetingExecutiveQuestion = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateAssessorMeetingExecutiveQuestionOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.okAssessorMeetingPartExecutiveQuestion = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, okAssessorMeetingPartExecutiveQuestionOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.okAssessorMeetingPartFunctionSummary = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, okAssessorMeetingPartFunctionSummaryOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.okDeliverableAssessorMeetingPart = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, okDeliverableAssessorMeetingPartOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.okMeetingMasterCheckListItemAssessorParts = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, okMeetingMasterCheckListItemAssessorPartsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getRecommendation = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getRecommendationOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateRecommendationDecision = function (id, options, callback) {
        return this.sendOperationRequest({
            id: id,
            options: options
        }, updateRecommendationDecisionOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.approveRecommendation = function (id, options, callback) {
        return this.sendOperationRequest({
            id: id,
            options: options
        }, approveRecommendationOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.createRecommendation = function (gateId, options, callback) {
        return this.sendOperationRequest({
            gateId: gateId,
            options: options
        }, createRecommendationOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.downloadEmailTemplate = function (id, options, callback) {
        return this.sendOperationRequest({
            id: id,
            options: options
        }, downloadEmailTemplateOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getGates = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getGatesOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getGatesRestApi = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getGatesRestApiOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.authorizeCheck = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, authorizeCheckOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.removeAllProjects = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, removeAllProjectsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.removeAllTemplates = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, removeAllTemplatesOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.appVersion = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, appVersionOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.helpLink = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, helpLinkOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.jobGetList = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, jobGetListOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.jobStart = function (jobName, options, callback) {
        return this.sendOperationRequest({
            jobName: jobName,
            options: options
        }, jobStartOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.jobGetParameters = function (jobName, options, callback) {
        return this.sendOperationRequest({
            jobName: jobName,
            options: options
        }, jobGetParametersOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.jobSaveParameters = function (triggerName, options, callback) {
        return this.sendOperationRequest({
            triggerName: triggerName,
            options: options
        }, jobSaveParametersOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.jobCreateTrigger = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, jobCreateTriggerOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.jobDeleteTrigger = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, jobDeleteTriggerOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.jobIsTriggerNameUnique = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, jobIsTriggerNameUniqueOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.extendEditingTime = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, extendEditingTimeOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.createLock = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, createLockOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.unLock = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, unLockOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getManualTasks = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getManualTasksOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getManualTask = function (id, options, callback) {
        return this.sendOperationRequest({
            id: id,
            options: options
        }, getManualTaskOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.createManualTask = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, createManualTaskOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.deleteManualTask = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, deleteManualTaskOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateManualTaskMainPart = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateManualTaskMainPartOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateManualTaskWorkerPart = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateManualTaskWorkerPartOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateCLIRelatedManualTaskWorkerPart = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateCLIRelatedManualTaskWorkerPartOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateCLIDAssessmentRelatedManualTaskWorkerPart = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateCLIDAssessmentRelatedManualTaskWorkerPartOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateCLIQAssessmentRelatedManualTaskWorkerPart = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateCLIQAssessmentRelatedManualTaskWorkerPartOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateMilestoneDeliverableRelatedManualTaskWorkerPart = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateMilestoneDeliverableRelatedManualTaskWorkerPartOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateMilestoneExecutiveQuestionRelatedManualTaskWorkerPart = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateMilestoneExecutiveQuestionRelatedManualTaskWorkerPartOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.okCLIRelatedManualTask = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, okCLIRelatedManualTaskOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.completeManualTask = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, completeManualTaskOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.approveManualTask = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, approveManualTaskOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.denyManualTask = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, denyManualTaskOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.uploadManualTaskAttachment = function (manualTaskId, options, callback) {
        return this.sendOperationRequest({
            manualTaskId: manualTaskId,
            options: options
        }, uploadManualTaskAttachmentOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.deleteManualTaskAttachment = function (manualTaskId, options, callback) {
        return this.sendOperationRequest({
            manualTaskId: manualTaskId,
            options: options
        }, deleteManualTaskAttachmentOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getProjectMeetings = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getProjectMeetingsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getMeetingByGate = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getMeetingByGateOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateMeeting = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateMeetingOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateParticipant = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateParticipantOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.uploadAttachment = function (meetingId, options, callback) {
        return this.sendOperationRequest({
            meetingId: meetingId,
            options: options
        }, uploadAttachmentOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.removeAttachment = function (meetingId, options, callback) {
        return this.sendOperationRequest({
            meetingId: meetingId,
            options: options
        }, removeAttachmentOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.setExternaApproval = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, setExternaApprovalOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.externalDeleted = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, externalDeletedOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.denyApproval = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, denyApprovalOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.setInApproval = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, setInApprovalOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.setApproved = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, setApprovedOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.undoApproval1 = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, undoApproval1OperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getMilestoneDeliverables = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getMilestoneDeliverablesOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getMilestoneDeliverable = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getMilestoneDeliverableOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateMilestoneDeliverable = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateMilestoneDeliverableOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getMilestoneQuestions = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getMilestoneQuestionsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getMilestoneQuestion = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getMilestoneQuestionOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateMilestoneQuestion = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateMilestoneQuestionOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getOrgUnitsLookupInfo = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getOrgUnitsLookupInfoOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getOrgUnitInfo = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getOrgUnitInfoOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getOrgUnits = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getOrgUnitsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.searchPrincipals = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, searchPrincipalsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.createPrincipal = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, createPrincipalOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getPrincipals = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getPrincipalsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getProjects = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getProjectsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.downloadProjects = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, downloadProjectsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getProjectMasterChecklistTemplateLookups = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getProjectMasterChecklistTemplateLookupsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getProjectYearLookups = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getProjectYearLookupsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getProjectPassedGateYearLookups = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getProjectPassedGateYearLookupsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getProjectsCreationDataFromNorrd = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getProjectsCreationDataFromNorrdOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.createProjectWithDataFromNorrd = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, createProjectWithDataFromNorrdOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateNorrdProjectIgnoreStatus = function (norrdProjectId, options, callback) {
        return this.sendOperationRequest({
            norrdProjectId: norrdProjectId,
            options: options
        }, updateNorrdProjectIgnoreStatusOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getProject = function (projectCodeName, options, callback) {
        return this.sendOperationRequest({
            projectCodeName: projectCodeName,
            options: options
        }, getProjectOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getProjectWithGates = function (projectId, options, callback) {
        return this.sendOperationRequest({
            projectId: projectId,
            options: options
        }, getProjectWithGatesOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getNorrdProject = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getNorrdProjectOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getProjectsLookupInfo = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getProjectsLookupInfoOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getProjectInfo = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getProjectInfoOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.removeWholeProject = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, removeWholeProjectOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.enableOrDisableNotifications = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, enableOrDisableNotificationsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateAllowedOnlyActiveCategories = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateAllowedOnlyActiveCategoriesOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.createProject = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, createProjectOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.canCreateProject = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, canCreateProjectOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getProjectCodeName = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getProjectCodeNameOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateProjectGaneralInfo = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateProjectGaneralInfoOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateStatus = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateStatusOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.rollbackStatus = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, rollbackStatusOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateHideNAItems = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateHideNAItemsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateShowRedDotDeliverable = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateShowRedDotDeliverableOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.searchProjects = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, searchProjectsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.synchronizeWithNorrd = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, synchronizeWithNorrdOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getProjectDocuments = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getProjectDocumentsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.deleteProjectDocument = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, deleteProjectDocumentOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.createProjectDocument = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, createProjectDocumentOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.restoreProjectDocument = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, restoreProjectDocumentOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateProjectDocumentComment = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateProjectDocumentCommentOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateProjectDocumentFileName = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateProjectDocumentFileNameOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.restoreProjectDocumentFile = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, restoreProjectDocumentFileOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateProjectDocumentCategory = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateProjectDocumentCategoryOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.updateProjectDocumentGate = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, updateProjectDocumentGateOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getProjectDocumentFiles = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getProjectDocumentFilesOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getProjectDocumentFileInfos = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getProjectDocumentFileInfosOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.uploadProjectDocumentFile = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, uploadProjectDocumentFileOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.deleteProjectDocumentFile = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, deleteProjectDocumentFileOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getProjectReaders = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getProjectReadersOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.deleteProjectReader = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, deleteProjectReaderOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.createProjectReaders = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, createProjectReadersOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getProjects1 = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getProjects1OperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getProjectsRestApi = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getProjectsRestApiOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getRelatedFunctionsByProject = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getRelatedFunctionsByProjectOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getGatesRelatedFunctionsByProject = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getGatesRelatedFunctionsByProjectOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getRelatedFunctions = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getRelatedFunctionsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getObjectPermissions = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getObjectPermissionsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getAppSettings = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getAppSettingsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.doesUserHaveAnyPermissionOnAnyObject = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, doesUserHaveAnyPermissionOnAnyObjectOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.searchSecurableObject = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, searchSecurableObjectOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.searchRoles = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, searchRolesOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getRolesOnObject = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getRolesOnObjectOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getRoleMembers = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getRoleMembersOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.createRoleMember = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, createRoleMemberOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.deleteRoleMember = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, deleteRoleMemberOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getUserRolesOnObjects = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getUserRolesOnObjectsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getUsersInRolesOnObject = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getUsersInRolesOnObjectOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.downloadFullLogs = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, downloadFullLogsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getActivitiesStatistics = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getActivitiesStatisticsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getActivitiesStatisticsGraph = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getActivitiesStatisticsGraphOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getRequestStatistics = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getRequestStatisticsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getRequestStatisticsGraph = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getRequestStatisticsGraphOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getJobsStatistics = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getJobsStatisticsOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getJobsStatisticsGraph = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getJobsStatisticsGraphOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getUsersCounters = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getUsersCountersOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getTemplates = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getTemplatesOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.generateModelsFromTemplate = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, generateModelsFromTemplateOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.deleteTemplate = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, deleteTemplateOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getEnabledTemplates = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getEnabledTemplatesOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getProjectTemplatesInfo = function (projectId, options, callback) {
        return this.sendOperationRequest({
            projectId: projectId,
            options: options
        }, getProjectTemplatesInfoOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.changeProjectTemplate = function (projectTemplateId, options, callback) {
        return this.sendOperationRequest({
            projectTemplateId: projectTemplateId,
            options: options
        }, changeProjectTemplateOperationSpec, callback);
    };
    TeamGateApiSecured.prototype.getProjectTemplateLookupModels = function (options, callback) {
        return this.sendOperationRequest({
            options: options
        }, getProjectTemplateLookupModelsOperationSpec, callback);
    };
    return TeamGateApiSecured;
}(TeamGateApiSecuredContext));
// Operation Specifications
var serializer = new msRest.Serializer(Mappers);
var getActionItemsOperationSpec = {
    httpMethod: "GET",
    path: "api/ActionItem",
    queryParameters: [
        Parameters.gateId0,
        Parameters.gateProcessType0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ActionItemDetailsModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var createActionItemOperationSpec = {
    httpMethod: "POST",
    path: "api/ActionItem",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.ActionItemCreateModel
    },
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Number"
                }
            }
        },
        400: {},
        default: {}
    },
    serializer: serializer
};
var deleteActionItemOperationSpec = {
    httpMethod: "DELETE",
    path: "api/ActionItem",
    queryParameters: [
        Parameters.actionItemId0
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var getActionItemDocumentsOperationSpec = {
    httpMethod: "GET",
    path: "api/ActionItem/documents",
    queryParameters: [
        Parameters.actionItemId0
    ],
    responses: {
        200: {
            bodyMapper: Mappers.ActionItemDocumentsModel
        },
        default: {}
    },
    serializer: serializer
};
var hasActionItemsOperationSpec = {
    httpMethod: "GET",
    path: "api/ActionItem/any",
    queryParameters: [
        Parameters.gateId0,
        Parameters.gateProcessType0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Boolean"
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var updateActionItemMainPartOperationSpec = {
    httpMethod: "PUT",
    path: "api/ActionItem/main-part",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.ActionItemDetailsModel
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var updateActionItemWorkerPartOperationSpec = {
    httpMethod: "PUT",
    path: "api/ActionItem/worker-part",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.ActionItemDetailsModel
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var prepareActionItemOperationSpec = {
    httpMethod: "PUT",
    path: "api/ActionItem/prepare",
    queryParameters: [
        Parameters.actionItemId0
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var denyActionItemOperationSpec = {
    httpMethod: "PUT",
    path: "api/ActionItem/deny",
    queryParameters: [
        Parameters.actionItemId0,
        Parameters.comment
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var approveActionItemOperationSpec = {
    httpMethod: "PUT",
    path: "api/ActionItem/approve",
    queryParameters: [
        Parameters.actionItemId0
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var uploadActionItemDocumentOperationSpec = {
    httpMethod: "POST",
    path: "api/ActionItem/{actionItemId}/document",
    urlParameters: [
        Parameters.actionItemId1
    ],
    queryParameters: [
        Parameters.version
    ],
    formDataParameters: [
        Parameters.file
    ],
    contentType: "multipart/form-data",
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var deleteActionItemDocumentOperationSpec = {
    httpMethod: "DELETE",
    path: "api/ActionItem/document",
    queryParameters: [
        Parameters.actionItemId0,
        Parameters.actionItemDocumentId
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var getActionItems1OperationSpec = {
    httpMethod: "GET",
    path: "rest/ActionItemRestApi",
    queryParameters: [
        Parameters.id0,
        Parameters.responsibleOrgUnitId,
        Parameters.responsibleOrgUnitCodeName,
        Parameters.includeOrgUnitChildren,
        Parameters.gateId1,
        Parameters.gateStartContainerNumber,
        Parameters.deadlineDateFrom,
        Parameters.deadlineDateTo,
        Parameters.creationDateFrom,
        Parameters.creationDateTo,
        Parameters.functionPrincipalEmail,
        Parameters.assessorRating,
        Parameters.gateProcessType1,
        Parameters.currentStatus,
        Parameters.approvedDateFrom,
        Parameters.approvedDateTo
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ActionItemRestApiModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getApiKeysOperationSpec = {
    httpMethod: "GET",
    path: "api/ApiKey",
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ApiKeyModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var addApiKeyOperationSpec = {
    httpMethod: "POST",
    path: "api/ApiKey",
    queryParameters: [
        Parameters.key0,
        Parameters.appplicationName,
        Parameters.responsiblePrincipalId
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var updateApiKeyOperationSpec = {
    httpMethod: "PUT",
    path: "api/ApiKey",
    queryParameters: [
        Parameters.id0,
        Parameters.appplicationName,
        Parameters.responsiblePrincipalId,
        Parameters.status0
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var generateKeyOperationSpec = {
    httpMethod: "GET",
    path: "api/ApiKey/generate",
    responses: {
        200: {
            bodyMapper: Mappers.ApiKeyGeneratedKeyModel
        },
        default: {}
    },
    serializer: serializer
};
var getApprovalsForGateOperationSpec = {
    httpMethod: "GET",
    path: "api/Approval/gate-approvals",
    queryParameters: [
        Parameters.projectId0,
        Parameters.gateId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ApprovalModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getApprovalsByUniqueIdOperationSpec = {
    httpMethod: "GET",
    path: "api/Approval/object-approvals",
    queryParameters: [
        Parameters.objectUniqueId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ApprovalModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var updateApprovalOperationSpec = {
    httpMethod: "PUT",
    path: "api/Approval",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.ApprovalModel
    },
    responses: {
        200: {
            bodyMapper: Mappers.ApprovalModel
        },
        default: {}
    },
    serializer: serializer
};
var deleteApprovalOperationSpec = {
    httpMethod: "DELETE",
    path: "api/Approval",
    queryParameters: [
        Parameters.id1
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var createChecklistApprovalOperationSpec = {
    httpMethod: "POST",
    path: "api/Approval/checklist-approval",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.ApprovalCreateModel
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var createCheckListItemDeliverableApprovalOperationSpec = {
    httpMethod: "POST",
    path: "api/Approval/checkListItemDeliverable-approval",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.ApprovalCreateModel
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var getApprovalsOperationSpec = {
    httpMethod: "GET",
    path: "rest/ApprovalRestApi",
    queryParameters: [
        Parameters.id0,
        Parameters.projectId1,
        Parameters.projectCodeName0,
        Parameters.gateId1,
        Parameters.gateStartContainerNumber,
        Parameters.relatedFunctionPrincipalEmail,
        Parameters.relatedObjectCodeName,
        Parameters.dateFromFrom,
        Parameters.dateFromTo,
        Parameters.dateToFrom,
        Parameters.dateToTo,
        Parameters.approvalResult,
        Parameters.type
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ApprovalRestApiModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getAppTasksOperationSpec = {
    httpMethod: "GET",
    path: "api/AppTask/tasks",
    queryParameters: [
        Parameters.projectId0,
        Parameters.messageType,
        Parameters.assignmentState,
        Parameters.principalId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "AssignmentListItemModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var updateTaskStateOperationSpec = {
    httpMethod: "PUT",
    path: "api/AppTask/state",
    queryParameters: [
        Parameters.id1,
        Parameters.isActive
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var getAttachmentsOperationSpec = {
    httpMethod: "GET",
    path: "api/Attachment",
    queryParameters: [
        Parameters.projectId0,
        Parameters.gateId0,
        Parameters.meetingId0,
        Parameters.taskId
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "DocumentModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getLogOperationSpec = {
    httpMethod: "GET",
    path: "api/ChangeLog",
    queryParameters: [
        Parameters.uniqueId
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ChangeLogModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getFunctionalOnlyChecklistsOperationSpec = {
    httpMethod: "GET",
    path: "api/Checklist",
    queryParameters: [
        Parameters.projectId0,
        Parameters.gateId0,
        Parameters.hideNAItems
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ChecklistDetailsModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var updateChecklistOperationSpec = {
    httpMethod: "PUT",
    path: "api/Checklist",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.ChecklistDetailsModel
    },
    responses: {
        200: {},
        409: {
            bodyMapper: Mappers.ApiConflictModel
        },
        default: {}
    },
    serializer: serializer
};
var deleteChecklistOperationSpec = {
    httpMethod: "DELETE",
    path: "api/Checklist",
    queryParameters: [
        Parameters.checklistId
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var updateChecklistPostFixOperationSpec = {
    httpMethod: "POST",
    path: "api/Checklist",
    queryParameters: [
        Parameters.checklistId,
        Parameters.postFix
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var getChecklistInfoOperationSpec = {
    httpMethod: "GET",
    path: "api/Checklist/info",
    queryParameters: [
        Parameters.id1
    ],
    responses: {
        200: {
            bodyMapper: Mappers.CheckListMainInfoModel
        },
        default: {}
    },
    serializer: serializer
};
var getAllCheckListsOperationSpec = {
    httpMethod: "GET",
    path: "api/Checklist/master",
    queryParameters: [
        Parameters.projectId0,
        Parameters.gateId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ChecklistDetailsModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getChecklistsSmallModelOperationSpec = {
    httpMethod: "GET",
    path: "api/Checklist/small-model",
    queryParameters: [
        Parameters.projectId0,
        Parameters.gateId0,
        Parameters.hideNAItems
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ChecklistSmallModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var prepareFunctionChecklistOperationSpec = {
    httpMethod: "PUT",
    path: "api/Checklist/{id}/in-approval",
    urlParameters: [
        Parameters.id2
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var approveFunctionChecklistOperationSpec = {
    httpMethod: "PUT",
    path: "api/Checklist/{id}/approved",
    urlParameters: [
        Parameters.id2
    ],
    queryParameters: [
        Parameters.approvalId
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var denyFunctionChecklistOperationSpec = {
    httpMethod: "PUT",
    path: "api/Checklist/{id}/denied",
    urlParameters: [
        Parameters.id2
    ],
    queryParameters: [
        Parameters.approvalId,
        Parameters.comment
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var undoChecklistApprovalOperationSpec = {
    httpMethod: "PUT",
    path: "api/Checklist/{id}/draft",
    urlParameters: [
        Parameters.id2
    ],
    queryParameters: [
        Parameters.comment
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var getChecklistsLookupInfoOperationSpec = {
    httpMethod: "GET",
    path: "api/Checklist/lookup-info",
    queryParameters: [
        Parameters.projectId0,
        Parameters.gateId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GenericLookupInfo"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getFunctionalChecklistsLookupInfoOperationSpec = {
    httpMethod: "GET",
    path: "api/Checklist/functional-checklists-lookup-info",
    queryParameters: [
        Parameters.projectId0,
        Parameters.gateId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GenericLookupInfo"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var copyChecklistOperationSpec = {
    httpMethod: "PUT",
    path: "api/Checklist/copy",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.CopyCheckListModel
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var getChecklistStatusOverviewOperationSpec = {
    httpMethod: "GET",
    path: "api/Checklist/status-overview",
    queryParameters: [
        Parameters.id1,
        Parameters.isForGateMeeting
    ],
    responses: {
        200: {
            bodyMapper: Mappers.ChecklistStatusOverview
        },
        default: {}
    },
    serializer: serializer
};
var getDeliverableChecklistItemsOperationSpec = {
    httpMethod: "GET",
    path: "api/ChecklistItem/deliverable",
    queryParameters: [
        Parameters.checkListId,
        Parameters.isApplicableForTemplate
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ChecklistItemDetailsModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getQuestionChecklistItemsOperationSpec = {
    httpMethod: "GET",
    path: "api/ChecklistItem/question",
    queryParameters: [
        Parameters.checkListId,
        Parameters.isApplicableForTemplate
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ChecklistItemDetailsModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getChecklistItemsLookupInfoOperationSpec = {
    httpMethod: "GET",
    path: "api/ChecklistItem/lookup-info",
    queryParameters: [
        Parameters.checkListIds
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GenericLookupInfo"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getDeliverableApprovedChecklistItemsOperationSpec = {
    httpMethod: "GET",
    path: "api/ChecklistItem/approved/deliverable",
    queryParameters: [
        Parameters.checkListId
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemApprovedDetailsModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getQuestionApprovedChecklistItemsOperationSpec = {
    httpMethod: "GET",
    path: "api/ChecklistItem/approved/question",
    queryParameters: [
        Parameters.checkListId
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemApprovedDetailsModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getApprovedChecklistItemOperationSpec = {
    httpMethod: "GET",
    path: "api/ChecklistItem/approved-item",
    queryParameters: [
        Parameters.checkListItemId0
    ],
    responses: {
        200: {
            bodyMapper: Mappers.CheckListItemApprovedDetailsModel
        },
        default: {}
    },
    serializer: serializer
};
var updateFunctionListGatePreparationItemOperationSpec = {
    httpMethod: "PUT",
    path: "api/ChecklistItem/approved/function",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.CheckListItemApprovedDetailsModel
    },
    responses: {
        200: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var updateAssessorGatePreparationItemOperationSpec = {
    httpMethod: "PUT",
    path: "api/ChecklistItem/approved/assessor",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.CheckListItemApprovedDetailsModel
    },
    responses: {
        200: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var updateAssessorNeedAttentionOperationSpec = {
    httpMethod: "PUT",
    path: "api/ChecklistItem/approved/assessor/need-attention",
    queryParameters: [
        Parameters.checkListItemId0,
        Parameters.needAttention
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var updatePrjManagerPartGatePreparationItemOperationSpec = {
    httpMethod: "PUT",
    path: "api/ChecklistItem/approved/project-manager",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.CheckListItemApprovedDetailsModel
    },
    responses: {
        200: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var updatePrjManagerNeedAttentionOperationSpec = {
    httpMethod: "PUT",
    path: "api/ChecklistItem/approved/project-manager/need-attention",
    queryParameters: [
        Parameters.checkListItemId0,
        Parameters.needAttention
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var getAllChecklistItemDeliverableDocumentsOperationSpec = {
    httpMethod: "GET",
    path: "api/ChecklistItem/{checkListItemId}/document-versions",
    urlParameters: [
        Parameters.checkListItemId1
    ],
    responses: {
        200: {
            bodyMapper: Mappers.DeliverableDocumentModel
        },
        default: {}
    },
    serializer: serializer
};
var uploadChecklistItemDeliverableDocumentOperationSpec = {
    httpMethod: "POST",
    path: "api/ChecklistItem/{checkListItemId}/document",
    urlParameters: [
        Parameters.checkListItemId1
    ],
    formDataParameters: [
        Parameters.file
    ],
    contentType: "multipart/form-data",
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var removeChecklistItemDeliverableDocumentOperationSpec = {
    httpMethod: "DELETE",
    path: "api/ChecklistItem/{checkListItemId}/document",
    urlParameters: [
        Parameters.checkListItemId1
    ],
    queryParameters: [
        Parameters.versionNumber
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var updateChecklistItemDeliverableFileCommentOperationSpec = {
    httpMethod: "PUT",
    path: "api/ChecklistItem/file-comment",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.UpdateChecklistItemDeliverableFileCommentModel
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var addChecklistItemDeliverableLinkOperationSpec = {
    httpMethod: "POST",
    path: "api/ChecklistItem/{checkListItemId}/link",
    urlParameters: [
        Parameters.checkListItemId1
    ],
    queryParameters: [
        Parameters.link
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var allowChecklistItemDeliverableWithoutDocumentOperationSpec = {
    httpMethod: "PUT",
    path: "api/ChecklistItem/{checkListItemId}/no-document",
    urlParameters: [
        Parameters.checkListItemId1
    ],
    queryParameters: [
        Parameters.section
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var copyChecklistFunctionCommentsOperationSpec = {
    httpMethod: "PUT",
    path: "api/ChecklistItem/copy-function-comments",
    queryParameters: [
        Parameters.fromChecklistId,
        Parameters.toChecklistId
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var getMasterCheckListItemFunctionsOperationSpec = {
    httpMethod: "GET",
    path: "api/ChecklistItem/functions",
    queryParameters: [
        Parameters.projectId0,
        Parameters.gateId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "FunctionModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var okPrjManagerPartGatePreparationItemsOperationSpec = {
    httpMethod: "PUT",
    path: "api/ChecklistItem/oks/project-manager",
    queryParameters: [
        Parameters.checkListId
    ],
    responses: {
        200: {
            bodyMapper: Mappers.CheckListItemApprovedDetailsModel
        },
        404: {},
        default: {}
    },
    serializer: serializer
};
var okPrjManagerPartGatePreparationItemOperationSpec = {
    httpMethod: "PUT",
    path: "api/ChecklistItem/ok/project-manager",
    queryParameters: [
        Parameters.checkListItemId0
    ],
    responses: {
        200: {
            bodyMapper: Mappers.CheckListItemApprovedDetailsModel
        },
        404: {},
        default: {}
    },
    serializer: serializer
};
var okAssessorPartGatePreparationItemsOperationSpec = {
    httpMethod: "PUT",
    path: "api/ChecklistItem/oks/assessor",
    queryParameters: [
        Parameters.checkListId
    ],
    responses: {
        200: {
            bodyMapper: Mappers.CheckListItemApprovedDetailsModel
        },
        404: {},
        default: {}
    },
    serializer: serializer
};
var okAssessorPartGatePreparationItemOperationSpec = {
    httpMethod: "PUT",
    path: "api/ChecklistItem/ok/assessor",
    queryParameters: [
        Parameters.checkListItemId0
    ],
    responses: {
        200: {
            bodyMapper: Mappers.CheckListItemApprovedDetailsModel
        },
        404: {},
        default: {}
    },
    serializer: serializer
};
var okFunctionPartGatePreparationItemOperationSpec = {
    httpMethod: "PUT",
    path: "api/ChecklistItem/ok/function",
    queryParameters: [
        Parameters.checkListItemId0
    ],
    responses: {
        200: {
            bodyMapper: Mappers.CheckListItemApprovedDetailsModel
        },
        404: {},
        default: {}
    },
    serializer: serializer
};
var isFunctionOkButtonVisiableOperationSpec = {
    httpMethod: "GET",
    path: "api/ChecklistItem/function-ok-button",
    queryParameters: [
        Parameters.checklistId
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Boolean"
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getGateDeliverablesOperationSpec = {
    httpMethod: "GET",
    path: "api/ChecklistItemDeliverable/deliverables",
    queryParameters: [
        Parameters.gateId0,
        Parameters.masterChecklistItemId,
        Parameters.hideNAItems
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GateDeliverableModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getGateDeliverableShortModelsOperationSpec = {
    httpMethod: "GET",
    path: "api/ChecklistItemDeliverable/gate-deliverables",
    queryParameters: [
        Parameters.gateId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GateDeliverableShortModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var prepareDeliverableOperationSpec = {
    httpMethod: "PUT",
    path: "api/ChecklistItemDeliverable/{id}/in-approval",
    urlParameters: [
        Parameters.id2
    ],
    queryParameters: [
        Parameters.dismissWarning
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Boolean"
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var approveDeliverableOperationSpec = {
    httpMethod: "PUT",
    path: "api/ChecklistItemDeliverable/{id}/approved",
    urlParameters: [
        Parameters.id2
    ],
    queryParameters: [
        Parameters.approvalId
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var denyDeliverableOperationSpec = {
    httpMethod: "PUT",
    path: "api/ChecklistItemDeliverable/{id}/denied",
    urlParameters: [
        Parameters.id2
    ],
    queryParameters: [
        Parameters.approvalId,
        Parameters.comment
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var undoApprovalOperationSpec = {
    httpMethod: "PUT",
    path: "api/ChecklistItemDeliverable/{id}/draft",
    urlParameters: [
        Parameters.id2
    ],
    queryParameters: [
        Parameters.comment
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var undoDeliverableApprovalWithoutInputOperationSpec = {
    httpMethod: "PUT",
    path: "api/ChecklistItemDeliverable/{id}/draft-without-input",
    urlParameters: [
        Parameters.id2
    ],
    queryParameters: [
        Parameters.comment
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var undoMasterDeliverableOperationSpec = {
    httpMethod: "PUT",
    path: "api/ChecklistItemDeliverable/{id}/draft-master",
    urlParameters: [
        Parameters.id2
    ],
    queryParameters: [
        Parameters.comment
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var updateChecklistItemDeliverableDenyCommentOperationSpec = {
    httpMethod: "PUT",
    path: "api/ChecklistItemDeliverable/deny-comment",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.GateDeliverableModel
    },
    responses: {
        200: {},
        400: {},
        default: {}
    },
    serializer: serializer
};
var setApprovalApplicationForDeliverableOperationSpec = {
    httpMethod: "PUT",
    path: "api/ChecklistItemDeliverable/{id}/set-approval-application",
    urlParameters: [
        Parameters.id2
    ],
    queryParameters: [
        Parameters.approvalApplication
    ],
    responses: {
        200: {},
        400: {},
        default: {}
    },
    serializer: serializer
};
var getGateDeliverablesLookupInfoOperationSpec = {
    httpMethod: "GET",
    path: "api/ChecklistItemDeliverable/lookup-info",
    queryParameters: [
        Parameters.projectId0,
        Parameters.gateId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GenericLookupInfo"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var downloadDeliverableProviderEmailTemplateOperationSpec = {
    httpMethod: "GET",
    path: "api/ChecklistItemDeliverable/{id}/download-email",
    urlParameters: [
        Parameters.id2
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Stream"
                }
            }
        },
        400: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var getChecklistItemsOperationSpec = {
    httpMethod: "GET",
    path: "rest/ChecklistItemRestApi",
    queryParameters: [
        Parameters.id0,
        Parameters.projectId1,
        Parameters.projectCodeName0,
        Parameters.gateId1,
        Parameters.gateStartContainerNumber,
        Parameters.type,
        Parameters.code,
        Parameters.rating,
        Parameters.assessorRating,
        Parameters.projectManagerRating,
        Parameters.gateAssessorMeetingRating,
        Parameters.hasActionItem
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ChecklistItemRestApiModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getDeliverablesTemplateStatusConfigurationOperationSpec = {
    httpMethod: "GET",
    path: "api/CheckListItemTemplateDeliverable/deliverables-template-status-configuration",
    queryParameters: [
        Parameters.projectTemplateId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "TemplateDeliverableStatusConfigurationModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getDeliverablesTemplateApprovalConfigurationOperationSpec = {
    httpMethod: "GET",
    path: "api/CheckListItemTemplateDeliverable/deliverables-template-approval-configuration",
    queryParameters: [
        Parameters.projectTemplateId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "DeliverableTemplateApprovalConfigurationModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var changeCLITemplateDeliverableGateAssessmentStatusOperationSpec = {
    httpMethod: "PUT",
    path: "api/CheckListItemTemplateDeliverable/status-gate-assessment",
    queryParameters: [
        Parameters.id1,
        Parameters.status1,
        Parameters.isEntry
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var createDeliverableTemplateFunctionsRangeOperationSpec = {
    httpMethod: "POST",
    path: "api/CheckListItemTemplateDeliverable/functions-range",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: {
            serializedName: "body",
            type: {
                name: "Sequence",
                element: {
                    type: {
                        name: "Composite",
                        className: "TemplateFunctionRangeCreateModel"
                    }
                }
            }
        }
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var deleteDeliverableTemplateFunctionsRangeOperationSpec = {
    httpMethod: "DELETE",
    path: "api/CheckListItemTemplateDeliverable/functions-range",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: {
            serializedName: "body",
            type: {
                name: "Sequence",
                element: {
                    type: {
                        name: "Number"
                    }
                }
            }
        }
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var updateDeliverableTemplateFunctionsRangeOperationSpec = {
    httpMethod: "PUT",
    path: "api/CheckListItemTemplateDeliverable/functions-range",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: {
            serializedName: "body",
            type: {
                name: "Sequence",
                element: {
                    type: {
                        name: "Composite",
                        className: "TemplateFunctionRangeUpdateModel"
                    }
                }
            }
        }
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var updateDocumentDefinitionOperationSpec = {
    httpMethod: "PUT",
    path: "api/CheckListItemTemplateDeliverable/{id}/document-definition",
    urlParameters: [
        Parameters.id2
    ],
    queryParameters: [
        Parameters.documentDefinitionId
    ],
    responses: {
        200: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var getDeliverablesDocumentConfigurationOperationSpec = {
    httpMethod: "GET",
    path: "api/CheckListItemTemplateDeliverable/deliverables-document-configuration",
    queryParameters: [
        Parameters.projectTemplateId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "DeliverableDocumentConfigurationModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getChecklistsOperationSpec = {
    httpMethod: "GET",
    path: "rest/ChecklistRestApi/all",
    queryParameters: [
        Parameters.id0,
        Parameters.responsibleOrgUnitId,
        Parameters.responsibleOrgUnitCodeName,
        Parameters.includeOrgUnitChildren,
        Parameters.checklistType,
        Parameters.status0,
        Parameters.projectId1,
        Parameters.projectCodeName0,
        Parameters.gateId1,
        Parameters.gateStartContainerNumber,
        Parameters.fullCode,
        Parameters.relatedFunctionEmail,
        Parameters.approvedDateFrom,
        Parameters.approvedDateTo
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ChecklistRestApiModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getChecklistsRestApiOperationSpec = {
    httpMethod: "GET",
    path: "rest/ChecklistRestApi",
    queryParameters: [
        Parameters.gateId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ChecklistShortInfoRestApiModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var downloadChecklistOperationSpec = {
    httpMethod: "GET",
    path: "api/ChecklistTemplate/download-checklist",
    queryParameters: [
        Parameters.projectTemplateId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Stream"
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var uploadChecklistTemplateAsyncOperationSpec = {
    httpMethod: "POST",
    path: "api/ChecklistTemplate/import",
    queryParameters: [
        Parameters.masterFileMode
    ],
    formDataParameters: [
        Parameters.file
    ],
    contentType: "multipart/form-data",
    responses: {
        200: {
            bodyMapper: Mappers.ChecklistTemplateExcelImportResult
        },
        400: {},
        default: {}
    },
    serializer: serializer
};
var getChecklistsTemplateApprovalConfigurationOperationSpec = {
    httpMethod: "GET",
    path: "api/ChecklistTemplate/checklists-template-approval-configuration",
    queryParameters: [
        Parameters.projectTemplateId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ChecklistTemplateApprovalConfigurationModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var createChecklistTemplateFunctionsRangeOperationSpec = {
    httpMethod: "POST",
    path: "api/ChecklistTemplate/functions-range",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: {
            serializedName: "body",
            type: {
                name: "Sequence",
                element: {
                    type: {
                        name: "Composite",
                        className: "TemplateFunctionRangeCreateModel"
                    }
                }
            }
        }
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var deleteChecklistTemplateFunctionsRangeOperationSpec = {
    httpMethod: "DELETE",
    path: "api/ChecklistTemplate/functions-range",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: {
            serializedName: "body",
            type: {
                name: "Sequence",
                element: {
                    type: {
                        name: "Number"
                    }
                }
            }
        }
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var updateChecklistTemplateFunctionsRangeOperationSpec = {
    httpMethod: "PUT",
    path: "api/ChecklistTemplate/functions-range",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: {
            serializedName: "body",
            type: {
                name: "Sequence",
                element: {
                    type: {
                        name: "Composite",
                        className: "TemplateFunctionRangeUpdateModel"
                    }
                }
            }
        }
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var getConfigurationOperationSpec = {
    httpMethod: "GET",
    path: "api/Configuration",
    responses: {
        200: {
            bodyMapper: Mappers.ConfigurationModel
        },
        default: {}
    },
    serializer: serializer
};
var putPrincipalConfigurationModelOperationSpec = {
    httpMethod: "PUT",
    path: "api/Configuration/put-principal-configuration",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.PrincipalConfigurationModel
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var getPrincipalConfigurationModelOperationSpec = {
    httpMethod: "GET",
    path: "api/Configuration/get-principal-configuration",
    queryParameters: [
        Parameters.group0,
        Parameters.key1,
        Parameters.projectId0
    ],
    responses: {
        200: {
            bodyMapper: Mappers.PrincipalConfigurationModel
        },
        default: {}
    },
    serializer: serializer
};
var getAppConfigurationsOperationSpec = {
    httpMethod: "GET",
    path: "api/Configuration/app-configurations",
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "DbConfigurationModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getOrgUnitConfigurationsOperationSpec = {
    httpMethod: "GET",
    path: "api/Configuration/org-unit-configurations",
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "OrgUnitConfigurationModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var updateAppConfigurationOperationSpec = {
    httpMethod: "PUT",
    path: "api/Configuration/update-app-configuration",
    queryParameters: [
        Parameters.key0,
        Parameters.value0,
        Parameters.description,
        Parameters.group1
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var updateOrgUnitConfigurationOperationSpec = {
    httpMethod: "PUT",
    path: "api/Configuration/update-org-unit-configuration",
    queryParameters: [
        Parameters.orgUnitId0,
        Parameters.orgUnitDisplayName,
        Parameters.key0,
        Parameters.value0,
        Parameters.description,
        Parameters.group1
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var addOrgUnitConfigurationOperationSpec = {
    httpMethod: "POST",
    path: "api/Configuration/add-org-unit-configuration",
    queryParameters: [
        Parameters.orgUnitId1
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var deleteOrgUnitConfigurationOperationSpec = {
    httpMethod: "DELETE",
    path: "api/Configuration/org-unit-configuration",
    queryParameters: [
        Parameters.orgUnitId1
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var getUseColorBlindSchemeValueOperationSpec = {
    httpMethod: "GET",
    path: "api/Configuration/get-color-blind-scheme-configuration",
    responses: {
        200: {
            bodyMapper: Mappers.ColorBlindSchemeModel
        },
        default: {}
    },
    serializer: serializer
};
var addOrUpdateUseColorBlindSchemeValueOperationSpec = {
    httpMethod: "PUT",
    path: "api/Configuration/put-color-blind-scheme-configuration",
    queryParameters: [
        Parameters.isEnabled0,
        Parameters.colorsRed,
        Parameters.colorsGreen,
        Parameters.colorsOrange,
        Parameters.colorsYellow,
        Parameters.colorsGray
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var getPrincipalFilterConfigurationOperationSpec = {
    httpMethod: "GET",
    path: "api/Configuration/principal-filter-configuration",
    queryParameters: [
        Parameters.name
    ],
    responses: {
        200: {
            bodyMapper: Mappers.PrincipalConfigurationModel
        },
        default: {}
    },
    serializer: serializer
};
var addOrUpdatePrincipalFilterConfigurationOperationSpec = {
    httpMethod: "PUT",
    path: "api/Configuration/principal-filter-configuration",
    queryParameters: [
        Parameters.name,
        Parameters.filterModel
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var deletePrincipalFilterConfigurationOperationSpec = {
    httpMethod: "DELETE",
    path: "api/Configuration/principal-filter-configuration",
    queryParameters: [
        Parameters.name
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var getAssignmentsForCurrentPrincipalOperationSpec = {
    httpMethod: "GET",
    path: "api/Dashboard/assignment",
    queryParameters: [
        Parameters.page,
        Parameters.size0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "AssignmentModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getNotificationsForCurrentPrincipalOperationSpec = {
    httpMethod: "GET",
    path: "api/Dashboard/notification",
    queryParameters: [
        Parameters.page,
        Parameters.size0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "NotificationModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var dismissNotificationOperationSpec = {
    httpMethod: "PUT",
    path: "api/Dashboard/notification/{id}",
    urlParameters: [
        Parameters.id2
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var getManagedAndAccessibleProjectsForCurrentPrincipalOperationSpec = {
    httpMethod: "GET",
    path: "api/Dashboard/my-managed-and-accessible-projects",
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "MyProjectModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getProjectsForCurrentPrincipalOperationSpec = {
    httpMethod: "GET",
    path: "api/Dashboard/my-projects",
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "MyProjectModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getFunctionalChecklistsForCurrentPrincipalOperationSpec = {
    httpMethod: "GET",
    path: "api/Dashboard/functional-checklists",
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ChecklistFunctionPrincipalModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getDeliverablesRestApiOperationSpec = {
    httpMethod: "GET",
    path: "rest/DeliverableRestApi",
    queryParameters: [
        Parameters.checklistId
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "DeliverableShortInfoRestApiModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getNotRelevantExternalDeliverableIdsRestApiOperationSpec = {
    httpMethod: "GET",
    path: "rest/DeliverableRestApi/not-relevant-external",
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Number"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getExternalDeliverablesWithRelatedPrincipalsRestApiOperationSpec = {
    httpMethod: "GET",
    path: "rest/DeliverableRestApi/related-principals-external",
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "DeliverableWithRelatedPrincipalsModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var setExternalRestApiOperationSpec = {
    httpMethod: "PUT",
    path: "rest/DeliverableRestApi/set-external",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.DeliverableSetExternalRestApiModel
    },
    responses: {
        200: {
            bodyMapper: Mappers.DeliverableSetExternalRestApiResponseModel
        },
        default: {}
    },
    serializer: serializer
};
var resetExternalRestApiOperationSpec = {
    httpMethod: "PUT",
    path: "rest/DeliverableRestApi/reset-external",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.DeliverableRestApiModel
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var setPreparedRestApiOperationSpec = {
    httpMethod: "PUT",
    path: "rest/DeliverableRestApi/set-prepared",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.DeliverableRestApiModel
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var resetPreparedRestApiOperationSpec = {
    httpMethod: "PUT",
    path: "rest/DeliverableRestApi/reset-prepared",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.DeliverableRestApiModel
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var setDraftStatusRestApiOperationSpec = {
    httpMethod: "PUT",
    path: "rest/DeliverableRestApi/set-draft",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.DeliverableSetStatusRestApiModel
    },
    responses: {
        200: {
            bodyMapper: Mappers.DeliverableSetStatusRestApiResponseModel
        },
        default: {}
    },
    serializer: serializer
};
var setInApprovalStatusRestApiOperationSpec = {
    httpMethod: "PUT",
    path: "rest/DeliverableRestApi/set-in-approval",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.DeliverableSetStatusRestApiModel
    },
    responses: {
        200: {
            bodyMapper: Mappers.DeliverableSetStatusRestApiResponseModel
        },
        default: {}
    },
    serializer: serializer
};
var setApprovedStatusStepOneRestApiOperationSpec = {
    httpMethod: "PUT",
    path: "rest/DeliverableRestApi/set-approved-step-one",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.DeliverableSetStatusRestApiModel
    },
    responses: {
        200: {
            bodyMapper: Mappers.DeliverableSetStatusRestApiResponseModel
        },
        default: {}
    },
    serializer: serializer
};
var setApprovedStatusStepTwoRestApiOperationSpec = {
    httpMethod: "PUT",
    path: "rest/DeliverableRestApi/set-approved-step-two",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.DeliverableSetStatusRestApiModel
    },
    responses: {
        200: {
            bodyMapper: Mappers.DeliverableSetStatusRestApiResponseModel
        },
        default: {}
    },
    serializer: serializer
};
var undoRestApiOperationSpec = {
    httpMethod: "PUT",
    path: "rest/DeliverableRestApi/undo",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.DeliverableSetStatusRestApiModel
    },
    responses: {
        200: {
            bodyMapper: Mappers.DeliverableSetStatusRestApiResponseModel
        },
        default: {}
    },
    serializer: serializer
};
var getDocumentCategoriesForProjectOperationSpec = {
    httpMethod: "GET",
    path: "api/DocumentCategory",
    queryParameters: [
        Parameters.projectId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "DocumentsCategoryModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var createDocumentCategoryOperationSpec = {
    httpMethod: "POST",
    path: "api/DocumentCategory",
    queryParameters: [
        Parameters.projectId0
    ],
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.DocumentCategoryCreateModel
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var updateDocumentCategoryOperationSpec = {
    httpMethod: "PUT",
    path: "api/DocumentCategory",
    queryParameters: [
        Parameters.projectId0
    ],
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.DocumentCategoryUpdateModel
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var deleteDocumentCategoryOperationSpec = {
    httpMethod: "DELETE",
    path: "api/DocumentCategory",
    queryParameters: [
        Parameters.projectId0,
        Parameters.categoryId
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var setDocumentCategoryToProjectOperationSpec = {
    httpMethod: "POST",
    path: "api/DocumentCategory/set-document-category-to-project",
    queryParameters: [
        Parameters.documentCategoryId,
        Parameters.projectId0
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var deleteDocumentCategoryFromProjectOperationSpec = {
    httpMethod: "DELETE",
    path: "api/DocumentCategory/document-category-to-project",
    queryParameters: [
        Parameters.documentCategoryId,
        Parameters.projectId0
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var getDocumentCategoriesForProjectLookupOperationSpec = {
    httpMethod: "GET",
    path: "api/DocumentCategory/lookup",
    queryParameters: [
        Parameters.projectId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GenericLookupInfo"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getDocumentDefinitionsOperationSpec = {
    httpMethod: "GET",
    path: "api/DocumentDefinition",
    queryParameters: [
        Parameters.projectTemplateId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "DocumentDefinitionModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getStatusEnumOperationSpec = {
    httpMethod: "GET",
    path: "api/Enum/status",
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "EnumModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getCheckListItemRatingEnumOperationSpec = {
    httpMethod: "GET",
    path: "api/Enum/check-list-item-rating",
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "EnumModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getGateRecommendationEnumOperationSpec = {
    httpMethod: "GET",
    path: "api/Enum/gate-recommendation",
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "EnumModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getActionItemDeadlineTypeEnumOperationSpec = {
    httpMethod: "GET",
    path: "api/Enum/action-item-deadline",
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "EnumModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getProjectSecurityLevelEnumOperationSpec = {
    httpMethod: "GET",
    path: "api/Enum/project-security-level",
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "EnumModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getProjectCategoryEnumOperationSpec = {
    httpMethod: "GET",
    path: "api/Enum/project-category",
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "EnumModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getProjectStatusEnumOperationSpec = {
    httpMethod: "GET",
    path: "api/Enum/project-status",
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "EnumModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getAssignmentStateEnumOperationSpec = {
    httpMethod: "GET",
    path: "api/Enum/assignment-state",
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "EnumModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var addFavoriteProjectForCurrentPrincipalOperationSpec = {
    httpMethod: "POST",
    path: "api/FavoriteProject",
    queryParameters: [
        Parameters.projectId0
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var deleteFavoriteProjectForCurrentPrincipalOperationSpec = {
    httpMethod: "DELETE",
    path: "api/FavoriteProject",
    queryParameters: [
        Parameters.projectId0
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var getFavoriteProjectsForCurrentPrincipalOperationSpec = {
    httpMethod: "GET",
    path: "api/FavoriteProject/list",
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "FavoriteProjectModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getFileInformationOperationSpec = {
    httpMethod: "GET",
    path: "api/File",
    queryParameters: [
        Parameters.fileInformationId
    ],
    responses: {
        200: {
            bodyMapper: Mappers.FileInformationDownloadResponse
        },
        403: {},
        default: {}
    },
    serializer: serializer
};
var getFunctionsOperationSpec = {
    httpMethod: "GET",
    path: "api/Function",
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "FunctionModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getGateFunctionsOperationSpec = {
    httpMethod: "GET",
    path: "api/Function/for-gate",
    queryParameters: [
        Parameters.gateId0,
        Parameters.exceptId,
        Parameters.isEnabledOnly
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "FunctionModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getProjectTemplateFunctionsOperationSpec = {
    httpMethod: "GET",
    path: "api/Function/for-project-template",
    queryParameters: [
        Parameters.projectTemplateId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "FunctionModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getProjectFunctionsOperationSpec = {
    httpMethod: "GET",
    path: "api/Function/for-project",
    queryParameters: [
        Parameters.projectId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "FunctionModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getGateOperationSpec = {
    httpMethod: "GET",
    path: "api/Gate",
    queryParameters: [
        Parameters.id1
    ],
    responses: {
        200: {
            bodyMapper: Mappers.GateModel
        },
        default: {}
    },
    serializer: serializer
};
var createGateOperationSpec = {
    httpMethod: "POST",
    path: "api/Gate",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.GateCreateModel
    },
    responses: {
        200: {
            bodyMapper: Mappers.ErrorResult
        },
        default: {}
    },
    serializer: serializer
};
var updateGateOperationSpec = {
    httpMethod: "PUT",
    path: "api/Gate",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.UpdateGateModel
    },
    responses: {
        200: {
            bodyMapper: Mappers.ErrorResult
        },
        default: {}
    },
    serializer: serializer
};
var deleteGateOperationSpec = {
    httpMethod: "DELETE",
    path: "api/Gate",
    queryParameters: [
        Parameters.gateId0
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var gateDisplayNameLookupModelsOperationSpec = {
    httpMethod: "GET",
    path: "api/Gate/gate-display-name-lookups",
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GateDisplayNameLookupModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getGatesListOperationSpec = {
    httpMethod: "GET",
    path: "api/Gate/list",
    queryParameters: [
        Parameters.projectId0
    ],
    responses: {
        200: {
            bodyMapper: Mappers.GateListModel
        },
        default: {}
    },
    serializer: serializer
};
var getGatesListLookupOperationSpec = {
    httpMethod: "GET",
    path: "api/Gate/list-lookup",
    queryParameters: [
        Parameters.projectId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GenericLookupInfo"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var redoGateOperationSpec = {
    httpMethod: "POST",
    path: "api/Gate/redo",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.GateCreateModel
    },
    responses: {
        200: {
            bodyMapper: Mappers.ErrorResult
        },
        default: {}
    },
    serializer: serializer
};
var splitGateOperationSpec = {
    httpMethod: "POST",
    path: "api/Gate/split",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.GateSplitModel
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var skipGateOperationSpec = {
    httpMethod: "POST",
    path: "api/Gate/skip",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.GateCreateModel
    },
    responses: {
        200: {
            bodyMapper: Mappers.ErrorResult
        },
        default: {}
    },
    serializer: serializer
};
var joinGateOperationSpec = {
    httpMethod: "POST",
    path: "api/Gate/join",
    queryParameters: [
        Parameters.gateId0,
        Parameters.nextGateId
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var setGateOnHoldOperationSpec = {
    httpMethod: "PUT",
    path: "api/Gate/set-on-hold",
    queryParameters: [
        Parameters.gateId0
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var undoGateOnHoldOperationSpec = {
    httpMethod: "PUT",
    path: "api/Gate/undo-on-hold",
    queryParameters: [
        Parameters.gateId0
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var getApprovedGatesListOperationSpec = {
    httpMethod: "GET",
    path: "api/Gate/approved-short-model",
    queryParameters: [
        Parameters.projectId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GateShortModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var prepareGateOperationSpec = {
    httpMethod: "PUT",
    path: "api/Gate/prepare",
    queryParameters: [
        Parameters.gateId0
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var denyGateOperationSpec = {
    httpMethod: "PUT",
    path: "api/Gate/deny",
    queryParameters: [
        Parameters.gateId0,
        Parameters.comment
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var approveGateOperationSpec = {
    httpMethod: "PUT",
    path: "api/Gate/approve",
    queryParameters: [
        Parameters.gateId0
    ],
    responses: {
        200: {},
        400: {},
        default: {}
    },
    serializer: serializer
};
var getGatesLookupInfoOperationSpec = {
    httpMethod: "GET",
    path: "api/Gate/lookup-info",
    queryParameters: [
        Parameters.projectId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GenericLookupInfo"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getNextGateOptionsOperationSpec = {
    httpMethod: "GET",
    path: "api/Gate/next-gate-options",
    queryParameters: [
        Parameters.gateId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GenericLookupInfo"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getGateInitDataOperationSpec = {
    httpMethod: "GET",
    path: "api/Gate/init-data",
    queryParameters: [
        Parameters.projectId0,
        Parameters.previousGateId,
        Parameters.redoGateId
    ],
    responses: {
        200: {
            bodyMapper: Mappers.GateCreateModel
        },
        default: {}
    },
    serializer: serializer
};
var getGateDescriptionDataOperationSpec = {
    httpMethod: "GET",
    path: "api/Gate/description-data",
    queryParameters: [
        Parameters.projectId0,
        Parameters.gateContainerNumber,
        Parameters.combineGateContainerNumber
    ],
    responses: {
        200: {
            bodyMapper: Mappers.GateDescriptionModel
        },
        default: {}
    },
    serializer: serializer
};
var updateRelatedFunctionOperationSpec = {
    httpMethod: "PUT",
    path: "api/Gate/assigned-functions",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.RelatedFunctionModel
    },
    responses: {
        200: {},
        409: {},
        default: {}
    },
    serializer: serializer
};
var enableDisableRelatedFunctionOperationSpec = {
    httpMethod: "PUT",
    path: "api/Gate/enable-function",
    queryParameters: [
        Parameters.relatedFunctionId,
        Parameters.isEnabled1
    ],
    responses: {
        200: {},
        409: {},
        default: {}
    },
    serializer: serializer
};
var putCurrentProjectGateOperationSpec = {
    httpMethod: "PUT",
    path: "api/Gate/put-current-project-gate",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.PrincipalConfigurationValueModel
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var getCurrentProjectGateOperationSpec = {
    httpMethod: "GET",
    path: "api/Gate/get-current-project-gate",
    queryParameters: [
        Parameters.projectId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Number"
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var hasGateMFilesDeliverablesOperationSpec = {
    httpMethod: "GET",
    path: "api/Gate/mfiles-deliverables",
    queryParameters: [
        Parameters.gateId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Boolean"
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getGateAssessmentKeyDeliverablesOperationSpec = {
    httpMethod: "GET",
    path: "api/GateAssessment/deliverables",
    queryParameters: [
        Parameters.projectId0,
        Parameters.gateId0,
        Parameters.projectState,
        Parameters.hideNAItems
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GateAssessmentKeyDeliverableModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getGateAssessmentKeyDeliverableOperationSpec = {
    httpMethod: "GET",
    path: "api/GateAssessment/deliverable",
    queryParameters: [
        Parameters.checklistItemId,
        Parameters.hideNAItems
    ],
    responses: {
        200: {
            bodyMapper: Mappers.GateAssessmentKeyDeliverableModel
        },
        default: {}
    },
    serializer: serializer
};
var updateAssessorDeliverableOperationSpec = {
    httpMethod: "PUT",
    path: "api/GateAssessment/deliverable/assessor",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.GateAssessmentKeyDeliverableModel
    },
    responses: {
        200: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var updateProjectManagerDeliverableOperationSpec = {
    httpMethod: "PUT",
    path: "api/GateAssessment/deliverable/project-manager",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.GateAssessmentKeyDeliverableModel
    },
    responses: {
        200: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var getGateAssessmentFunctionSummariesOperationSpec = {
    httpMethod: "GET",
    path: "api/GateAssessment/function-summary",
    queryParameters: [
        Parameters.projectId0,
        Parameters.gateId0,
        Parameters.projectState,
        Parameters.hideNAItems
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GateAssessmentFunctionSummary"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var updateAssessorFunctionSummaryOperationSpec = {
    httpMethod: "PUT",
    path: "api/GateAssessment/function-summary/assessor",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.GateAssessmentFunctionSummary
    },
    responses: {
        200: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var updateProjectManagerFunctionSummaryOperationSpec = {
    httpMethod: "PUT",
    path: "api/GateAssessment/function-summary/project-manager",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.GateAssessmentFunctionSummary
    },
    responses: {
        200: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var getGateAssessmentExecutiveQuestionsOperationSpec = {
    httpMethod: "GET",
    path: "api/GateAssessment/executive-questions",
    queryParameters: [
        Parameters.projectId0,
        Parameters.gateId0,
        Parameters.hideNAItems
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GateAssessmentExecutiveQuestion"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getGateAssessmentExecutiveQuestionOperationSpec = {
    httpMethod: "GET",
    path: "api/GateAssessment/executive-question",
    queryParameters: [
        Parameters.checklistItemId
    ],
    responses: {
        200: {
            bodyMapper: Mappers.GateAssessmentExecutiveQuestion
        },
        default: {}
    },
    serializer: serializer
};
var updateAssessorExecutiveQuestionOperationSpec = {
    httpMethod: "PUT",
    path: "api/GateAssessment/executive-question/assessor",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.GateAssessmentExecutiveQuestion
    },
    responses: {
        200: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var updateProjectManagerExecutiveQuestionOperationSpec = {
    httpMethod: "PUT",
    path: "api/GateAssessment/executive-question/project-manager",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.GateAssessmentExecutiveQuestion
    },
    responses: {
        200: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var okDeliverableAssessorPartOperationSpec = {
    httpMethod: "PUT",
    path: "api/GateAssessment/deliverable/assessor/ok",
    queryParameters: [
        Parameters.checkListItemId0,
        Parameters.projectState
    ],
    responses: {
        200: {
            bodyMapper: Mappers.GateAssessmentKeyDeliverableModel
        },
        404: {},
        default: {}
    },
    serializer: serializer
};
var okAssessmentMasterCheckListItemAssessorPartsOperationSpec = {
    httpMethod: "PUT",
    path: "api/GateAssessment/assessment-master-checklist-item-assessor-parts/ok",
    queryParameters: [
        Parameters.gateId0
    ],
    responses: {
        200: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var okExecutiveQuestionAssessorPartOperationSpec = {
    httpMethod: "PUT",
    path: "api/GateAssessment/executive-question/assessor/ok",
    queryParameters: [
        Parameters.checkListItemId0
    ],
    responses: {
        200: {
            bodyMapper: Mappers.GateAssessmentExecutiveQuestion
        },
        404: {},
        default: {}
    },
    serializer: serializer
};
var okFunctionSummaryAssessorPartOperationSpec = {
    httpMethod: "PUT",
    path: "api/GateAssessment/function-summary/assessor/ok",
    queryParameters: [
        Parameters.checkListItemId0,
        Parameters.projectState
    ],
    responses: {
        200: {
            bodyMapper: Mappers.GateAssessmentFunctionSummary
        },
        404: {},
        default: {}
    },
    serializer: serializer
};
var getGateMeetingOperationSpec = {
    httpMethod: "GET",
    path: "api/GateMeeting",
    queryParameters: [
        Parameters.gateId0
    ],
    responses: {
        200: {
            bodyMapper: Mappers.GateMeetingModel
        },
        default: {}
    },
    serializer: serializer
};
var updateGateMeetingDecisionOperationSpec = {
    httpMethod: "PUT",
    path: "api/GateMeeting/{id}/decision",
    urlParameters: [
        Parameters.id2
    ],
    queryParameters: [
        Parameters.decision
    ],
    responses: {
        200: {},
        403: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var setGateMeetingStatusToInApprovalOperationSpec = {
    httpMethod: "PUT",
    path: "api/GateMeeting/{id}/status-to-in-approval",
    urlParameters: [
        Parameters.id2
    ],
    responses: {
        200: {},
        403: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var approveGateMeetingDecisionOperationSpec = {
    httpMethod: "PUT",
    path: "api/GateMeeting/{id}/status-to-approve",
    urlParameters: [
        Parameters.id2
    ],
    responses: {
        200: {},
        403: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var denyGateMeetingDecisionOperationSpec = {
    httpMethod: "PUT",
    path: "api/GateMeeting/{id}/status-to-draft",
    urlParameters: [
        Parameters.id2
    ],
    queryParameters: [
        Parameters.comment
    ],
    responses: {
        200: {},
        403: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var createGateMeetingOperationSpec = {
    httpMethod: "POST",
    path: "api/GateMeeting/{gateId}",
    urlParameters: [
        Parameters.gateId2
    ],
    responses: {
        200: {},
        400: {},
        403: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var getGateKeyDeliverablesOperationSpec = {
    httpMethod: "GET",
    path: "api/GateMeeting/deliverables",
    queryParameters: [
        Parameters.projectId0,
        Parameters.gateId0,
        Parameters.hideNAItems
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GateKeyDeliverableModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var updateAssessorMeetingDeliverableOperationSpec = {
    httpMethod: "PUT",
    path: "api/GateMeeting/deliverable/assessor-meeting",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.GateKeyDeliverableModel
    },
    responses: {
        200: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var getGateFunctionSummariesOperationSpec = {
    httpMethod: "GET",
    path: "api/GateMeeting/function-summary",
    queryParameters: [
        Parameters.projectId0,
        Parameters.gateId0,
        Parameters.hideNAItems
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GateFunctionSummaryModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var updateAssessorMeetingFunctionSummaryOperationSpec = {
    httpMethod: "PUT",
    path: "api/GateMeeting/function-summary/assessor-meeting",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.GateFunctionSummaryModel
    },
    responses: {
        200: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var getGateExecutiveQuestionsOperationSpec = {
    httpMethod: "GET",
    path: "api/GateMeeting/executive-question",
    queryParameters: [
        Parameters.projectId0,
        Parameters.gateId0,
        Parameters.hideNAItems
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GateExecutiveQuestionModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var updateAssessorMeetingExecutiveQuestionOperationSpec = {
    httpMethod: "PUT",
    path: "api/GateMeeting/executive-question/assessor-meeting",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.GateExecutiveQuestionModel
    },
    responses: {
        200: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var okAssessorMeetingPartExecutiveQuestionOperationSpec = {
    httpMethod: "PUT",
    path: "api/GateMeeting/executive-question/assessor-meeting/ok",
    queryParameters: [
        Parameters.checkListItemId0
    ],
    responses: {
        200: {
            bodyMapper: Mappers.GateExecutiveQuestionModel
        },
        404: {},
        default: {}
    },
    serializer: serializer
};
var okAssessorMeetingPartFunctionSummaryOperationSpec = {
    httpMethod: "PUT",
    path: "api/GateMeeting/function-summary/assessor-meeting/ok",
    queryParameters: [
        Parameters.checkListItemId0
    ],
    responses: {
        200: {
            bodyMapper: Mappers.GateFunctionSummaryModel
        },
        404: {},
        default: {}
    },
    serializer: serializer
};
var okDeliverableAssessorMeetingPartOperationSpec = {
    httpMethod: "PUT",
    path: "api/GateMeeting/deliverable/assessor-meeting/ok",
    queryParameters: [
        Parameters.checkListItemId0
    ],
    responses: {
        200: {
            bodyMapper: Mappers.GateKeyDeliverableModel
        },
        404: {},
        default: {}
    },
    serializer: serializer
};
var okMeetingMasterCheckListItemAssessorPartsOperationSpec = {
    httpMethod: "PUT",
    path: "api/GateMeeting/meeting-master-checklist-item-assessor-parts/ok",
    queryParameters: [
        Parameters.gateId0
    ],
    responses: {
        200: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var getRecommendationOperationSpec = {
    httpMethod: "GET",
    path: "api/GateRecommendation",
    queryParameters: [
        Parameters.gateId0
    ],
    responses: {
        200: {
            bodyMapper: Mappers.GateRecommendationModel
        },
        default: {}
    },
    serializer: serializer
};
var updateRecommendationDecisionOperationSpec = {
    httpMethod: "PUT",
    path: "api/GateRecommendation/{id}/decision",
    urlParameters: [
        Parameters.id2
    ],
    queryParameters: [
        Parameters.decision
    ],
    responses: {
        200: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var approveRecommendationOperationSpec = {
    httpMethod: "PUT",
    path: "api/GateRecommendation/{id}/approve",
    urlParameters: [
        Parameters.id2
    ],
    responses: {
        200: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var createRecommendationOperationSpec = {
    httpMethod: "POST",
    path: "api/GateRecommendation/{gateId}",
    urlParameters: [
        Parameters.gateId2
    ],
    responses: {
        200: {},
        400: {},
        403: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var downloadEmailTemplateOperationSpec = {
    httpMethod: "GET",
    path: "api/GateRecommendation/{id}/download-email",
    urlParameters: [
        Parameters.id2
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Stream"
                }
            }
        },
        400: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var getGatesOperationSpec = {
    httpMethod: "GET",
    path: "rest/GateRestApi/all",
    queryParameters: [
        Parameters.id0,
        Parameters.responsibleOrgUnitId,
        Parameters.responsibleOrgUnitCodeName,
        Parameters.includeOrgUnitChildren,
        Parameters.projectId1,
        Parameters.projectCodeName0,
        Parameters.startContainerNumber,
        Parameters.forecastOrActualDateFrom,
        Parameters.forecastOrActualDateTo,
        Parameters.completionTargetDateFrom,
        Parameters.completionTargetDateTo,
        Parameters.pjMPrincipalEmail,
        Parameters.gAPrincipalEmail,
        Parameters.gOPrincipalEmail,
        Parameters.gateMeetingStatus,
        Parameters.gateMeetingDecision,
        Parameters.gateMeetingApprovedAtDateTimeFrom,
        Parameters.gateMeetingApprovedAtDateTimeTo,
        Parameters.meetingStartDateTimeFrom,
        Parameters.meetingStartDateTimeTo,
        Parameters.meetingEndDateTimeFrom,
        Parameters.meetingEndDateTimeTo
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GateRestApiModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getGatesRestApiOperationSpec = {
    httpMethod: "GET",
    path: "rest/GateRestApi",
    queryParameters: [
        Parameters.projectId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GateShortInfoRestApiModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var authorizeCheckOperationSpec = {
    httpMethod: "GET",
    path: "api/Health/authorize-check",
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var removeAllProjectsOperationSpec = {
    httpMethod: "DELETE",
    path: "api/Health/projects",
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var removeAllTemplatesOperationSpec = {
    httpMethod: "DELETE",
    path: "api/Health/templates",
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var appVersionOperationSpec = {
    httpMethod: "GET",
    path: "api/Health/version",
    responses: {
        200: {
            bodyMapper: Mappers.AppVersionModel
        },
        default: {}
    },
    serializer: serializer
};
var helpLinkOperationSpec = {
    httpMethod: "GET",
    path: "api/Health/help-link",
    responses: {
        200: {
            bodyMapper: Mappers.HelpLinkModel
        },
        default: {}
    },
    serializer: serializer
};
var jobGetListOperationSpec = {
    httpMethod: "GET",
    path: "api/Job/list",
    responses: {
        200: {
            bodyMapper: Mappers.ApiV1JobList
        },
        default: {}
    },
    serializer: serializer
};
var jobStartOperationSpec = {
    httpMethod: "POST",
    path: "api/Job/start/{jobName}",
    urlParameters: [
        Parameters.jobName0
    ],
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.JobStartModel
    },
    responses: {
        200: {
            bodyMapper: Mappers.ApiV1Job
        },
        400: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var jobGetParametersOperationSpec = {
    httpMethod: "GET",
    path: "api/Job/parameters/{jobName}",
    urlParameters: [
        Parameters.jobName0
    ],
    queryParameters: [
        Parameters.triggerName0
    ],
    responses: {
        200: {
            bodyMapper: Mappers.JobDetailsModel
        },
        400: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var jobSaveParametersOperationSpec = {
    httpMethod: "POST",
    path: "api/Job/parameters/{triggerName}",
    urlParameters: [
        Parameters.triggerName1
    ],
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.JobSaveParametersModel
    },
    responses: {
        200: {
            bodyMapper: Mappers.ApiV1Job
        },
        400: {},
        default: {}
    },
    serializer: serializer
};
var jobCreateTriggerOperationSpec = {
    httpMethod: "POST",
    path: "api/Job/create",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.JobSaveParametersModel
    },
    responses: {
        200: {
            bodyMapper: Mappers.ApiV1Job
        },
        400: {},
        409: {
            bodyMapper: Mappers.JobSaveParametersResult
        },
        default: {}
    },
    serializer: serializer
};
var jobDeleteTriggerOperationSpec = {
    httpMethod: "DELETE",
    path: "api/Job/delete",
    queryParameters: [
        Parameters.triggerId,
        Parameters.jobName1
    ],
    responses: {
        200: {
            bodyMapper: Mappers.ApiV1Job
        },
        400: {},
        default: {}
    },
    serializer: serializer
};
var jobIsTriggerNameUniqueOperationSpec = {
    httpMethod: "GET",
    path: "api/Job/check-trigger-name",
    queryParameters: [
        Parameters.triggerName0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Boolean"
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var extendEditingTimeOperationSpec = {
    httpMethod: "POST",
    path: "api/Lock/extend",
    queryParameters: [
        Parameters.objectUniqueId0
    ],
    responses: {
        200: {
            bodyMapper: Mappers.ExtendTimeLockModel
        },
        default: {}
    },
    serializer: serializer
};
var createLockOperationSpec = {
    httpMethod: "POST",
    path: "api/Lock",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.LockCreateModel
    },
    responses: {
        200: {
            bodyMapper: Mappers.LockModelWithStatus
        },
        404: {},
        default: {}
    },
    serializer: serializer
};
var unLockOperationSpec = {
    httpMethod: "DELETE",
    path: "api/Lock",
    queryParameters: [
        Parameters.objectUniqueId0,
        Parameters.section
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Boolean"
                }
            }
        },
        404: {},
        default: {}
    },
    serializer: serializer
};
var getManualTasksOperationSpec = {
    httpMethod: "GET",
    path: "api/ManualTask/tasks",
    queryParameters: [
        Parameters.projectCodeName1,
        Parameters.gateId0,
        Parameters.isForCurrentPrincipal
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ManualTaskModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getManualTaskOperationSpec = {
    httpMethod: "GET",
    path: "api/ManualTask/{id}",
    urlParameters: [
        Parameters.id2
    ],
    responses: {
        200: {
            bodyMapper: Mappers.ManualTaskModel
        },
        default: {}
    },
    serializer: serializer
};
var createManualTaskOperationSpec = {
    httpMethod: "POST",
    path: "api/ManualTask",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.ManualTaskCreateModel
    },
    responses: {
        200: {},
        400: {},
        default: {}
    },
    serializer: serializer
};
var deleteManualTaskOperationSpec = {
    httpMethod: "DELETE",
    path: "api/ManualTask",
    queryParameters: [
        Parameters.manualTaskId0
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var updateManualTaskMainPartOperationSpec = {
    httpMethod: "PUT",
    path: "api/ManualTask/main-part",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.ManualTaskUpdateModel
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var updateManualTaskWorkerPartOperationSpec = {
    httpMethod: "PUT",
    path: "api/ManualTask/worker-part",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.ManualTaskUpdateModel
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var updateCLIRelatedManualTaskWorkerPartOperationSpec = {
    httpMethod: "PUT",
    path: "api/ManualTask/cli-worker-part",
    queryParameters: [
        Parameters.manualTaskId0
    ],
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.CheckListItemApprovedDetailsModel
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var updateCLIDAssessmentRelatedManualTaskWorkerPartOperationSpec = {
    httpMethod: "PUT",
    path: "api/ManualTask/clid-assessment-worker-part",
    queryParameters: [
        Parameters.manualTaskId0
    ],
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.GateAssessmentKeyDeliverableModel
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var updateCLIQAssessmentRelatedManualTaskWorkerPartOperationSpec = {
    httpMethod: "PUT",
    path: "api/ManualTask/cliq-assessment-worker-part",
    queryParameters: [
        Parameters.manualTaskId0
    ],
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.GateAssessmentExecutiveQuestion
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var updateMilestoneDeliverableRelatedManualTaskWorkerPartOperationSpec = {
    httpMethod: "PUT",
    path: "api/ManualTask/milestone-clid-worker-part",
    queryParameters: [
        Parameters.manualTaskId0
    ],
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.GateMilestoneDeliverableModel
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var updateMilestoneExecutiveQuestionRelatedManualTaskWorkerPartOperationSpec = {
    httpMethod: "PUT",
    path: "api/ManualTask/milestone-cliq-worker-part",
    queryParameters: [
        Parameters.manualTaskId0
    ],
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.GateMilestoneQuestionModel
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var okCLIRelatedManualTaskOperationSpec = {
    httpMethod: "PUT",
    path: "api/ManualTask/ok",
    queryParameters: [
        Parameters.manualTaskId0,
        Parameters.checkListItemId0
    ],
    responses: {
        200: {
            bodyMapper: Mappers.CheckListItemApprovedDetailsModel
        },
        default: {}
    },
    serializer: serializer
};
var completeManualTaskOperationSpec = {
    httpMethod: "PUT",
    path: "api/ManualTask/complete",
    queryParameters: [
        Parameters.manualTaskId0,
        Parameters.state
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var approveManualTaskOperationSpec = {
    httpMethod: "PUT",
    path: "api/ManualTask/approve",
    queryParameters: [
        Parameters.manualTaskId0
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var denyManualTaskOperationSpec = {
    httpMethod: "PUT",
    path: "api/ManualTask/deny",
    queryParameters: [
        Parameters.manualTaskId0,
        Parameters.comment
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var uploadManualTaskAttachmentOperationSpec = {
    httpMethod: "POST",
    path: "api/ManualTask/{manualTaskId}/attachment-upload",
    urlParameters: [
        Parameters.manualTaskId1
    ],
    formDataParameters: [
        Parameters.file
    ],
    contentType: "multipart/form-data",
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var deleteManualTaskAttachmentOperationSpec = {
    httpMethod: "DELETE",
    path: "api/ManualTask/{manualTaskId}/attachment-delete",
    urlParameters: [
        Parameters.manualTaskId1
    ],
    queryParameters: [
        Parameters.attachmentId
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var getProjectMeetingsOperationSpec = {
    httpMethod: "GET",
    path: "api/Meeting/list/project",
    queryParameters: [
        Parameters.projectId0,
        Parameters.gateId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "MeetingShortModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getMeetingByGateOperationSpec = {
    httpMethod: "GET",
    path: "api/Meeting/for-gate",
    queryParameters: [
        Parameters.gateId0
    ],
    responses: {
        200: {
            bodyMapper: Mappers.MeetingModel
        },
        default: {}
    },
    serializer: serializer
};
var updateMeetingOperationSpec = {
    httpMethod: "PUT",
    path: "api/Meeting/update",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.MeetingUpdateModel
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var updateParticipantOperationSpec = {
    httpMethod: "PUT",
    path: "api/Meeting/update-participant",
    queryParameters: [
        Parameters.meetingId0,
        Parameters.principalId0,
        Parameters.isMandatory
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var uploadAttachmentOperationSpec = {
    httpMethod: "POST",
    path: "api/Meeting/{meetingId}/file",
    urlParameters: [
        Parameters.meetingId1
    ],
    formDataParameters: [
        Parameters.file
    ],
    contentType: "multipart/form-data",
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var removeAttachmentOperationSpec = {
    httpMethod: "DELETE",
    path: "api/Meeting/{meetingId}/file",
    urlParameters: [
        Parameters.meetingId1
    ],
    queryParameters: [
        Parameters.attachmentId
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var setExternaApprovalOperationSpec = {
    httpMethod: "PUT",
    path: "rest/MFilesRestApi/set-external-approval",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.MFilesDeliverableSetExternalRestApiModel
    },
    responses: {
        200: {
            bodyMapper: Mappers.MFilesDeliverableRestApiResponseModel
        },
        default: {}
    },
    serializer: serializer
};
var externalDeletedOperationSpec = {
    httpMethod: "PUT",
    path: "rest/MFilesRestApi/external-deleted",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.MFilesDeliverableResetExternalRestApiModel
    },
    responses: {
        200: {
            bodyMapper: Mappers.MFilesDeliverableRestApiResponseModel
        },
        default: {}
    },
    serializer: serializer
};
var denyApprovalOperationSpec = {
    httpMethod: "PUT",
    path: "rest/MFilesRestApi/deny-approval",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.MFilesDeliverableDenyApprovalRestApiModel
    },
    responses: {
        200: {
            bodyMapper: Mappers.MFilesDeliverableRestApiResponseModel
        },
        default: {}
    },
    serializer: serializer
};
var setInApprovalOperationSpec = {
    httpMethod: "PUT",
    path: "rest/MFilesRestApi/set-in-approval",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.MFilesDeliverableSetInApprovalRestApiModel
    },
    responses: {
        200: {
            bodyMapper: Mappers.MFilesDeliverableRestApiResponseModel
        },
        default: {}
    },
    serializer: serializer
};
var setApprovedOperationSpec = {
    httpMethod: "PUT",
    path: "rest/MFilesRestApi/set-approved",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.MFilesDeliverableSetApprovedRestApiModel
    },
    responses: {
        200: {
            bodyMapper: Mappers.MFilesDeliverableRestApiResponseModel
        },
        default: {}
    },
    serializer: serializer
};
var undoApproval1OperationSpec = {
    httpMethod: "PUT",
    path: "rest/MFilesRestApi/undo-approval",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.MFilesDeliverableUndoApprovalRestApiModel
    },
    responses: {
        200: {
            bodyMapper: Mappers.MFilesDeliverableRestApiResponseModel
        },
        default: {}
    },
    serializer: serializer
};
var getMilestoneDeliverablesOperationSpec = {
    httpMethod: "GET",
    path: "api/Milestone/deliverables",
    queryParameters: [
        Parameters.projectId0,
        Parameters.gateId0,
        Parameters.hideNAItems
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GateMilestoneDeliverableModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getMilestoneDeliverableOperationSpec = {
    httpMethod: "GET",
    path: "api/Milestone/deliverable",
    queryParameters: [
        Parameters.checklistItemId,
        Parameters.hideNAItems
    ],
    responses: {
        200: {
            bodyMapper: Mappers.GateMilestoneDeliverableModel
        },
        default: {}
    },
    serializer: serializer
};
var updateMilestoneDeliverableOperationSpec = {
    httpMethod: "PUT",
    path: "api/Milestone/deliverables/project-manager",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.GateMilestoneDeliverableModel
    },
    responses: {
        200: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var getMilestoneQuestionsOperationSpec = {
    httpMethod: "GET",
    path: "api/Milestone/questions",
    queryParameters: [
        Parameters.projectId0,
        Parameters.gateId0,
        Parameters.hideNAItems
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GateMilestoneQuestionModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getMilestoneQuestionOperationSpec = {
    httpMethod: "GET",
    path: "api/Milestone/question",
    queryParameters: [
        Parameters.checklistItemId
    ],
    responses: {
        200: {
            bodyMapper: Mappers.GateMilestoneQuestionModel
        },
        default: {}
    },
    serializer: serializer
};
var updateMilestoneQuestionOperationSpec = {
    httpMethod: "PUT",
    path: "api/Milestone/question/project-manager",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.GateMilestoneQuestionModel
    },
    responses: {
        200: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var getOrgUnitsLookupInfoOperationSpec = {
    httpMethod: "GET",
    path: "api/OrgUnit/lookup-info",
    queryParameters: [
        Parameters.isForProject,
        Parameters.isActiveOnly,
        Parameters.isActiveOrWithProjectsOnly
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GenericLookupInfo"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getOrgUnitInfoOperationSpec = {
    httpMethod: "GET",
    path: "api/OrgUnit/info",
    queryParameters: [
        Parameters.id1
    ],
    responses: {
        200: {
            bodyMapper: Mappers.OrgUnitShortModel
        },
        default: {}
    },
    serializer: serializer
};
var getOrgUnitsOperationSpec = {
    httpMethod: "GET",
    path: "rest/OrgUnitRestApi",
    queryParameters: [
        Parameters.id0,
        Parameters.codeName,
        Parameters.includeChildren
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "OrgUnitRestApiModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var searchPrincipalsOperationSpec = {
    httpMethod: "GET",
    path: "api/Principal/search",
    queryParameters: [
        Parameters.page,
        Parameters.size1,
        Parameters.term,
        Parameters.exceptPrincipalIds,
        Parameters.projectId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "PrincipalModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var createPrincipalOperationSpec = {
    httpMethod: "POST",
    path: "api/Principal/create",
    queryParameters: [
        Parameters.email0
    ],
    responses: {
        200: {
            bodyMapper: Mappers.PrincipalModel
        },
        default: {}
    },
    serializer: serializer
};
var getPrincipalsOperationSpec = {
    httpMethod: "GET",
    path: "rest/PrincipalRestApi",
    queryParameters: [
        Parameters.id0,
        Parameters.email1,
        Parameters.displayName
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "PrincipalRestApiModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getProjectsOperationSpec = {
    httpMethod: "GET",
    path: "api/Project",
    queryParameters: [
        Parameters.projectDisplayNameAndNumber,
        Parameters.projectLeaderIds,
        Parameters.gateAssessorIds,
        Parameters.teamMemberIds,
        Parameters.orgUnitId0,
        Parameters.statuses,
        Parameters.categories,
        Parameters.years,
        Parameters.favoritesOnly,
        Parameters.checklistCodes,
        Parameters.passedGateNumbers,
        Parameters.milestoneNumbers,
        Parameters.isLastPassedGateOnly,
        Parameters.isIncludeSkipped,
        Parameters.passedGateYear,
        Parameters.isOverdueGateOnly,
        Parameters.page,
        Parameters.size1,
        Parameters.sortByName,
        Parameters.orderBy
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ProjectShortModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var downloadProjectsOperationSpec = {
    httpMethod: "GET",
    path: "api/Project/download-projects",
    queryParameters: [
        Parameters.projectDisplayNameAndNumber,
        Parameters.projectLeaderIds,
        Parameters.gateAssessorIds,
        Parameters.teamMemberIds,
        Parameters.orgUnitId0,
        Parameters.statuses,
        Parameters.categories,
        Parameters.years,
        Parameters.favoritesOnly,
        Parameters.checklistCodes,
        Parameters.passedGateNumbers,
        Parameters.milestoneNumbers,
        Parameters.isLastPassedGateOnly,
        Parameters.isIncludeSkipped,
        Parameters.passedGateYear,
        Parameters.isOverdueGateOnly,
        Parameters.sortByName,
        Parameters.orderBy
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Stream"
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getProjectMasterChecklistTemplateLookupsOperationSpec = {
    httpMethod: "GET",
    path: "api/Project/project-master-checklist-template-lookup",
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "LookupStringValueModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getProjectYearLookupsOperationSpec = {
    httpMethod: "GET",
    path: "api/Project/project-year-lookup",
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "LookupModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getProjectPassedGateYearLookupsOperationSpec = {
    httpMethod: "GET",
    path: "api/Project/project-passed-gate-year-lookup",
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "LookupModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getProjectsCreationDataFromNorrdOperationSpec = {
    httpMethod: "GET",
    path: "api/Project/data-from-norrd",
    queryParameters: [
        Parameters.projectNumber,
        Parameters.projectName,
        Parameters.leadOrgUnit,
        Parameters.page,
        Parameters.size1,
        Parameters.sortByName,
        Parameters.orderBy
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ProjectCreationDataFromNorrdModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var createProjectWithDataFromNorrdOperationSpec = {
    httpMethod: "POST",
    path: "api/Project/data-from-norrd",
    queryParameters: [
        Parameters.norrdProjectId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Number"
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var updateNorrdProjectIgnoreStatusOperationSpec = {
    httpMethod: "PUT",
    path: "api/Project/data-from-norrd/ignored/{norrdProjectId}",
    urlParameters: [
        Parameters.norrdProjectId1
    ],
    queryParameters: [
        Parameters.isIgnored
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var getProjectOperationSpec = {
    httpMethod: "GET",
    path: "api/Project/{projectCodeName}",
    urlParameters: [
        Parameters.projectCodeName2
    ],
    responses: {
        200: {
            bodyMapper: Mappers.ProjectDetailsModel
        },
        403: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var getProjectWithGatesOperationSpec = {
    httpMethod: "GET",
    path: "api/Project/{projectId}/with-gates",
    urlParameters: [
        Parameters.projectId2
    ],
    responses: {
        200: {
            bodyMapper: Mappers.ProjectGatesModel
        },
        default: {}
    },
    serializer: serializer
};
var getNorrdProjectOperationSpec = {
    httpMethod: "GET",
    path: "api/Project/norrd-info",
    queryParameters: [
        Parameters.id1
    ],
    responses: {
        200: {
            bodyMapper: Mappers.ProjectCreationDataFromNorrdModel
        },
        default: {}
    },
    serializer: serializer
};
var getProjectsLookupInfoOperationSpec = {
    httpMethod: "GET",
    path: "api/Project/lookup-info",
    queryParameters: [
        Parameters.showDisplayName
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GenericLookupInfo"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getProjectInfoOperationSpec = {
    httpMethod: "GET",
    path: "api/Project/info",
    queryParameters: [
        Parameters.id1
    ],
    responses: {
        200: {
            bodyMapper: Mappers.ProjectInfoModel
        },
        default: {}
    },
    serializer: serializer
};
var removeWholeProjectOperationSpec = {
    httpMethod: "DELETE",
    path: "api/Project/remove-whole",
    queryParameters: [
        Parameters.id1
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var enableOrDisableNotificationsOperationSpec = {
    httpMethod: "PUT",
    path: "api/Project/notifications",
    queryParameters: [
        Parameters.projectId0,
        Parameters.isEnabled1
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var updateAllowedOnlyActiveCategoriesOperationSpec = {
    httpMethod: "PUT",
    path: "api/Project/only-active-categories",
    queryParameters: [
        Parameters.projectId0,
        Parameters.value1
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var createProjectOperationSpec = {
    httpMethod: "POST",
    path: "api/Project/create",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.ProjectCreateModel
    },
    responses: {
        200: {
            bodyMapper: Mappers.ProjectCreateResponseModel
        },
        default: {}
    },
    serializer: serializer
};
var canCreateProjectOperationSpec = {
    httpMethod: "GET",
    path: "api/Project/can-create",
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Boolean"
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getProjectCodeNameOperationSpec = {
    httpMethod: "GET",
    path: "api/Project/code-name",
    queryParameters: [
        Parameters.projectId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "String"
                }
            }
        },
        404: {},
        default: {}
    },
    serializer: serializer
};
var updateProjectGaneralInfoOperationSpec = {
    httpMethod: "PUT",
    path: "api/Project/update",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.ProjectUpdateModel
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var updateStatusOperationSpec = {
    httpMethod: "PUT",
    path: "api/Project/update-status",
    queryParameters: [
        Parameters.projectId0,
        Parameters.status1
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var rollbackStatusOperationSpec = {
    httpMethod: "PUT",
    path: "api/Project/rollback-status",
    queryParameters: [
        Parameters.projectId0
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var updateHideNAItemsOperationSpec = {
    httpMethod: "PUT",
    path: "api/Project/hide-na-items",
    queryParameters: [
        Parameters.projectId0,
        Parameters.isEnabled1
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var updateShowRedDotDeliverableOperationSpec = {
    httpMethod: "PUT",
    path: "api/Project/show-red-dot-deliverable",
    queryParameters: [
        Parameters.projectId0,
        Parameters.isEnabled1
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var searchProjectsOperationSpec = {
    httpMethod: "GET",
    path: "api/Project/search-projects",
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ProjectSearchModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var synchronizeWithNorrdOperationSpec = {
    httpMethod: "PUT",
    path: "api/Project/synchronize-with-norrd",
    queryParameters: [
        Parameters.projectId0
    ],
    responses: {
        200: {
            bodyMapper: Mappers.ProjectSynchronizationWithNorrdResult
        },
        default: {}
    },
    serializer: serializer
};
var getProjectDocumentsOperationSpec = {
    httpMethod: "GET",
    path: "api/ProjectDocument/documents",
    queryParameters: [
        Parameters.projectId0,
        Parameters.showDeletedDocuments
    ],
    responses: {
        200: {
            bodyMapper: Mappers.ProjectDocumentsModel
        },
        default: {}
    },
    serializer: serializer
};
var deleteProjectDocumentOperationSpec = {
    httpMethod: "DELETE",
    path: "api/ProjectDocument/document",
    queryParameters: [
        Parameters.id1
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var createProjectDocumentOperationSpec = {
    httpMethod: "POST",
    path: "api/ProjectDocument/document",
    queryParameters: [
        Parameters.projectId0
    ],
    formDataParameters: [
        Parameters.file
    ],
    contentType: "multipart/form-data",
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Number"
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var restoreProjectDocumentOperationSpec = {
    httpMethod: "DELETE",
    path: "api/ProjectDocument/restore",
    queryParameters: [
        Parameters.id1
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var updateProjectDocumentCommentOperationSpec = {
    httpMethod: "PUT",
    path: "api/ProjectDocument/document/file-comment",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.UpdateProjectDocumentFileCommentModel
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var updateProjectDocumentFileNameOperationSpec = {
    httpMethod: "PUT",
    path: "api/ProjectDocument/document/file-name",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.UpdateProjectDocumentFileNameModel
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var restoreProjectDocumentFileOperationSpec = {
    httpMethod: "PUT",
    path: "api/ProjectDocument/document/file-restore",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.RestoreProjectDocumentFileModel
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var updateProjectDocumentCategoryOperationSpec = {
    httpMethod: "PUT",
    path: "api/ProjectDocument/document/file-document-category",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.UpdateProjectDocumentCategoryModel
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var updateProjectDocumentGateOperationSpec = {
    httpMethod: "PUT",
    path: "api/ProjectDocument/document/file-document-gate",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: Mappers.UpdateProjectDocumentGateModel
    },
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var getProjectDocumentFilesOperationSpec = {
    httpMethod: "GET",
    path: "api/ProjectDocument/document/files",
    queryParameters: [
        Parameters.documentId,
        Parameters.showDeletedFiles
    ],
    responses: {
        200: {
            bodyMapper: Mappers.ProjectDocumentFilesModel
        },
        default: {}
    },
    serializer: serializer
};
var getProjectDocumentFileInfosOperationSpec = {
    httpMethod: "GET",
    path: "api/ProjectDocument/document/file-infos",
    queryParameters: [
        Parameters.projectId0,
        Parameters.documentId
    ],
    responses: {
        200: {
            bodyMapper: Mappers.ProjectDocumentFileInfosModel
        },
        default: {}
    },
    serializer: serializer
};
var uploadProjectDocumentFileOperationSpec = {
    httpMethod: "POST",
    path: "api/ProjectDocument/document-file",
    queryParameters: [
        Parameters.documentId
    ],
    formDataParameters: [
        Parameters.file
    ],
    contentType: "multipart/form-data",
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Number"
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var deleteProjectDocumentFileOperationSpec = {
    httpMethod: "DELETE",
    path: "api/ProjectDocument/document/file",
    queryParameters: [
        Parameters.documentId,
        Parameters.fileInformationId
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var getProjectReadersOperationSpec = {
    httpMethod: "GET",
    path: "api/ProjectReader",
    queryParameters: [
        Parameters.projectId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ProjectReaderModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var deleteProjectReaderOperationSpec = {
    httpMethod: "DELETE",
    path: "api/ProjectReader",
    queryParameters: [
        Parameters.projectId0,
        Parameters.principalId0
    ],
    responses: {
        200: {},
        403: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var createProjectReadersOperationSpec = {
    httpMethod: "POST",
    path: "api/ProjectReader",
    requestBody: {
        parameterPath: [
            "options",
            "body"
        ],
        mapper: {
            serializedName: "body",
            type: {
                name: "Sequence",
                element: {
                    type: {
                        name: "Composite",
                        className: "ProjectReaderCreateModel"
                    }
                }
            }
        }
    },
    responses: {
        200: {},
        403: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var getProjects1OperationSpec = {
    httpMethod: "GET",
    path: "rest/ProjectRestApi/all",
    queryParameters: [
        Parameters.id0,
        Parameters.responsibleOrgUnitId,
        Parameters.responsibleOrgUnitCodeName,
        Parameters.includeOrgUnitChildren,
        Parameters.plannedStartDateFrom,
        Parameters.plannedStartDateTo,
        Parameters.plannedEndDateFrom,
        Parameters.plannedEndDateTo,
        Parameters.leaderPrincipalEmail
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ProjectRestApiModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getProjectsRestApiOperationSpec = {
    httpMethod: "GET",
    path: "rest/ProjectRestApi",
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ProjectShortInfoRestApiModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getRelatedFunctionsByProjectOperationSpec = {
    httpMethod: "GET",
    path: "api/RelatedFunction",
    queryParameters: [
        Parameters.projectId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "RelatedFunctionModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getGatesRelatedFunctionsByProjectOperationSpec = {
    httpMethod: "GET",
    path: "api/RelatedFunction/gates-related-functions",
    queryParameters: [
        Parameters.projectId0,
        Parameters.gateId0,
        Parameters.functionId
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "RelatedFunctionModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getRelatedFunctionsOperationSpec = {
    httpMethod: "GET",
    path: "rest/RelatedFunctionRestApi",
    queryParameters: [
        Parameters.id0,
        Parameters.projectId1,
        Parameters.projectCodeName0,
        Parameters.gateId1,
        Parameters.gateStartContainerNumber,
        Parameters.principalEmail
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "RelatedFunctionRestApiModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getObjectPermissionsOperationSpec = {
    httpMethod: "GET",
    path: "api/Security",
    queryParameters: [
        Parameters.objectUniqueId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Number"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getAppSettingsOperationSpec = {
    httpMethod: "GET",
    path: "api/Security/app-settings",
    responses: {
        200: {
            bodyMapper: Mappers.AppSecurityModel
        },
        default: {}
    },
    serializer: serializer
};
var doesUserHaveAnyPermissionOnAnyObjectOperationSpec = {
    httpMethod: "GET",
    path: "api/Security/does-user-have-any-permission-on-any-object",
    queryParameters: [
        Parameters.permissionIds
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Boolean"
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var searchSecurableObjectOperationSpec = {
    httpMethod: "GET",
    path: "api/SecurityTabs/search-securable-object",
    queryParameters: [
        Parameters.term
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var searchRolesOperationSpec = {
    httpMethod: "GET",
    path: "api/SecurityTabs/search-roles",
    queryParameters: [
        Parameters.term
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GenericLookupInfo"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getRolesOnObjectOperationSpec = {
    httpMethod: "GET",
    path: "api/SecurityTabs/roles-on-object",
    queryParameters: [
        Parameters.uniqueId
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ObjectRoleInfo"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getRoleMembersOperationSpec = {
    httpMethod: "GET",
    path: "api/SecurityTabs/role-members",
    queryParameters: [
        Parameters.objectUniqueId0,
        Parameters.roleId0,
        Parameters.inheritedFrom
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "RoleMemberModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var createRoleMemberOperationSpec = {
    httpMethod: "POST",
    path: "api/SecurityTabs/role-member",
    queryParameters: [
        Parameters.objectUniqueId1,
        Parameters.roleId1,
        Parameters.principalId1,
        Parameters.principalEmail,
        Parameters.principalCodeName,
        Parameters.principalDisplayName,
        Parameters.canDelete
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var deleteRoleMemberOperationSpec = {
    httpMethod: "DELETE",
    path: "api/SecurityTabs/role-member",
    queryParameters: [
        Parameters.objectUniqueId1,
        Parameters.roleId1,
        Parameters.principalId1,
        Parameters.principalEmail,
        Parameters.principalCodeName,
        Parameters.principalDisplayName,
        Parameters.canDelete
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var getUserRolesOnObjectsOperationSpec = {
    httpMethod: "GET",
    path: "api/SecurityTabs/user-roles",
    queryParameters: [
        Parameters.principalId0,
        Parameters.objectUniqueId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "PrincipalRoleItem"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getUsersInRolesOnObjectOperationSpec = {
    httpMethod: "GET",
    path: "api/SecurityTabs/users-in-role",
    queryParameters: [
        Parameters.roleId0,
        Parameters.objectUniqueId0
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "PrincipalInRoleOnObjectItem"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var downloadFullLogsOperationSpec = {
    httpMethod: "GET",
    path: "api/Service/download-full-logs",
    queryParameters: [
        Parameters.isErrorsOnly,
        Parameters.dateFrom,
        Parameters.dateTo
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Stream"
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getActivitiesStatisticsOperationSpec = {
    httpMethod: "GET",
    path: "api/Statistics/activities",
    queryParameters: [
        Parameters.dateTimeFrom,
        Parameters.dateTimeTo
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ActivityStatisticsModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getActivitiesStatisticsGraphOperationSpec = {
    httpMethod: "GET",
    path: "api/Statistics/activities-graph",
    queryParameters: [
        Parameters.dateTimeFrom,
        Parameters.dateTimeTo,
        Parameters.xScaleUnit
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ActivityStatisticsGraphModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getRequestStatisticsOperationSpec = {
    httpMethod: "GET",
    path: "api/Statistics/requests",
    queryParameters: [
        Parameters.dateTimeFrom,
        Parameters.dateTimeTo
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "RequestStatisticsModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getRequestStatisticsGraphOperationSpec = {
    httpMethod: "GET",
    path: "api/Statistics/requests-graph",
    queryParameters: [
        Parameters.dateTimeFrom,
        Parameters.dateTimeTo,
        Parameters.xScaleUnit
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "RequestStatisticsGraphModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getJobsStatisticsOperationSpec = {
    httpMethod: "GET",
    path: "api/Statistics/jobs",
    queryParameters: [
        Parameters.dateTimeFrom,
        Parameters.dateTimeTo
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "JobStatisticsModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getJobsStatisticsGraphOperationSpec = {
    httpMethod: "GET",
    path: "api/Statistics/jobs-graph",
    queryParameters: [
        Parameters.dateTimeFrom,
        Parameters.dateTimeTo,
        Parameters.xScaleUnit
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "JobStatisticsGraphModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getUsersCountersOperationSpec = {
    httpMethod: "GET",
    path: "api/Statistics/users",
    queryParameters: [
        Parameters.dateTimeFrom,
        Parameters.dateTimeTo
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "UserStatisticsModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getTemplatesOperationSpec = {
    httpMethod: "GET",
    path: "api/Template",
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ProjectTemplateModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var generateModelsFromTemplateOperationSpec = {
    httpMethod: "PUT",
    path: "api/Template",
    queryParameters: [
        Parameters.projectId0,
        Parameters.projectTemplateId0
    ],
    responses: {
        200: {},
        400: {},
        default: {}
    },
    serializer: serializer
};
var deleteTemplateOperationSpec = {
    httpMethod: "DELETE",
    path: "api/Template",
    queryParameters: [
        Parameters.projectTemplateId0
    ],
    responses: {
        200: {},
        default: {}
    },
    serializer: serializer
};
var getEnabledTemplatesOperationSpec = {
    httpMethod: "GET",
    path: "api/Template/enabled",
    queryParameters: [
        Parameters.projectId0,
        Parameters.isNorrdProject
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ProjectTemplateModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var getProjectTemplatesInfoOperationSpec = {
    httpMethod: "GET",
    path: "api/Template/{projectId}",
    urlParameters: [
        Parameters.projectId2
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ProjectTemplateModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
var changeProjectTemplateOperationSpec = {
    httpMethod: "PUT",
    path: "api/Template/{projectTemplateId}/enabled",
    urlParameters: [
        Parameters.projectTemplateId1
    ],
    queryParameters: [
        Parameters.isEnabled1
    ],
    responses: {
        200: {},
        404: {},
        default: {}
    },
    serializer: serializer
};
var getProjectTemplateLookupModelsOperationSpec = {
    httpMethod: "GET",
    path: "api/Template/project-template-lookups",
    queryParameters: [
        Parameters.withVersion
    ],
    responses: {
        200: {
            bodyMapper: {
                serializedName: "parsedResponse",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "LookupModel"
                        }
                    }
                }
            }
        },
        default: {}
    },
    serializer: serializer
};
export { TeamGateApiSecured, TeamGateApiSecuredContext, Models as TeamGateApiSecuredModels, Mappers as TeamGateApiSecuredMappers };
