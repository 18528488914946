/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is regenerated.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var PrincipalModel = {
    serializedName: "PrincipalModel",
    type: {
        name: "Composite",
        className: "PrincipalModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            value: {
                required: true,
                serializedName: "value",
                type: {
                    name: "Number"
                }
            },
            displayName: {
                required: true,
                serializedName: "displayName",
                type: {
                    name: "String"
                }
            },
            email: {
                required: true,
                serializedName: "email",
                type: {
                    name: "String"
                }
            },
            code: {
                required: true,
                serializedName: "code",
                type: {
                    name: "String"
                }
            },
            country: {
                serializedName: "country",
                type: {
                    name: "String"
                }
            },
            subDepartment: {
                serializedName: "subDepartment",
                type: {
                    name: "String"
                }
            },
            objectId: {
                serializedName: "objectId",
                type: {
                    name: "String"
                }
            },
            isActive: {
                serializedName: "isActive",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var LookupModel = {
    serializedName: "LookupModel",
    type: {
        name: "Composite",
        className: "LookupModel",
        modelProperties: {
            value: {
                required: true,
                serializedName: "value",
                type: {
                    name: "Number"
                }
            },
            label: {
                serializedName: "label",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var DenyCommentModel = {
    serializedName: "DenyCommentModel",
    type: {
        name: "Composite",
        className: "DenyCommentModel",
        modelProperties: {
            comment: {
                required: true,
                serializedName: "comment",
                type: {
                    name: "String"
                }
            },
            created: {
                required: true,
                serializedName: "created",
                type: {
                    name: "DateTime"
                }
            }
        }
    }
};
export var ActionItemDetailsModelFunctionPrincipal = {
    serializedName: "ActionItemDetailsModel_functionPrincipal",
    type: {
        name: "Composite",
        className: "ActionItemDetailsModelFunctionPrincipal",
        modelProperties: __assign({}, PrincipalModel.type.modelProperties)
    }
};
export var ActionItemDetailsModelSupportedFunctionPrincipal = {
    serializedName: "ActionItemDetailsModel_supportedFunctionPrincipal",
    type: {
        name: "Composite",
        className: "ActionItemDetailsModelSupportedFunctionPrincipal",
        modelProperties: __assign({}, PrincipalModel.type.modelProperties)
    }
};
export var ActionItemDetailsModel = {
    serializedName: "ActionItemDetailsModel",
    type: {
        name: "Composite",
        className: "ActionItemDetailsModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            code: {
                serializedName: "code",
                type: {
                    name: "String"
                }
            },
            number: {
                required: true,
                serializedName: "number",
                type: {
                    name: "Number"
                }
            },
            actionText: {
                required: true,
                serializedName: "actionText",
                type: {
                    name: "String"
                }
            },
            deadlineType: {
                serializedName: "deadlineType",
                type: {
                    name: "String"
                }
            },
            deadlineDate: {
                serializedName: "deadlineDate",
                type: {
                    name: "DateTime"
                }
            },
            deadlineGateId: {
                serializedName: "deadlineGateId",
                type: {
                    name: "Number"
                }
            },
            deadlineGateDisplayName: {
                serializedName: "deadlineGateDisplayName",
                type: {
                    name: "String"
                }
            },
            functionRating: {
                required: true,
                serializedName: "functionRating",
                type: {
                    name: "String"
                }
            },
            functionId: {
                serializedName: "functionId",
                type: {
                    name: "Number"
                }
            },
            functionName: {
                serializedName: "functionName",
                type: {
                    name: "String"
                }
            },
            functionPrincipalId: {
                serializedName: "functionPrincipalId",
                type: {
                    name: "Number"
                }
            },
            functionPrincipal: {
                serializedName: "functionPrincipal",
                type: {
                    name: "Composite",
                    className: "ActionItemDetailsModelFunctionPrincipal"
                }
            },
            supportedFunctionId: {
                serializedName: "supportedFunctionId",
                type: {
                    name: "Number"
                }
            },
            supportedFunctionName: {
                serializedName: "supportedFunctionName",
                type: {
                    name: "String"
                }
            },
            supportedFunctionPrincipalId: {
                serializedName: "supportedFunctionPrincipalId",
                type: {
                    name: "Number"
                }
            },
            supportedFunctionPrincipal: {
                serializedName: "supportedFunctionPrincipal",
                type: {
                    name: "Composite",
                    className: "ActionItemDetailsModelSupportedFunctionPrincipal"
                }
            },
            responsibleDisplayText: {
                required: true,
                serializedName: "responsibleDisplayText",
                type: {
                    name: "String"
                }
            },
            assessorRating: {
                required: true,
                serializedName: "assessorRating",
                type: {
                    name: "String"
                }
            },
            remarks: {
                serializedName: "remarks",
                type: {
                    name: "String"
                }
            },
            currentStatus: {
                serializedName: "currentStatus",
                type: {
                    name: "String"
                }
            },
            gateProcessType: {
                required: true,
                serializedName: "gateProcessType",
                type: {
                    name: "String"
                }
            },
            creationDate: {
                serializedName: "creationDate",
                type: {
                    name: "DateTime"
                }
            },
            approvedDate: {
                serializedName: "approvedDate",
                type: {
                    name: "DateTime"
                }
            },
            gateId: {
                required: true,
                serializedName: "gateId",
                type: {
                    name: "Number"
                }
            },
            relatedChecklists: {
                serializedName: "relatedChecklists",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "LookupModel"
                        }
                    }
                }
            },
            relatedChecklistItems: {
                serializedName: "relatedChecklistItems",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "LookupModel"
                        }
                    }
                }
            },
            denyComments: {
                serializedName: "denyComments",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "DenyCommentModel"
                        }
                    }
                }
            },
            hasFile: {
                required: true,
                serializedName: "hasFile",
                type: {
                    name: "Boolean"
                }
            },
            currentFileInformationId: {
                serializedName: "currentFileInformationId",
                type: {
                    name: "Number"
                }
            },
            canPrincipalUpdateMainPart: {
                required: true,
                serializedName: "canPrincipalUpdateMainPart",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalUpdateWorkerPart: {
                required: true,
                serializedName: "canPrincipalUpdateWorkerPart",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalSetToInApproval: {
                required: true,
                serializedName: "canPrincipalSetToInApproval",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalApprove: {
                required: true,
                serializedName: "canPrincipalApprove",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalDelete: {
                required: true,
                serializedName: "canPrincipalDelete",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalManageDocument: {
                required: true,
                serializedName: "canPrincipalManageDocument",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var ActionItemCreateModel = {
    serializedName: "ActionItemCreateModel",
    type: {
        name: "Composite",
        className: "ActionItemCreateModel",
        modelProperties: {
            number: {
                serializedName: "number",
                type: {
                    name: "Number"
                }
            },
            actionText: {
                serializedName: "actionText",
                type: {
                    name: "String"
                }
            },
            deadlineType: {
                serializedName: "deadlineType",
                type: {
                    name: "String"
                }
            },
            deadlineDate: {
                serializedName: "deadlineDate",
                type: {
                    name: "DateTime"
                }
            },
            deadlineGateId: {
                serializedName: "deadlineGateId",
                type: {
                    name: "Number"
                }
            },
            functionRating: {
                serializedName: "functionRating",
                type: {
                    name: "String"
                }
            },
            functionId: {
                serializedName: "functionId",
                type: {
                    name: "Number"
                }
            },
            functionPrincipalId: {
                required: true,
                serializedName: "functionPrincipalId",
                type: {
                    name: "Number"
                }
            },
            supportedFunctionId: {
                serializedName: "supportedFunctionId",
                type: {
                    name: "Number"
                }
            },
            supportedFunctionPrincipalId: {
                serializedName: "supportedFunctionPrincipalId",
                type: {
                    name: "Number"
                }
            },
            assessorRating: {
                serializedName: "assessorRating",
                type: {
                    name: "String"
                }
            },
            remarks: {
                serializedName: "remarks",
                type: {
                    name: "String"
                }
            },
            gateProcessType: {
                required: true,
                serializedName: "gateProcessType",
                type: {
                    name: "String"
                }
            },
            gateId: {
                required: true,
                serializedName: "gateId",
                type: {
                    name: "Number"
                }
            },
            relatedChecklistsIds: {
                serializedName: "relatedChecklistsIds",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Number"
                        }
                    }
                }
            },
            relatedChecklistItemsIds: {
                serializedName: "relatedChecklistItemsIds",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Number"
                        }
                    }
                }
            }
        }
    }
};
export var ActionItemDocumentModel = {
    serializedName: "ActionItemDocumentModel",
    type: {
        name: "Composite",
        className: "ActionItemDocumentModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            fileInformationId: {
                required: true,
                serializedName: "fileInformationId",
                type: {
                    name: "Number"
                }
            },
            originalFileName: {
                serializedName: "originalFileName",
                type: {
                    name: "String"
                }
            },
            name: {
                serializedName: "name",
                type: {
                    name: "String"
                }
            },
            version: {
                required: true,
                serializedName: "version",
                type: {
                    name: "Number"
                }
            },
            uploadedDate: {
                required: true,
                serializedName: "uploadedDate",
                type: {
                    name: "DateTime"
                }
            },
            uploadedBy: {
                serializedName: "uploadedBy",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var ActionItemDocumentsModel = {
    serializedName: "ActionItemDocumentsModel",
    type: {
        name: "Composite",
        className: "ActionItemDocumentsModel",
        modelProperties: {
            actionItemId: {
                required: true,
                serializedName: "actionItemId",
                type: {
                    name: "Number"
                }
            },
            actionItemCode: {
                serializedName: "actionItemCode",
                type: {
                    name: "String"
                }
            },
            projectDisplayName: {
                serializedName: "projectDisplayName",
                type: {
                    name: "String"
                }
            },
            documents: {
                serializedName: "documents",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ActionItemDocumentModel"
                        }
                    }
                }
            }
        }
    }
};
export var ActionItemRestApiModel = {
    serializedName: "ActionItemRestApiModel",
    type: {
        name: "Composite",
        className: "ActionItemRestApiModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            uniqueId: {
                serializedName: "uniqueId",
                type: {
                    name: "String"
                }
            },
            projectCodeName: {
                serializedName: "projectCodeName",
                type: {
                    name: "String"
                }
            },
            code: {
                serializedName: "code",
                type: {
                    name: "String"
                }
            },
            number: {
                required: true,
                serializedName: "number",
                type: {
                    name: "Number"
                }
            },
            actionText: {
                serializedName: "actionText",
                type: {
                    name: "String"
                }
            },
            deadlineType: {
                serializedName: "deadlineType",
                type: {
                    name: "String"
                }
            },
            deadlineDate: {
                serializedName: "deadlineDate",
                type: {
                    name: "DateTime"
                }
            },
            deadlineGateId: {
                serializedName: "deadlineGateId",
                type: {
                    name: "Number"
                }
            },
            deadlineGateDisplayName: {
                serializedName: "deadlineGateDisplayName",
                type: {
                    name: "String"
                }
            },
            functionRating: {
                required: true,
                serializedName: "functionRating",
                type: {
                    name: "String"
                }
            },
            functionId: {
                serializedName: "functionId",
                type: {
                    name: "Number"
                }
            },
            functionName: {
                serializedName: "functionName",
                type: {
                    name: "String"
                }
            },
            functionPrincipalId: {
                serializedName: "functionPrincipalId",
                type: {
                    name: "Number"
                }
            },
            functionPrincipalDisplayName: {
                serializedName: "functionPrincipalDisplayName",
                type: {
                    name: "String"
                }
            },
            supportedFunctionId: {
                serializedName: "supportedFunctionId",
                type: {
                    name: "Number"
                }
            },
            supportedFunctionName: {
                serializedName: "supportedFunctionName",
                type: {
                    name: "String"
                }
            },
            supportedFunctionPrincipalId: {
                serializedName: "supportedFunctionPrincipalId",
                type: {
                    name: "Number"
                }
            },
            supportedFunctionPrincipalDisplayName: {
                serializedName: "supportedFunctionPrincipalDisplayName",
                type: {
                    name: "String"
                }
            },
            assessorRating: {
                required: true,
                serializedName: "assessorRating",
                type: {
                    name: "String"
                }
            },
            remarks: {
                serializedName: "remarks",
                type: {
                    name: "String"
                }
            },
            gateProcessType: {
                required: true,
                serializedName: "gateProcessType",
                type: {
                    name: "String"
                }
            },
            gateId: {
                required: true,
                serializedName: "gateId",
                type: {
                    name: "Number"
                }
            },
            gateDisplayName: {
                serializedName: "gateDisplayName",
                type: {
                    name: "String"
                }
            },
            creationDate: {
                required: true,
                serializedName: "creationDate",
                type: {
                    name: "DateTime"
                }
            },
            approvedDate: {
                serializedName: "approvedDate",
                type: {
                    name: "DateTime"
                }
            },
            currentStatus: {
                required: true,
                serializedName: "currentStatus",
                type: {
                    name: "String"
                }
            },
            url: {
                serializedName: "url",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var ApiKeyModelResponsiblePrincipal = {
    serializedName: "ApiKeyModel_responsiblePrincipal",
    type: {
        name: "Composite",
        className: "ApiKeyModelResponsiblePrincipal",
        modelProperties: __assign({}, PrincipalModel.type.modelProperties)
    }
};
export var ApiKeyModel = {
    serializedName: "ApiKeyModel",
    type: {
        name: "Composite",
        className: "ApiKeyModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            key: {
                serializedName: "key",
                type: {
                    name: "String"
                }
            },
            applicationName: {
                serializedName: "applicationName",
                type: {
                    name: "String"
                }
            },
            status: {
                required: true,
                serializedName: "status",
                type: {
                    name: "String"
                }
            },
            isSystem: {
                required: true,
                serializedName: "isSystem",
                type: {
                    name: "Boolean"
                }
            },
            responsiblePrincipal: {
                serializedName: "responsiblePrincipal",
                type: {
                    name: "Composite",
                    className: "ApiKeyModelResponsiblePrincipal"
                }
            }
        }
    }
};
export var ApiKeyGeneratedKeyModel = {
    serializedName: "ApiKeyGeneratedKeyModel",
    type: {
        name: "Composite",
        className: "ApiKeyGeneratedKeyModel",
        modelProperties: {
            key: {
                required: true,
                serializedName: "key",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var ApprovalModel = {
    serializedName: "ApprovalModel",
    type: {
        name: "Composite",
        className: "ApprovalModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            relatedFunctionId: {
                required: true,
                serializedName: "relatedFunctionId",
                type: {
                    name: "Number"
                }
            },
            projectId: {
                required: true,
                serializedName: "projectId",
                type: {
                    name: "Number"
                }
            },
            relatedFunctionFunctionId: {
                required: true,
                serializedName: "relatedFunctionFunctionId",
                type: {
                    name: "Number"
                }
            },
            relatedFunctionFunctionCode: {
                serializedName: "relatedFunctionFunctionCode",
                type: {
                    name: "String"
                }
            },
            relatedFunctionFunctionName: {
                serializedName: "relatedFunctionFunctionName",
                type: {
                    name: "String"
                }
            },
            relatedObjectUniqueId: {
                serializedName: "relatedObjectUniqueId",
                type: {
                    name: "String"
                }
            },
            relatedObjectCodeName: {
                serializedName: "relatedObjectCodeName",
                type: {
                    name: "String"
                }
            },
            relatedPrincipalDisplayName: {
                serializedName: "relatedPrincipalDisplayName",
                type: {
                    name: "String"
                }
            },
            dateFrom: {
                required: true,
                serializedName: "dateFrom",
                type: {
                    name: "DateTime"
                }
            },
            dateTo: {
                required: true,
                serializedName: "dateTo",
                type: {
                    name: "DateTime"
                }
            },
            approvalResult: {
                required: true,
                serializedName: "approvalResult",
                type: {
                    name: "String"
                }
            },
            approvalType: {
                required: true,
                serializedName: "approvalType",
                type: {
                    name: "String"
                }
            },
            created: {
                required: true,
                serializedName: "created",
                type: {
                    name: "DateTime"
                }
            },
            createdBy: {
                serializedName: "createdBy",
                type: {
                    name: "String"
                }
            },
            modified: {
                required: true,
                serializedName: "modified",
                type: {
                    name: "DateTime"
                }
            },
            modifiedBy: {
                serializedName: "modifiedBy",
                type: {
                    name: "String"
                }
            },
            canBeApproved: {
                required: true,
                serializedName: "canBeApproved",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalRemoveApproval: {
                required: true,
                serializedName: "canPrincipalRemoveApproval",
                type: {
                    name: "Boolean"
                }
            },
            deliverableName: {
                serializedName: "deliverableName",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var ApprovalCreateModel = {
    serializedName: "ApprovalCreateModel",
    type: {
        name: "Composite",
        className: "ApprovalCreateModel",
        modelProperties: {
            projectId: {
                required: true,
                serializedName: "projectId",
                type: {
                    name: "Number"
                }
            },
            gateId: {
                required: true,
                serializedName: "gateId",
                type: {
                    name: "Number"
                }
            },
            relatedObjectId: {
                required: true,
                serializedName: "relatedObjectId",
                type: {
                    name: "Number"
                }
            },
            functionId: {
                required: true,
                serializedName: "functionId",
                type: {
                    name: "Number"
                }
            },
            weekFrom: {
                required: true,
                serializedName: "weekFrom",
                type: {
                    name: "Number"
                }
            },
            weekTo: {
                required: true,
                serializedName: "weekTo",
                type: {
                    name: "Number"
                }
            },
            approvalType: {
                required: true,
                serializedName: "approvalType",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var ApprovalRestApiModel = {
    serializedName: "ApprovalRestApiModel",
    type: {
        name: "Composite",
        className: "ApprovalRestApiModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            uniqueId: {
                serializedName: "uniqueId",
                type: {
                    name: "String"
                }
            },
            projectCodeName: {
                serializedName: "projectCodeName",
                type: {
                    name: "String"
                }
            },
            gateId: {
                required: true,
                serializedName: "gateId",
                type: {
                    name: "Number"
                }
            },
            relatedFunctionId: {
                required: true,
                serializedName: "relatedFunctionId",
                type: {
                    name: "Number"
                }
            },
            relatedObjectUniqueId: {
                serializedName: "relatedObjectUniqueId",
                type: {
                    name: "String"
                }
            },
            relatedObjectCodeName: {
                serializedName: "relatedObjectCodeName",
                type: {
                    name: "String"
                }
            },
            dateFrom: {
                required: true,
                serializedName: "dateFrom",
                type: {
                    name: "DateTime"
                }
            },
            dateTo: {
                required: true,
                serializedName: "dateTo",
                type: {
                    name: "DateTime"
                }
            },
            approvalResult: {
                required: true,
                serializedName: "approvalResult",
                type: {
                    name: "String"
                }
            },
            created: {
                required: true,
                serializedName: "created",
                type: {
                    name: "DateTime"
                }
            },
            createdByPrincipalId: {
                required: true,
                serializedName: "createdByPrincipalId",
                type: {
                    name: "Number"
                }
            },
            createdByPrincipalDisplayName: {
                serializedName: "createdByPrincipalDisplayName",
                type: {
                    name: "String"
                }
            },
            modified: {
                required: true,
                serializedName: "modified",
                type: {
                    name: "DateTime"
                }
            },
            modifiedByPrincipalId: {
                required: true,
                serializedName: "modifiedByPrincipalId",
                type: {
                    name: "Number"
                }
            },
            modifiedByPrincipalDisplayName: {
                serializedName: "modifiedByPrincipalDisplayName",
                type: {
                    name: "String"
                }
            },
            isPreviousApproval: {
                required: true,
                serializedName: "isPreviousApproval",
                type: {
                    name: "Boolean"
                }
            },
            type: {
                required: true,
                serializedName: "type",
                type: {
                    name: "String"
                }
            },
            disabled: {
                required: true,
                serializedName: "disabled",
                type: {
                    name: "Boolean"
                }
            },
            isOverdueMessageSent: {
                serializedName: "isOverdueMessageSent",
                type: {
                    name: "Boolean"
                }
            },
            weekFrom: {
                serializedName: "weekFrom",
                type: {
                    name: "Number"
                }
            },
            weekTo: {
                serializedName: "weekTo",
                type: {
                    name: "Number"
                }
            },
            url: {
                serializedName: "url",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var LookupStringValueModel = {
    serializedName: "LookupStringValueModel",
    type: {
        name: "Composite",
        className: "LookupStringValueModel",
        modelProperties: {
            value: {
                serializedName: "value",
                type: {
                    name: "String"
                }
            },
            label: {
                serializedName: "label",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var AssignmentListItemModelProject = {
    serializedName: "AssignmentListItemModel_project",
    type: {
        name: "Composite",
        className: "AssignmentListItemModelProject",
        modelProperties: __assign({}, LookupStringValueModel.type.modelProperties)
    }
};
export var AssignmentListItemModel = {
    serializedName: "AssignmentListItemModel",
    type: {
        name: "Composite",
        className: "AssignmentListItemModel",
        modelProperties: {
            state: {
                required: true,
                serializedName: "state",
                type: {
                    name: "String"
                }
            },
            principals: {
                serializedName: "principals",
                type: {
                    name: "String"
                }
            },
            emailQueueLastRun: {
                serializedName: "emailQueueLastRun",
                type: {
                    name: "DateTime"
                }
            },
            emailQueueLastFailedRun: {
                serializedName: "emailQueueLastFailedRun",
                type: {
                    name: "DateTime"
                }
            },
            emailQueueLastErrorMessage: {
                serializedName: "emailQueueLastErrorMessage",
                type: {
                    name: "String"
                }
            },
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            description: {
                serializedName: "description",
                type: {
                    name: "String"
                }
            },
            dueDate: {
                required: true,
                serializedName: "dueDate",
                type: {
                    name: "DateTime"
                }
            },
            createdDate: {
                required: true,
                serializedName: "createdDate",
                type: {
                    name: "DateTime"
                }
            },
            project: {
                serializedName: "project",
                type: {
                    name: "Composite",
                    className: "AssignmentListItemModelProject"
                }
            },
            messageType: {
                required: true,
                serializedName: "messageType",
                type: {
                    name: "String"
                }
            },
            objectId: {
                required: true,
                serializedName: "objectId",
                type: {
                    name: "String"
                }
            },
            objectHierarchy: {
                serializedName: "objectHierarchy",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var DocumentModel = {
    serializedName: "DocumentModel",
    type: {
        name: "Composite",
        className: "DocumentModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            fileId: {
                required: true,
                serializedName: "fileId",
                type: {
                    name: "Number"
                }
            },
            name: {
                serializedName: "name",
                type: {
                    name: "String"
                }
            },
            uploadedDate: {
                required: true,
                serializedName: "uploadedDate",
                type: {
                    name: "DateTime"
                }
            },
            uploadedBy: {
                serializedName: "uploadedBy",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var ChangeLogFieldModel = {
    serializedName: "ChangeLogFieldModel",
    type: {
        name: "Composite",
        className: "ChangeLogFieldModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            name: {
                required: true,
                serializedName: "name",
                type: {
                    name: "String"
                }
            },
            oldValue: {
                required: true,
                serializedName: "oldValue",
                type: {
                    name: "String"
                }
            },
            newValue: {
                required: true,
                serializedName: "newValue",
                type: {
                    name: "String"
                }
            },
            isDateTimeFieldType: {
                serializedName: "isDateTimeFieldType",
                type: {
                    name: "Boolean"
                }
            },
            oldValueDate: {
                serializedName: "oldValueDate",
                type: {
                    name: "DateTime"
                }
            },
            newValueDate: {
                serializedName: "newValueDate",
                type: {
                    name: "DateTime"
                }
            }
        }
    }
};
export var ChangeLogObjectModel = {
    serializedName: "ChangeLogObjectModel",
    type: {
        name: "Composite",
        className: "ChangeLogObjectModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "String"
                }
            },
            objectType: {
                required: true,
                serializedName: "objectType",
                type: {
                    name: "String"
                }
            },
            actionType: {
                required: true,
                serializedName: "actionType",
                type: {
                    name: "String"
                }
            },
            fields: {
                required: true,
                serializedName: "fields",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ChangeLogFieldModel"
                        }
                    }
                }
            }
        }
    }
};
export var ChangeLogModel = {
    serializedName: "ChangeLogModel",
    type: {
        name: "Composite",
        className: "ChangeLogModel",
        modelProperties: {
            date: {
                required: true,
                serializedName: "date",
                type: {
                    name: "DateTime"
                }
            },
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Uuid"
                }
            },
            changedBy: {
                required: true,
                serializedName: "changedBy",
                type: {
                    name: "String"
                }
            },
            details: {
                required: true,
                serializedName: "details",
                type: {
                    name: "String"
                }
            },
            objects: {
                required: true,
                serializedName: "objects",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ChangeLogObjectModel"
                        }
                    }
                }
            }
        }
    }
};
export var ChecklistDetailsModel = {
    serializedName: "ChecklistDetailsModel",
    type: {
        name: "Composite",
        className: "ChecklistDetailsModel",
        modelProperties: {
            projectId: {
                required: true,
                serializedName: "projectId",
                type: {
                    name: "Number"
                }
            },
            version: {
                required: true,
                serializedName: "version",
                type: {
                    name: "String"
                }
            },
            functionCode: {
                serializedName: "functionCode",
                type: {
                    name: "String"
                }
            },
            functionName: {
                serializedName: "functionName",
                type: {
                    name: "String"
                }
            },
            functionPrincipalName: {
                serializedName: "functionPrincipalName",
                type: {
                    name: "String"
                }
            },
            checkListPostFix: {
                serializedName: "checkListPostFix",
                type: {
                    name: "String"
                }
            },
            orgUnitCodeName: {
                serializedName: "orgUnitCodeName",
                type: {
                    name: "String"
                }
            },
            gateDisplayName: {
                serializedName: "gateDisplayName",
                type: {
                    name: "String"
                }
            },
            isFromTemplate: {
                required: true,
                serializedName: "isFromTemplate",
                type: {
                    name: "Boolean"
                }
            },
            isEnabled: {
                required: true,
                serializedName: "isEnabled",
                type: {
                    name: "Boolean"
                }
            },
            status: {
                required: true,
                serializedName: "status",
                type: {
                    name: "String"
                }
            },
            preparedByPrincipalDisplayName: {
                serializedName: "preparedByPrincipalDisplayName",
                type: {
                    name: "String"
                }
            },
            prepared: {
                serializedName: "prepared",
                type: {
                    name: "DateTime"
                }
            },
            approvedByPrincipalDisplayName: {
                serializedName: "approvedByPrincipalDisplayName",
                type: {
                    name: "String"
                }
            },
            approved: {
                serializedName: "approved",
                type: {
                    name: "DateTime"
                }
            },
            approvers: {
                serializedName: "approvers",
                type: {
                    name: "String"
                }
            },
            overrideAllowed: {
                required: true,
                serializedName: "overrideAllowed",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalCopyChecklist: {
                required: true,
                serializedName: "canPrincipalCopyChecklist",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalRemoveChecklist: {
                required: true,
                serializedName: "canPrincipalRemoveChecklist",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalEditChecklist: {
                required: true,
                serializedName: "canPrincipalEditChecklist",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalAddManualTask: {
                required: true,
                serializedName: "canPrincipalAddManualTask",
                type: {
                    name: "Boolean"
                }
            },
            ratingCountTotal: {
                required: true,
                serializedName: "ratingCountTotal",
                type: {
                    name: "Number"
                }
            },
            funcRatingCount: {
                required: true,
                serializedName: "funcRatingCount",
                type: {
                    name: "Number"
                }
            },
            pjmRatingCount: {
                required: true,
                serializedName: "pjmRatingCount",
                type: {
                    name: "Number"
                }
            },
            gaRatingCount: {
                required: true,
                serializedName: "gaRatingCount",
                type: {
                    name: "Number"
                }
            },
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            uniqueId: {
                required: true,
                serializedName: "uniqueId",
                type: {
                    name: "String"
                }
            },
            code: {
                required: true,
                serializedName: "code",
                type: {
                    name: "String"
                }
            },
            name: {
                required: true,
                serializedName: "name",
                type: {
                    name: "String"
                }
            },
            projectCode: {
                required: true,
                serializedName: "projectCode",
                type: {
                    name: "String"
                }
            },
            projectName: {
                required: true,
                serializedName: "projectName",
                type: {
                    name: "String"
                }
            },
            isMaster: {
                required: true,
                serializedName: "isMaster",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var ApiConflictModel = {
    serializedName: "ApiConflictModel",
    type: {
        name: "Composite",
        className: "ApiConflictModel",
        modelProperties: {
            needResponseOnConflict: {
                required: true,
                serializedName: "needResponseOnConflict",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var ChecklistShortModel = {
    serializedName: "ChecklistShortModel",
    type: {
        name: "Composite",
        className: "ChecklistShortModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            displayName: {
                required: true,
                serializedName: "displayName",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var CheckListMainInfoModel = {
    serializedName: "CheckListMainInfoModel",
    type: {
        name: "Composite",
        className: "CheckListMainInfoModel",
        modelProperties: {
            canPrincipalUpdatePrjManagerPart: {
                required: true,
                serializedName: "canPrincipalUpdatePrjManagerPart",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalUpdateAssessorPart: {
                required: true,
                serializedName: "canPrincipalUpdateAssessorPart",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalSetInApprovalChecklist: {
                required: true,
                serializedName: "canPrincipalSetInApprovalChecklist",
                type: {
                    name: "Boolean"
                }
            },
            canCheckListBeApproved: {
                required: true,
                serializedName: "canCheckListBeApproved",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalSetToDraftChecklist: {
                required: true,
                serializedName: "canPrincipalSetToDraftChecklist",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalImmediateApproveChecklist: {
                required: true,
                serializedName: "canPrincipalImmediateApproveChecklist",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalCopyCommentsFromSplittedGates: {
                required: true,
                serializedName: "canPrincipalCopyCommentsFromSplittedGates",
                type: {
                    name: "Boolean"
                }
            },
            hasApprovalWarning: {
                required: true,
                serializedName: "hasApprovalWarning",
                type: {
                    name: "Boolean"
                }
            },
            approvalId: {
                serializedName: "approvalId",
                type: {
                    name: "Number"
                }
            },
            isValid: {
                required: true,
                serializedName: "isValid",
                type: {
                    name: "Boolean"
                }
            },
            status: {
                required: true,
                serializedName: "status",
                type: {
                    name: "String"
                }
            },
            relatedChecklistsFromSplittedGates: {
                serializedName: "relatedChecklistsFromSplittedGates",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ChecklistShortModel"
                        }
                    }
                }
            },
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            uniqueId: {
                required: true,
                serializedName: "uniqueId",
                type: {
                    name: "String"
                }
            },
            code: {
                required: true,
                serializedName: "code",
                type: {
                    name: "String"
                }
            },
            name: {
                required: true,
                serializedName: "name",
                type: {
                    name: "String"
                }
            },
            projectCode: {
                required: true,
                serializedName: "projectCode",
                type: {
                    name: "String"
                }
            },
            projectName: {
                required: true,
                serializedName: "projectName",
                type: {
                    name: "String"
                }
            },
            isMaster: {
                required: true,
                serializedName: "isMaster",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var FunctionModel = {
    serializedName: "FunctionModel",
    type: {
        name: "Composite",
        className: "FunctionModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            name: {
                required: true,
                serializedName: "name",
                type: {
                    name: "String"
                }
            },
            code: {
                required: true,
                serializedName: "code",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var ChecklistSmallModel = {
    serializedName: "ChecklistSmallModel",
    type: {
        name: "Composite",
        className: "ChecklistSmallModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            uniqueId: {
                serializedName: "uniqueId",
                type: {
                    name: "String"
                }
            },
            code: {
                serializedName: "code",
                type: {
                    name: "String"
                }
            },
            name: {
                serializedName: "name",
                type: {
                    name: "String"
                }
            },
            status: {
                required: true,
                serializedName: "status",
                type: {
                    name: "String"
                }
            },
            targetStatus: {
                required: true,
                serializedName: "targetStatus",
                type: {
                    name: "String"
                }
            },
            targetDeliverableDate: {
                serializedName: "targetDeliverableDate",
                type: {
                    name: "DateTime"
                }
            },
            comment: {
                serializedName: "comment",
                type: {
                    name: "String"
                }
            },
            type: {
                required: true,
                serializedName: "type",
                type: {
                    name: "String"
                }
            },
            isEnabled: {
                required: true,
                serializedName: "isEnabled",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalSetInApprovalChecklist: {
                required: true,
                serializedName: "canPrincipalSetInApprovalChecklist",
                type: {
                    name: "Boolean"
                }
            },
            canCheckListBeApproved: {
                required: true,
                serializedName: "canCheckListBeApproved",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalSetToDraftChecklist: {
                required: true,
                serializedName: "canPrincipalSetToDraftChecklist",
                type: {
                    name: "Boolean"
                }
            },
            isValid: {
                required: true,
                serializedName: "isValid",
                type: {
                    name: "Boolean"
                }
            },
            hasApprovalWarning: {
                required: true,
                serializedName: "hasApprovalWarning",
                type: {
                    name: "Boolean"
                }
            },
            functions: {
                serializedName: "functions",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "FunctionModel"
                        }
                    }
                }
            }
        }
    }
};
export var GenericLookupInfo = {
    serializedName: "GenericLookupInfo",
    type: {
        name: "Composite",
        className: "GenericLookupInfo",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            codeName: {
                serializedName: "codeName",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var CopyCheckListModel = {
    serializedName: "CopyCheckListModel",
    type: {
        name: "Composite",
        className: "CopyCheckListModel",
        modelProperties: {
            checkListId: {
                required: true,
                serializedName: "checkListId",
                type: {
                    name: "Number"
                }
            },
            postFix: {
                serializedName: "postFix",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var StatusOverview = {
    serializedName: "StatusOverview",
    type: {
        name: "Composite",
        className: "StatusOverview",
        modelProperties: {
            greenRatingCount: {
                required: true,
                serializedName: "greenRatingCount",
                type: {
                    name: "Number"
                }
            },
            yellowRatingCount: {
                required: true,
                serializedName: "yellowRatingCount",
                type: {
                    name: "Number"
                }
            },
            redRatingCount: {
                required: true,
                serializedName: "redRatingCount",
                type: {
                    name: "Number"
                }
            },
            notApplicableRatingCount: {
                required: true,
                serializedName: "notApplicableRatingCount",
                type: {
                    name: "Number"
                }
            },
            defaultRatingCount: {
                required: true,
                serializedName: "defaultRatingCount",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export var ChecklistStatusOverviewProjectManagerOverview = {
    serializedName: "ChecklistStatusOverview_projectManagerOverview",
    type: {
        name: "Composite",
        className: "ChecklistStatusOverviewProjectManagerOverview",
        modelProperties: __assign({}, StatusOverview.type.modelProperties)
    }
};
export var ChecklistStatusOverviewFunctionOverview = {
    serializedName: "ChecklistStatusOverview_functionOverview",
    type: {
        name: "Composite",
        className: "ChecklistStatusOverviewFunctionOverview",
        modelProperties: __assign({}, StatusOverview.type.modelProperties)
    }
};
export var ChecklistStatusOverviewAssessorOverview = {
    serializedName: "ChecklistStatusOverview_assessorOverview",
    type: {
        name: "Composite",
        className: "ChecklistStatusOverviewAssessorOverview",
        modelProperties: __assign({}, StatusOverview.type.modelProperties)
    }
};
export var ChecklistStatusOverview = {
    serializedName: "ChecklistStatusOverview",
    type: {
        name: "Composite",
        className: "ChecklistStatusOverview",
        modelProperties: {
            projectManagerOverview: {
                required: true,
                serializedName: "projectManagerOverview",
                type: {
                    name: "Composite",
                    className: "ChecklistStatusOverviewProjectManagerOverview"
                }
            },
            functionOverview: {
                required: true,
                serializedName: "functionOverview",
                type: {
                    name: "Composite",
                    className: "ChecklistStatusOverviewFunctionOverview"
                }
            },
            assessorOverview: {
                required: true,
                serializedName: "assessorOverview",
                type: {
                    name: "Composite",
                    className: "ChecklistStatusOverviewAssessorOverview"
                }
            }
        }
    }
};
export var FunctionWithEditAbility = {
    serializedName: "FunctionWithEditAbility",
    type: {
        name: "Composite",
        className: "FunctionWithEditAbility",
        modelProperties: {
            isResponsibleInputProvider: {
                required: true,
                serializedName: "isResponsibleInputProvider",
                type: {
                    name: "Boolean"
                }
            },
            isEnabled: {
                required: true,
                serializedName: "isEnabled",
                type: {
                    name: "Boolean"
                }
            },
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            name: {
                required: true,
                serializedName: "name",
                type: {
                    name: "String"
                }
            },
            code: {
                required: true,
                serializedName: "code",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var CheckListItemDeliverableDocumentModel = {
    serializedName: "CheckListItemDeliverableDocumentModel",
    type: {
        name: "Composite",
        className: "CheckListItemDeliverableDocumentModel",
        modelProperties: {
            fileId: {
                required: true,
                serializedName: "fileId",
                type: {
                    name: "Number"
                }
            },
            fileComment: {
                serializedName: "fileComment",
                type: {
                    name: "String"
                }
            },
            originalFileName: {
                serializedName: "originalFileName",
                type: {
                    name: "String"
                }
            },
            name: {
                serializedName: "name",
                type: {
                    name: "String"
                }
            },
            version: {
                required: true,
                serializedName: "version",
                type: {
                    name: "Number"
                }
            },
            gateDisplayName: {
                serializedName: "gateDisplayName",
                type: {
                    name: "String"
                }
            },
            status: {
                required: true,
                serializedName: "status",
                type: {
                    name: "String"
                }
            },
            uploadedDate: {
                required: true,
                serializedName: "uploadedDate",
                type: {
                    name: "DateTime"
                }
            },
            uploadedBy: {
                serializedName: "uploadedBy",
                type: {
                    name: "String"
                }
            },
            checkListItemId: {
                required: true,
                serializedName: "checkListItemId",
                type: {
                    name: "Number"
                }
            },
            hasRedDot: {
                required: true,
                serializedName: "hasRedDot",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalDeleteDocument: {
                required: true,
                serializedName: "canPrincipalDeleteDocument",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var ChecklistItemDetailsModelDocument = {
    serializedName: "ChecklistItemDetailsModel_document",
    type: {
        name: "Composite",
        className: "ChecklistItemDetailsModelDocument",
        modelProperties: __assign({}, CheckListItemDeliverableDocumentModel.type.modelProperties)
    }
};
export var ChecklistItemDetailsModel = {
    serializedName: "ChecklistItemDetailsModel",
    type: {
        name: "Composite",
        className: "ChecklistItemDetailsModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            checklistId: {
                required: true,
                serializedName: "checklistId",
                type: {
                    name: "Number"
                }
            },
            code: {
                serializedName: "code",
                type: {
                    name: "String"
                }
            },
            questionTitle: {
                serializedName: "questionTitle",
                type: {
                    name: "String"
                }
            },
            question: {
                serializedName: "question",
                type: {
                    name: "String"
                }
            },
            deliverableText: {
                serializedName: "deliverableText",
                type: {
                    name: "String"
                }
            },
            guideline: {
                serializedName: "guideline",
                type: {
                    name: "String"
                }
            },
            type: {
                required: true,
                serializedName: "type",
                type: {
                    name: "String"
                }
            },
            relatedFunctionId: {
                serializedName: "relatedFunctionId",
                type: {
                    name: "Number"
                }
            },
            functionNames: {
                serializedName: "functionNames",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "FunctionWithEditAbility"
                        }
                    }
                }
            },
            isEnabled: {
                required: true,
                serializedName: "isEnabled",
                type: {
                    name: "Boolean"
                }
            },
            actionItemsCount: {
                required: true,
                serializedName: "actionItemsCount",
                type: {
                    name: "Number"
                }
            },
            actionItemsDraftCount: {
                required: true,
                serializedName: "actionItemsDraftCount",
                type: {
                    name: "Number"
                }
            },
            actionItemsInApprovalCount: {
                required: true,
                serializedName: "actionItemsInApprovalCount",
                type: {
                    name: "Number"
                }
            },
            actionItemsApprovedCount: {
                required: true,
                serializedName: "actionItemsApprovedCount",
                type: {
                    name: "Number"
                }
            },
            actionItemsNotApplicableCount: {
                required: true,
                serializedName: "actionItemsNotApplicableCount",
                type: {
                    name: "Number"
                }
            },
            document: {
                serializedName: "document",
                type: {
                    name: "Composite",
                    className: "ChecklistItemDetailsModelDocument"
                }
            }
        }
    }
};
export var GateDeliverableModelFileInformation = {
    serializedName: "GateDeliverableModel_fileInformation",
    type: {
        name: "Composite",
        className: "GateDeliverableModelFileInformation",
        modelProperties: __assign({}, CheckListItemDeliverableDocumentModel.type.modelProperties)
    }
};
export var GateDeliverableModel = {
    serializedName: "GateDeliverableModel",
    type: {
        name: "Composite",
        className: "GateDeliverableModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            uniqueId: {
                serializedName: "uniqueId",
                type: {
                    name: "String"
                }
            },
            checkListItemId: {
                required: true,
                serializedName: "checkListItemId",
                type: {
                    name: "Number"
                }
            },
            checkListItemCode: {
                serializedName: "checkListItemCode",
                type: {
                    name: "String"
                }
            },
            checkListItemType: {
                required: true,
                serializedName: "checkListItemType",
                type: {
                    name: "String"
                }
            },
            deliverableName: {
                serializedName: "deliverableName",
                type: {
                    name: "String"
                }
            },
            currentStatus: {
                serializedName: "currentStatus",
                type: {
                    name: "String"
                }
            },
            stateAtGateAssessment: {
                serializedName: "stateAtGateAssessment",
                type: {
                    name: "String"
                }
            },
            targetDeliverableDate: {
                serializedName: "targetDeliverableDate",
                type: {
                    name: "DateTime"
                }
            },
            fileInformation: {
                serializedName: "fileInformation",
                type: {
                    name: "Composite",
                    className: "GateDeliverableModelFileInformation"
                }
            },
            commentId: {
                serializedName: "commentId",
                type: {
                    name: "Number"
                }
            },
            comment: {
                serializedName: "comment",
                type: {
                    name: "String"
                }
            },
            functionalComment: {
                serializedName: "functionalComment",
                type: {
                    name: "String"
                }
            },
            projectManagerComment: {
                serializedName: "projectManagerComment",
                type: {
                    name: "String"
                }
            },
            isEnabled: {
                required: true,
                serializedName: "isEnabled",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalSetInApprovalDeliverable: {
                required: true,
                serializedName: "canPrincipalSetInApprovalDeliverable",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalApproveDeliverable: {
                required: true,
                serializedName: "canPrincipalApproveDeliverable",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalSetToDraftDeliverable: {
                required: true,
                serializedName: "canPrincipalSetToDraftDeliverable",
                type: {
                    name: "Boolean"
                }
            },
            isApprovalDisabled: {
                required: true,
                serializedName: "isApprovalDisabled",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalImmediateApproveDeliverable: {
                required: true,
                serializedName: "canPrincipalImmediateApproveDeliverable",
                type: {
                    name: "Boolean"
                }
            },
            approvalId: {
                serializedName: "approvalId",
                type: {
                    name: "Number"
                }
            },
            rating: {
                serializedName: "rating",
                type: {
                    name: "String"
                }
            },
            validationErrors: {
                serializedName: "validationErrors",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            hasChildren: {
                required: true,
                serializedName: "hasChildren",
                type: {
                    name: "Boolean"
                }
            },
            hasRating: {
                required: true,
                serializedName: "hasRating",
                type: {
                    name: "Boolean"
                }
            },
            hasComment: {
                required: true,
                serializedName: "hasComment",
                type: {
                    name: "Boolean"
                }
            },
            hasFile: {
                required: true,
                serializedName: "hasFile",
                type: {
                    name: "Boolean"
                }
            },
            hasRedDot: {
                required: true,
                serializedName: "hasRedDot",
                type: {
                    name: "Boolean"
                }
            },
            canEdit: {
                required: true,
                serializedName: "canEdit",
                type: {
                    name: "Boolean"
                }
            },
            doNotNeedApproval: {
                required: true,
                serializedName: "doNotNeedApproval",
                type: {
                    name: "Boolean"
                }
            },
            isReadyForApprovalVisible: {
                required: true,
                serializedName: "isReadyForApprovalVisible",
                type: {
                    name: "Boolean"
                }
            },
            isApprovalActionVisible: {
                required: true,
                serializedName: "isApprovalActionVisible",
                type: {
                    name: "Boolean"
                }
            },
            isApprovalButtonVisible: {
                required: true,
                serializedName: "isApprovalButtonVisible",
                type: {
                    name: "Boolean"
                }
            },
            isNotApplicableAfterApproval: {
                required: true,
                serializedName: "isNotApplicableAfterApproval",
                type: {
                    name: "Boolean"
                }
            },
            approvalApplication: {
                required: true,
                serializedName: "approvalApplication",
                type: {
                    name: "String"
                }
            },
            isExternallyConfigured: {
                required: true,
                serializedName: "isExternallyConfigured",
                type: {
                    name: "Boolean"
                }
            },
            externalApprovalAppLink: {
                serializedName: "externalApprovalAppLink",
                type: {
                    name: "String"
                }
            },
            externalTestPlanAppLink: {
                serializedName: "externalTestPlanAppLink",
                type: {
                    name: "String"
                }
            },
            isExternalPrepared: {
                required: true,
                serializedName: "isExternalPrepared",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var CheckListItemApprovedDetailsModelDeliverable = {
    serializedName: "CheckListItemApprovedDetailsModel_deliverable",
    type: {
        name: "Composite",
        className: "CheckListItemApprovedDetailsModelDeliverable",
        modelProperties: __assign({}, GateDeliverableModel.type.modelProperties)
    }
};
export var CheckListItemApprovedDetailsModelDocument = {
    serializedName: "CheckListItemApprovedDetailsModel_document",
    type: {
        name: "Composite",
        className: "CheckListItemApprovedDetailsModelDocument",
        modelProperties: __assign({}, CheckListItemDeliverableDocumentModel.type.modelProperties)
    }
};
export var CheckListItemApprovedDetailsModel = {
    serializedName: "CheckListItemApprovedDetailsModel",
    type: {
        name: "Composite",
        className: "CheckListItemApprovedDetailsModel",
        modelProperties: {
            comment: {
                serializedName: "comment",
                type: {
                    name: "String"
                }
            },
            rating: {
                required: true,
                serializedName: "rating",
                type: {
                    name: "String"
                }
            },
            commentIsPublished: {
                required: true,
                serializedName: "commentIsPublished",
                type: {
                    name: "Boolean"
                }
            },
            projectManagerComment: {
                serializedName: "projectManagerComment",
                type: {
                    name: "String"
                }
            },
            projectManagerNeedAttention: {
                required: true,
                serializedName: "projectManagerNeedAttention",
                type: {
                    name: "Boolean"
                }
            },
            projectManagerCommentIsPublished: {
                required: true,
                serializedName: "projectManagerCommentIsPublished",
                type: {
                    name: "Boolean"
                }
            },
            projectManagerRating: {
                required: true,
                serializedName: "projectManagerRating",
                type: {
                    name: "String"
                }
            },
            assessorCommentIsPublished: {
                required: true,
                serializedName: "assessorCommentIsPublished",
                type: {
                    name: "Boolean"
                }
            },
            needToCreateActionItem: {
                required: true,
                serializedName: "needToCreateActionItem",
                type: {
                    name: "Boolean"
                }
            },
            needAttention: {
                required: true,
                serializedName: "needAttention",
                type: {
                    name: "Boolean"
                }
            },
            assessorComment: {
                serializedName: "assessorComment",
                type: {
                    name: "String"
                }
            },
            assessorRating: {
                required: true,
                serializedName: "assessorRating",
                type: {
                    name: "String"
                }
            },
            taskId: {
                serializedName: "taskId",
                type: {
                    name: "Number"
                }
            },
            taskStatus: {
                serializedName: "taskStatus",
                type: {
                    name: "String"
                }
            },
            hasActionItem: {
                required: true,
                serializedName: "hasActionItem",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalUpdateFunctionalPart: {
                required: true,
                serializedName: "canPrincipalUpdateFunctionalPart",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalDelegateTask: {
                required: true,
                serializedName: "canPrincipalDelegateTask",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalUpdateAssessorPart: {
                required: true,
                serializedName: "canPrincipalUpdateAssessorPart",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalUpdatePrjManagerPart: {
                required: true,
                serializedName: "canPrincipalUpdatePrjManagerPart",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalSetToInApproveFunctionalDeliverable: {
                required: true,
                serializedName: "canPrincipalSetToInApproveFunctionalDeliverable",
                type: {
                    name: "Boolean"
                }
            },
            canAddActionItem: {
                required: true,
                serializedName: "canAddActionItem",
                type: {
                    name: "Boolean"
                }
            },
            deliverable: {
                serializedName: "deliverable",
                type: {
                    name: "Composite",
                    className: "CheckListItemApprovedDetailsModelDeliverable"
                }
            },
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            checklistId: {
                required: true,
                serializedName: "checklistId",
                type: {
                    name: "Number"
                }
            },
            code: {
                serializedName: "code",
                type: {
                    name: "String"
                }
            },
            questionTitle: {
                serializedName: "questionTitle",
                type: {
                    name: "String"
                }
            },
            question: {
                serializedName: "question",
                type: {
                    name: "String"
                }
            },
            deliverableText: {
                serializedName: "deliverableText",
                type: {
                    name: "String"
                }
            },
            guideline: {
                serializedName: "guideline",
                type: {
                    name: "String"
                }
            },
            type: {
                required: true,
                serializedName: "type",
                type: {
                    name: "String"
                }
            },
            relatedFunctionId: {
                serializedName: "relatedFunctionId",
                type: {
                    name: "Number"
                }
            },
            functionNames: {
                serializedName: "functionNames",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "FunctionWithEditAbility"
                        }
                    }
                }
            },
            isEnabled: {
                required: true,
                serializedName: "isEnabled",
                type: {
                    name: "Boolean"
                }
            },
            actionItemsCount: {
                required: true,
                serializedName: "actionItemsCount",
                type: {
                    name: "Number"
                }
            },
            actionItemsDraftCount: {
                required: true,
                serializedName: "actionItemsDraftCount",
                type: {
                    name: "Number"
                }
            },
            actionItemsInApprovalCount: {
                required: true,
                serializedName: "actionItemsInApprovalCount",
                type: {
                    name: "Number"
                }
            },
            actionItemsApprovedCount: {
                required: true,
                serializedName: "actionItemsApprovedCount",
                type: {
                    name: "Number"
                }
            },
            actionItemsNotApplicableCount: {
                required: true,
                serializedName: "actionItemsNotApplicableCount",
                type: {
                    name: "Number"
                }
            },
            document: {
                serializedName: "document",
                type: {
                    name: "Composite",
                    className: "CheckListItemApprovedDetailsModelDocument"
                }
            }
        }
    }
};
export var DeliverableDocumentModel = {
    serializedName: "DeliverableDocumentModel",
    type: {
        name: "Composite",
        className: "DeliverableDocumentModel",
        modelProperties: {
            documents: {
                serializedName: "documents",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemDeliverableDocumentModel"
                        }
                    }
                }
            },
            functionDocuments: {
                serializedName: "functionDocuments",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemDeliverableDocumentModel"
                        }
                    }
                }
            },
            canPrincipalUploadDocument: {
                required: true,
                serializedName: "canPrincipalUploadDocument",
                type: {
                    name: "Boolean"
                }
            },
            canUploadDocument: {
                required: true,
                serializedName: "canUploadDocument",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalAllowWithoutDocument: {
                required: true,
                serializedName: "canPrincipalAllowWithoutDocument",
                type: {
                    name: "Boolean"
                }
            },
            responsibleOrgUnitId: {
                required: true,
                serializedName: "responsibleOrgUnitId",
                type: {
                    name: "Number"
                }
            },
            canPrincipalAddLink: {
                required: true,
                serializedName: "canPrincipalAddLink",
                type: {
                    name: "Boolean"
                }
            },
            projectDisplayName: {
                serializedName: "projectDisplayName",
                type: {
                    name: "String"
                }
            },
            gateDisplayName: {
                serializedName: "gateDisplayName",
                type: {
                    name: "String"
                }
            },
            deliverableDisplayName: {
                serializedName: "deliverableDisplayName",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var UpdateChecklistItemDeliverableFileCommentModel = {
    serializedName: "UpdateChecklistItemDeliverableFileCommentModel",
    type: {
        name: "Composite",
        className: "UpdateChecklistItemDeliverableFileCommentModel",
        modelProperties: {
            checkListItemId: {
                required: true,
                serializedName: "checkListItemId",
                type: {
                    name: "Number"
                }
            },
            fileInformationId: {
                required: true,
                serializedName: "fileInformationId",
                type: {
                    name: "Number"
                }
            },
            comment: {
                serializedName: "comment",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var GateDeliverableShortModel = {
    serializedName: "GateDeliverableShortModel",
    type: {
        name: "Composite",
        className: "GateDeliverableShortModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            uniqueId: {
                serializedName: "uniqueId",
                type: {
                    name: "String"
                }
            },
            checkListItemId: {
                required: true,
                serializedName: "checkListItemId",
                type: {
                    name: "Number"
                }
            },
            checkListItemCode: {
                serializedName: "checkListItemCode",
                type: {
                    name: "String"
                }
            },
            deliverableName: {
                serializedName: "deliverableName",
                type: {
                    name: "String"
                }
            },
            approvalApplication: {
                required: true,
                serializedName: "approvalApplication",
                type: {
                    name: "String"
                }
            },
            isExternallyConfigured: {
                required: true,
                serializedName: "isExternallyConfigured",
                type: {
                    name: "Boolean"
                }
            },
            deliverableStatus: {
                required: true,
                serializedName: "deliverableStatus",
                type: {
                    name: "String"
                }
            },
            checklistStatus: {
                required: true,
                serializedName: "checklistStatus",
                type: {
                    name: "String"
                }
            },
            hasData: {
                required: true,
                serializedName: "hasData",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var ChecklistItemDeliverableRestApiModel = {
    serializedName: "ChecklistItemDeliverableRestApiModel",
    type: {
        name: "Composite",
        className: "ChecklistItemDeliverableRestApiModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            uniqueId: {
                serializedName: "uniqueId",
                type: {
                    name: "String"
                }
            },
            isCopiedFromPreviousGate: {
                required: true,
                serializedName: "isCopiedFromPreviousGate",
                type: {
                    name: "Boolean"
                }
            },
            stateAtGateCreation: {
                required: true,
                serializedName: "stateAtGateCreation",
                type: {
                    name: "String"
                }
            },
            stateAtGateAssessment: {
                required: true,
                serializedName: "stateAtGateAssessment",
                type: {
                    name: "String"
                }
            },
            currentStatus: {
                required: true,
                serializedName: "currentStatus",
                type: {
                    name: "String"
                }
            },
            fileInformationId: {
                serializedName: "fileInformationId",
                type: {
                    name: "Number"
                }
            },
            fileInformationFileName: {
                serializedName: "fileInformationFileName",
                type: {
                    name: "String"
                }
            },
            isEnabled: {
                required: true,
                serializedName: "isEnabled",
                type: {
                    name: "Boolean"
                }
            },
            isDeleted: {
                required: true,
                serializedName: "isDeleted",
                type: {
                    name: "Boolean"
                }
            },
            documentDefinitionId: {
                required: true,
                serializedName: "documentDefinitionId",
                type: {
                    name: "Number"
                }
            },
            documentDefinitionName: {
                serializedName: "documentDefinitionName",
                type: {
                    name: "String"
                }
            },
            documentName: {
                serializedName: "documentName",
                type: {
                    name: "String"
                }
            },
            isExternalConfigured: {
                required: true,
                serializedName: "isExternalConfigured",
                type: {
                    name: "Boolean"
                }
            },
            externalApprovalAppLink: {
                serializedName: "externalApprovalAppLink",
                type: {
                    name: "String"
                }
            },
            approvalApplication: {
                required: true,
                serializedName: "approvalApplication",
                type: {
                    name: "String"
                }
            },
            externalTestPlanAppLink: {
                serializedName: "externalTestPlanAppLink",
                type: {
                    name: "String"
                }
            },
            approvalMaxDateTo: {
                serializedName: "approvalMaxDateTo",
                type: {
                    name: "DateTime"
                }
            },
            approvedApprovalMaxDate: {
                serializedName: "approvedApprovalMaxDate",
                type: {
                    name: "DateTime"
                }
            }
        }
    }
};
export var ChecklistItemRestApiModelDeliverable = {
    serializedName: "ChecklistItemRestApiModel_deliverable",
    type: {
        name: "Composite",
        className: "ChecklistItemRestApiModelDeliverable",
        modelProperties: __assign({}, ChecklistItemDeliverableRestApiModel.type.modelProperties)
    }
};
export var ChecklistItemRestApiModel = {
    serializedName: "ChecklistItemRestApiModel",
    type: {
        name: "Composite",
        className: "ChecklistItemRestApiModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            uniqueId: {
                serializedName: "uniqueId",
                type: {
                    name: "String"
                }
            },
            projectCodeName: {
                serializedName: "projectCodeName",
                type: {
                    name: "String"
                }
            },
            gateId: {
                required: true,
                serializedName: "gateId",
                type: {
                    name: "Number"
                }
            },
            checklistId: {
                required: true,
                serializedName: "checklistId",
                type: {
                    name: "Number"
                }
            },
            checklistFullCode: {
                serializedName: "checklistFullCode",
                type: {
                    name: "String"
                }
            },
            code: {
                serializedName: "code",
                type: {
                    name: "String"
                }
            },
            guideline: {
                serializedName: "guideline",
                type: {
                    name: "String"
                }
            },
            comment: {
                serializedName: "comment",
                type: {
                    name: "String"
                }
            },
            questionTitle: {
                serializedName: "questionTitle",
                type: {
                    name: "String"
                }
            },
            question: {
                serializedName: "question",
                type: {
                    name: "String"
                }
            },
            answer: {
                serializedName: "answer",
                type: {
                    name: "String"
                }
            },
            version: {
                serializedName: "version",
                type: {
                    name: "String"
                }
            },
            rating: {
                required: true,
                serializedName: "rating",
                type: {
                    name: "String"
                }
            },
            checklistItemType: {
                required: true,
                serializedName: "checklistItemType",
                type: {
                    name: "String"
                }
            },
            isEnabled: {
                required: true,
                serializedName: "isEnabled",
                type: {
                    name: "Boolean"
                }
            },
            isDeleted: {
                required: true,
                serializedName: "isDeleted",
                type: {
                    name: "Boolean"
                }
            },
            deliverableText: {
                serializedName: "deliverableText",
                type: {
                    name: "String"
                }
            },
            assessorComment: {
                serializedName: "assessorComment",
                type: {
                    name: "String"
                }
            },
            assessorRating: {
                required: true,
                serializedName: "assessorRating",
                type: {
                    name: "String"
                }
            },
            projectManagerComment: {
                serializedName: "projectManagerComment",
                type: {
                    name: "String"
                }
            },
            projectManagerRating: {
                required: true,
                serializedName: "projectManagerRating",
                type: {
                    name: "String"
                }
            },
            masterCheckListItemId: {
                serializedName: "masterCheckListItemId",
                type: {
                    name: "Number"
                }
            },
            masterCheckListItemCode: {
                serializedName: "masterCheckListItemCode",
                type: {
                    name: "String"
                }
            },
            commentIsPublished: {
                required: true,
                serializedName: "commentIsPublished",
                type: {
                    name: "Boolean"
                }
            },
            assessorCommentIsPublished: {
                required: true,
                serializedName: "assessorCommentIsPublished",
                type: {
                    name: "Boolean"
                }
            },
            projectManagerCommentIsPublished: {
                required: true,
                serializedName: "projectManagerCommentIsPublished",
                type: {
                    name: "Boolean"
                }
            },
            needAttention: {
                required: true,
                serializedName: "needAttention",
                type: {
                    name: "Boolean"
                }
            },
            needToCreateActionItem: {
                required: true,
                serializedName: "needToCreateActionItem",
                type: {
                    name: "Boolean"
                }
            },
            gateAssessorMeetingComment: {
                serializedName: "gateAssessorMeetingComment",
                type: {
                    name: "String"
                }
            },
            gateAssessorMeetingRating: {
                required: true,
                serializedName: "gateAssessorMeetingRating",
                type: {
                    name: "String"
                }
            },
            projectManagerNeedAttention: {
                required: true,
                serializedName: "projectManagerNeedAttention",
                type: {
                    name: "Boolean"
                }
            },
            externalIdentifier: {
                serializedName: "externalIdentifier",
                type: {
                    name: "String"
                }
            },
            commentModified: {
                serializedName: "commentModified",
                type: {
                    name: "DateTime"
                }
            },
            hasActionItem: {
                required: true,
                serializedName: "hasActionItem",
                type: {
                    name: "Boolean"
                }
            },
            url: {
                serializedName: "url",
                type: {
                    name: "String"
                }
            },
            deliverable: {
                serializedName: "deliverable",
                type: {
                    name: "Composite",
                    className: "ChecklistItemRestApiModelDeliverable"
                }
            }
        }
    }
};
export var DeliverableGateStatusConfigurationModel = {
    serializedName: "DeliverableGateStatusConfigurationModel",
    type: {
        name: "Composite",
        className: "DeliverableGateStatusConfigurationModel",
        modelProperties: {
            gateId: {
                required: true,
                serializedName: "gateId",
                type: {
                    name: "Number"
                }
            },
            gateIndexNumber: {
                required: true,
                serializedName: "gateIndexNumber",
                type: {
                    name: "Number"
                }
            },
            gateTemplateDisplayName: {
                required: true,
                serializedName: "gateTemplateDisplayName",
                type: {
                    name: "String"
                }
            },
            checkListItemTemplateDeliverableId: {
                required: true,
                serializedName: "checkListItemTemplateDeliverableId",
                type: {
                    name: "Number"
                }
            },
            code: {
                required: true,
                serializedName: "code",
                type: {
                    name: "String"
                }
            },
            stateAtGateCreation: {
                required: true,
                serializedName: "stateAtGateCreation",
                type: {
                    name: "String"
                }
            },
            stateAtGateAssessment: {
                required: true,
                serializedName: "stateAtGateAssessment",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var TemplateDeliverableStatusConfigurationModel = {
    serializedName: "TemplateDeliverableStatusConfigurationModel",
    type: {
        name: "Composite",
        className: "TemplateDeliverableStatusConfigurationModel",
        modelProperties: {
            documentIdentifier: {
                required: true,
                serializedName: "documentIdentifier",
                type: {
                    name: "String"
                }
            },
            functionCode: {
                serializedName: "functionCode",
                type: {
                    name: "String"
                }
            },
            deliverableName: {
                serializedName: "deliverableName",
                type: {
                    name: "String"
                }
            },
            statusConfigurations: {
                required: true,
                serializedName: "statusConfigurations",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "DeliverableGateStatusConfigurationModel"
                        }
                    }
                }
            }
        }
    }
};
export var TemplateFunctionRangeModel = {
    serializedName: "TemplateFunctionRangeModel",
    type: {
        name: "Composite",
        className: "TemplateFunctionRangeModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            templateId: {
                required: true,
                serializedName: "templateId",
                type: {
                    name: "Number"
                }
            },
            functionId: {
                required: true,
                serializedName: "functionId",
                type: {
                    name: "Number"
                }
            },
            functionCode: {
                required: true,
                serializedName: "functionCode",
                type: {
                    name: "String"
                }
            },
            weekFrom: {
                required: true,
                serializedName: "weekFrom",
                type: {
                    name: "Number"
                }
            },
            weekTo: {
                required: true,
                serializedName: "weekTo",
                type: {
                    name: "Number"
                }
            },
            approvalType: {
                required: true,
                serializedName: "approvalType",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var DeliverableTemplateApprovalConfigurationModel = {
    serializedName: "DeliverableTemplateApprovalConfigurationModel",
    type: {
        name: "Composite",
        className: "DeliverableTemplateApprovalConfigurationModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            code: {
                required: true,
                serializedName: "code",
                type: {
                    name: "String"
                }
            },
            gateTemplateNumber: {
                required: true,
                serializedName: "gateTemplateNumber",
                type: {
                    name: "Number"
                }
            },
            gateTemplateDisplayName: {
                required: true,
                serializedName: "gateTemplateDisplayName",
                type: {
                    name: "String"
                }
            },
            functionCode: {
                serializedName: "functionCode",
                type: {
                    name: "String"
                }
            },
            deliverableName: {
                serializedName: "deliverableName",
                type: {
                    name: "String"
                }
            },
            deliverableTemplateFunctionsRange: {
                required: true,
                serializedName: "deliverableTemplateFunctionsRange",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "TemplateFunctionRangeModel"
                        }
                    }
                }
            },
            checklistType: {
                required: true,
                serializedName: "checklistType",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var TemplateFunctionRangeCreateModel = {
    serializedName: "TemplateFunctionRangeCreateModel",
    type: {
        name: "Composite",
        className: "TemplateFunctionRangeCreateModel",
        modelProperties: {
            templateId: {
                required: true,
                serializedName: "templateId",
                type: {
                    name: "Number"
                }
            },
            functionId: {
                required: true,
                serializedName: "functionId",
                type: {
                    name: "Number"
                }
            },
            weekFrom: {
                required: true,
                serializedName: "weekFrom",
                type: {
                    name: "Number"
                }
            },
            weekTo: {
                required: true,
                serializedName: "weekTo",
                type: {
                    name: "Number"
                }
            },
            approvalType: {
                required: true,
                serializedName: "approvalType",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var TemplateFunctionRangeUpdateModel = {
    serializedName: "TemplateFunctionRangeUpdateModel",
    type: {
        name: "Composite",
        className: "TemplateFunctionRangeUpdateModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            weekFrom: {
                required: true,
                serializedName: "weekFrom",
                type: {
                    name: "Number"
                }
            },
            weekTo: {
                required: true,
                serializedName: "weekTo",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export var DeliverableDocumentConfigurationModel = {
    serializedName: "DeliverableDocumentConfigurationModel",
    type: {
        name: "Composite",
        className: "DeliverableDocumentConfigurationModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            name: {
                serializedName: "name",
                type: {
                    name: "String"
                }
            },
            functionCode: {
                serializedName: "functionCode",
                type: {
                    name: "String"
                }
            },
            uniqueDocumentId: {
                serializedName: "uniqueDocumentId",
                type: {
                    name: "String"
                }
            },
            deliverableCodes: {
                serializedName: "deliverableCodes",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var ChecklistRestApiModel = {
    serializedName: "ChecklistRestApiModel",
    type: {
        name: "Composite",
        className: "ChecklistRestApiModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            uniqueId: {
                serializedName: "uniqueId",
                type: {
                    name: "String"
                }
            },
            projectId: {
                required: true,
                serializedName: "projectId",
                type: {
                    name: "Number"
                }
            },
            projectCodeName: {
                serializedName: "projectCodeName",
                type: {
                    name: "String"
                }
            },
            projectDisplayName: {
                serializedName: "projectDisplayName",
                type: {
                    name: "String"
                }
            },
            code: {
                serializedName: "code",
                type: {
                    name: "String"
                }
            },
            name: {
                serializedName: "name",
                type: {
                    name: "String"
                }
            },
            version: {
                serializedName: "version",
                type: {
                    name: "String"
                }
            },
            ratingScore: {
                required: true,
                serializedName: "ratingScore",
                type: {
                    name: "Number"
                }
            },
            status: {
                required: true,
                serializedName: "status",
                type: {
                    name: "String"
                }
            },
            relatedFunctionId: {
                serializedName: "relatedFunctionId",
                type: {
                    name: "Number"
                }
            },
            isEnabled: {
                required: true,
                serializedName: "isEnabled",
                type: {
                    name: "Boolean"
                }
            },
            isDeleted: {
                required: true,
                serializedName: "isDeleted",
                type: {
                    name: "Boolean"
                }
            },
            checklistTemplateId: {
                serializedName: "checklistTemplateId",
                type: {
                    name: "Number"
                }
            },
            checklistTemplateName: {
                serializedName: "checklistTemplateName",
                type: {
                    name: "String"
                }
            },
            gateId: {
                required: true,
                serializedName: "gateId",
                type: {
                    name: "Number"
                }
            },
            gateDisplayName: {
                serializedName: "gateDisplayName",
                type: {
                    name: "String"
                }
            },
            functionId: {
                serializedName: "functionId",
                type: {
                    name: "Number"
                }
            },
            functionName: {
                serializedName: "functionName",
                type: {
                    name: "String"
                }
            },
            gateContainerNumber: {
                required: true,
                serializedName: "gateContainerNumber",
                type: {
                    name: "Number"
                }
            },
            preparedByPrincipalId: {
                serializedName: "preparedByPrincipalId",
                type: {
                    name: "Number"
                }
            },
            preparedByPrincipalDisplayName: {
                serializedName: "preparedByPrincipalDisplayName",
                type: {
                    name: "String"
                }
            },
            prepared: {
                serializedName: "prepared",
                type: {
                    name: "DateTime"
                }
            },
            approvedByPrincipalId: {
                serializedName: "approvedByPrincipalId",
                type: {
                    name: "Number"
                }
            },
            approvedByPrincipalDisplayName: {
                serializedName: "approvedByPrincipalDisplayName",
                type: {
                    name: "String"
                }
            },
            approved: {
                serializedName: "approved",
                type: {
                    name: "DateTime"
                }
            },
            masterCheckListId: {
                serializedName: "masterCheckListId",
                type: {
                    name: "Number"
                }
            },
            masterCheckListName: {
                serializedName: "masterCheckListName",
                type: {
                    name: "String"
                }
            },
            checklistType: {
                required: true,
                serializedName: "checklistType",
                type: {
                    name: "String"
                }
            },
            modified: {
                required: true,
                serializedName: "modified",
                type: {
                    name: "DateTime"
                }
            },
            modifiedByPrincipalId: {
                required: true,
                serializedName: "modifiedByPrincipalId",
                type: {
                    name: "Number"
                }
            },
            modifiedByPrincipalDisplayName: {
                serializedName: "modifiedByPrincipalDisplayName",
                type: {
                    name: "String"
                }
            },
            parentChecklistId: {
                serializedName: "parentChecklistId",
                type: {
                    name: "Number"
                }
            },
            postFix: {
                serializedName: "postFix",
                type: {
                    name: "String"
                }
            },
            fullCode: {
                serializedName: "fullCode",
                type: {
                    name: "String"
                }
            },
            fullName: {
                serializedName: "fullName",
                type: {
                    name: "String"
                }
            },
            approvalMaxDateTo: {
                serializedName: "approvalMaxDateTo",
                type: {
                    name: "DateTime"
                }
            },
            approvedApprovalMaxDate: {
                serializedName: "approvedApprovalMaxDate",
                type: {
                    name: "DateTime"
                }
            },
            url: {
                serializedName: "url",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var ChecklistShortInfoRestApiModel = {
    serializedName: "ChecklistShortInfoRestApiModel",
    type: {
        name: "Composite",
        className: "ChecklistShortInfoRestApiModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            code: {
                required: true,
                serializedName: "code",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var ChecklistTemplateExcelImportResult = {
    serializedName: "ChecklistTemplateExcelImportResult",
    type: {
        name: "Composite",
        className: "ChecklistTemplateExcelImportResult",
        modelProperties: {
            errorMessages: {
                serializedName: "errorMessages",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            fileName: {
                serializedName: "fileName",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var ChecklistTemplateApprovalConfigurationModel = {
    serializedName: "ChecklistTemplateApprovalConfigurationModel",
    type: {
        name: "Composite",
        className: "ChecklistTemplateApprovalConfigurationModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            code: {
                required: true,
                serializedName: "code",
                type: {
                    name: "String"
                }
            },
            gateTemplateNumber: {
                required: true,
                serializedName: "gateTemplateNumber",
                type: {
                    name: "Number"
                }
            },
            gateTemplateDisplayName: {
                required: true,
                serializedName: "gateTemplateDisplayName",
                type: {
                    name: "String"
                }
            },
            functionCode: {
                serializedName: "functionCode",
                type: {
                    name: "String"
                }
            },
            checklistTemplateFunctionsRange: {
                required: true,
                serializedName: "checklistTemplateFunctionsRange",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "TemplateFunctionRangeModel"
                        }
                    }
                }
            },
            checklistType: {
                required: true,
                serializedName: "checklistType",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var AzureAdConfigurationModel = {
    serializedName: "AzureAdConfigurationModel",
    type: {
        name: "Composite",
        className: "AzureAdConfigurationModel",
        modelProperties: {
            clientId: {
                required: true,
                serializedName: "clientId",
                type: {
                    name: "String"
                }
            },
            instance: {
                required: true,
                serializedName: "instance",
                type: {
                    name: "String"
                }
            },
            postLoginRedirectUri: {
                required: true,
                serializedName: "postLoginRedirectUri",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var ConfigurationModelAzureAd = {
    serializedName: "ConfigurationModel_azureAd",
    type: {
        name: "Composite",
        className: "ConfigurationModelAzureAd",
        modelProperties: __assign({}, AzureAdConfigurationModel.type.modelProperties)
    }
};
export var ConfigurationModel = {
    serializedName: "ConfigurationModel",
    type: {
        name: "Composite",
        className: "ConfigurationModel",
        modelProperties: {
            azureAd: {
                required: true,
                serializedName: "azureAd",
                type: {
                    name: "Composite",
                    className: "ConfigurationModelAzureAd"
                }
            },
            environmentName: {
                serializedName: "environmentName",
                type: {
                    name: "String"
                }
            },
            maximumDocumentUploadSize: {
                required: true,
                serializedName: "maximumDocumentUploadSize",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export var PrincipalConfigurationModel = {
    serializedName: "PrincipalConfigurationModel",
    type: {
        name: "Composite",
        className: "PrincipalConfigurationModel",
        modelProperties: {
            key: {
                required: true,
                serializedName: "key",
                type: {
                    name: "String"
                }
            },
            group: {
                required: true,
                serializedName: "group",
                type: {
                    name: "String"
                }
            },
            value: {
                serializedName: "value",
                type: {
                    name: "String"
                }
            },
            projectId: {
                serializedName: "projectId",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export var DbConfigurationModel = {
    serializedName: "DbConfigurationModel",
    type: {
        name: "Composite",
        className: "DbConfigurationModel",
        modelProperties: {
            key: {
                serializedName: "key",
                type: {
                    name: "String"
                }
            },
            value: {
                serializedName: "value",
                type: {
                    name: "String"
                }
            },
            description: {
                serializedName: "description",
                type: {
                    name: "String"
                }
            },
            group: {
                serializedName: "group",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var OrgUnitConfigurationModel = {
    serializedName: "OrgUnitConfigurationModel",
    type: {
        name: "Composite",
        className: "OrgUnitConfigurationModel",
        modelProperties: {
            orgUnitId: {
                required: true,
                serializedName: "orgUnitId",
                type: {
                    name: "Number"
                }
            },
            orgUnitDisplayName: {
                serializedName: "orgUnitDisplayName",
                type: {
                    name: "String"
                }
            },
            key: {
                serializedName: "key",
                type: {
                    name: "String"
                }
            },
            value: {
                serializedName: "value",
                type: {
                    name: "String"
                }
            },
            description: {
                serializedName: "description",
                type: {
                    name: "String"
                }
            },
            group: {
                serializedName: "group",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var ColorsModel = {
    serializedName: "ColorsModel",
    type: {
        name: "Composite",
        className: "ColorsModel",
        modelProperties: {
            red: {
                serializedName: "red",
                type: {
                    name: "String"
                }
            },
            green: {
                serializedName: "green",
                type: {
                    name: "String"
                }
            },
            orange: {
                serializedName: "orange",
                type: {
                    name: "String"
                }
            },
            yellow: {
                serializedName: "yellow",
                type: {
                    name: "String"
                }
            },
            gray: {
                serializedName: "gray",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var ColorBlindSchemeModelColors = {
    serializedName: "ColorBlindSchemeModel_colors",
    type: {
        name: "Composite",
        className: "ColorBlindSchemeModelColors",
        modelProperties: __assign({}, ColorsModel.type.modelProperties)
    }
};
export var ColorBlindSchemeModel = {
    serializedName: "ColorBlindSchemeModel",
    type: {
        name: "Composite",
        className: "ColorBlindSchemeModel",
        modelProperties: {
            isEnabled: {
                serializedName: "isEnabled",
                type: {
                    name: "Boolean"
                }
            },
            colors: {
                serializedName: "colors",
                type: {
                    name: "Composite",
                    className: "ColorBlindSchemeModelColors"
                }
            }
        }
    }
};
export var AssignmentModelProject = {
    serializedName: "AssignmentModel_project",
    type: {
        name: "Composite",
        className: "AssignmentModelProject",
        modelProperties: __assign({}, LookupStringValueModel.type.modelProperties)
    }
};
export var AssignmentModel = {
    serializedName: "AssignmentModel",
    type: {
        name: "Composite",
        className: "AssignmentModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            description: {
                serializedName: "description",
                type: {
                    name: "String"
                }
            },
            dueDate: {
                required: true,
                serializedName: "dueDate",
                type: {
                    name: "DateTime"
                }
            },
            createdDate: {
                required: true,
                serializedName: "createdDate",
                type: {
                    name: "DateTime"
                }
            },
            project: {
                serializedName: "project",
                type: {
                    name: "Composite",
                    className: "AssignmentModelProject"
                }
            },
            messageType: {
                required: true,
                serializedName: "messageType",
                type: {
                    name: "String"
                }
            },
            objectId: {
                required: true,
                serializedName: "objectId",
                type: {
                    name: "String"
                }
            },
            objectHierarchy: {
                serializedName: "objectHierarchy",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var NotificationModelProject = {
    serializedName: "NotificationModel_project",
    type: {
        name: "Composite",
        className: "NotificationModelProject",
        modelProperties: __assign({}, LookupStringValueModel.type.modelProperties)
    }
};
export var NotificationModel = {
    serializedName: "NotificationModel",
    type: {
        name: "Composite",
        className: "NotificationModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            description: {
                required: true,
                serializedName: "description",
                type: {
                    name: "String"
                }
            },
            createdDate: {
                required: true,
                serializedName: "createdDate",
                type: {
                    name: "DateTime"
                }
            },
            messageType: {
                required: true,
                serializedName: "messageType",
                type: {
                    name: "String"
                }
            },
            objectId: {
                required: true,
                serializedName: "objectId",
                type: {
                    name: "String"
                }
            },
            objectHierarchy: {
                serializedName: "objectHierarchy",
                type: {
                    name: "String"
                }
            },
            project: {
                serializedName: "project",
                type: {
                    name: "Composite",
                    className: "NotificationModelProject"
                }
            }
        }
    }
};
export var GateLinkModel = {
    serializedName: "GateLinkModel",
    type: {
        name: "Composite",
        className: "GateLinkModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            displayName: {
                required: true,
                serializedName: "displayName",
                type: {
                    name: "String"
                }
            },
            setupStatus: {
                required: true,
                serializedName: "setupStatus",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var MyProjectModel = {
    serializedName: "MyProjectModel",
    type: {
        name: "Composite",
        className: "MyProjectModel",
        modelProperties: {
            projectId: {
                required: true,
                serializedName: "projectId",
                type: {
                    name: "Number"
                }
            },
            projectFullDisplayName: {
                required: true,
                serializedName: "projectFullDisplayName",
                type: {
                    name: "String"
                }
            },
            projectDisplayName: {
                required: true,
                serializedName: "projectDisplayName",
                type: {
                    name: "String"
                }
            },
            projectCode: {
                required: true,
                serializedName: "projectCode",
                type: {
                    name: "String"
                }
            },
            projectManagementDisplayNames: {
                serializedName: "projectManagementDisplayNames",
                type: {
                    name: "String"
                }
            },
            gateAssessorDisplayNames: {
                serializedName: "gateAssessorDisplayNames",
                type: {
                    name: "String"
                }
            },
            gates: {
                required: true,
                serializedName: "gates",
                type: {
                    name: "String"
                }
            },
            gateLinks: {
                required: true,
                serializedName: "gateLinks",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GateLinkModel"
                        }
                    }
                }
            },
            functionCodes: {
                required: true,
                serializedName: "functionCodes",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var ChecklistFunctionPrincipalModel = {
    serializedName: "ChecklistFunctionPrincipalModel",
    type: {
        name: "Composite",
        className: "ChecklistFunctionPrincipalModel",
        modelProperties: {
            projectId: {
                required: true,
                serializedName: "projectId",
                type: {
                    name: "Number"
                }
            },
            projectFullDisplayName: {
                required: true,
                serializedName: "projectFullDisplayName",
                type: {
                    name: "String"
                }
            },
            projectDisplayName: {
                required: true,
                serializedName: "projectDisplayName",
                type: {
                    name: "String"
                }
            },
            projectCode: {
                required: true,
                serializedName: "projectCode",
                type: {
                    name: "String"
                }
            },
            projectManagementDisplayName: {
                serializedName: "projectManagementDisplayName",
                type: {
                    name: "String"
                }
            },
            gateAssessorDisplayName: {
                serializedName: "gateAssessorDisplayName",
                type: {
                    name: "String"
                }
            },
            gateDisplayName: {
                required: true,
                serializedName: "gateDisplayName",
                type: {
                    name: "String"
                }
            },
            gateStatus: {
                required: true,
                serializedName: "gateStatus",
                type: {
                    name: "String"
                }
            },
            checklistCode: {
                required: true,
                serializedName: "checklistCode",
                type: {
                    name: "String"
                }
            },
            checklistStatus: {
                required: true,
                serializedName: "checklistStatus",
                type: {
                    name: "String"
                }
            },
            functionCode: {
                required: true,
                serializedName: "functionCode",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var DeliverableShortInfoRestApiModel = {
    serializedName: "DeliverableShortInfoRestApiModel",
    type: {
        name: "Composite",
        className: "DeliverableShortInfoRestApiModel",
        modelProperties: {
            checklistItemId: {
                required: true,
                serializedName: "checklistItemId",
                type: {
                    name: "Number"
                }
            },
            checklistItemCode: {
                required: true,
                serializedName: "checklistItemCode",
                type: {
                    name: "String"
                }
            },
            deliverableId: {
                required: true,
                serializedName: "deliverableId",
                type: {
                    name: "Number"
                }
            },
            deliverableDocumentName: {
                required: true,
                serializedName: "deliverableDocumentName",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var DeliverableRelatedPrincipalsModel = {
    serializedName: "DeliverableRelatedPrincipalsModel",
    type: {
        name: "Composite",
        className: "DeliverableRelatedPrincipalsModel",
        modelProperties: {
            gtmPrincipalObjectId: {
                serializedName: "gtmPrincipalObjectId",
                type: {
                    name: "String"
                }
            },
            gtmPrincipalDisplayName: {
                serializedName: "gtmPrincipalDisplayName",
                type: {
                    name: "String"
                }
            },
            pgtmPrincipalObjectId: {
                serializedName: "pgtmPrincipalObjectId",
                type: {
                    name: "String"
                }
            },
            pgtmPrincipalDisplayName: {
                serializedName: "pgtmPrincipalDisplayName",
                type: {
                    name: "String"
                }
            },
            gaPrincipalObjectId: {
                serializedName: "gaPrincipalObjectId",
                type: {
                    name: "String"
                }
            },
            gaPrincipalDisplayName: {
                serializedName: "gaPrincipalDisplayName",
                type: {
                    name: "String"
                }
            },
            dpPrincipalObjectId: {
                serializedName: "dpPrincipalObjectId",
                type: {
                    name: "String"
                }
            },
            dpPrincipalDisplayName: {
                serializedName: "dpPrincipalDisplayName",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var DeliverableWithRelatedPrincipalsModelDeliverableRelatedPrincipalsModel = {
    serializedName: "DeliverableWithRelatedPrincipalsModel_deliverableRelatedPrincipalsModel",
    type: {
        name: "Composite",
        className: "DeliverableWithRelatedPrincipalsModelDeliverableRelatedPrincipalsModel",
        modelProperties: __assign({}, DeliverableRelatedPrincipalsModel.type.modelProperties)
    }
};
export var DeliverableWithRelatedPrincipalsModel = {
    serializedName: "DeliverableWithRelatedPrincipalsModel",
    type: {
        name: "Composite",
        className: "DeliverableWithRelatedPrincipalsModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            deliverableRelatedPrincipalsModel: {
                serializedName: "deliverableRelatedPrincipalsModel",
                type: {
                    name: "Composite",
                    className: "DeliverableWithRelatedPrincipalsModelDeliverableRelatedPrincipalsModel"
                }
            }
        }
    }
};
export var DeliverableSetExternalRestApiModel = {
    serializedName: "DeliverableSetExternalRestApiModel",
    type: {
        name: "Composite",
        className: "DeliverableSetExternalRestApiModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            approvalAppLink: {
                required: true,
                serializedName: "approvalAppLink",
                type: {
                    name: "String"
                }
            },
            testPlanAppLink: {
                required: true,
                serializedName: "testPlanAppLink",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var DeliverableSetExternalRestApiResponseModelDeliverableRelatedPrincipalsModel = {
    serializedName: "DeliverableSetExternalRestApiResponseModel_deliverableRelatedPrincipalsModel",
    type: {
        name: "Composite",
        className: "DeliverableSetExternalRestApiResponseModelDeliverableRelatedPrincipalsModel",
        modelProperties: __assign({}, DeliverableRelatedPrincipalsModel.type.modelProperties)
    }
};
export var DeliverableSetExternalRestApiResponseModel = {
    serializedName: "DeliverableSetExternalRestApiResponseModel",
    type: {
        name: "Composite",
        className: "DeliverableSetExternalRestApiResponseModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            deliverableRelatedPrincipalsModel: {
                serializedName: "deliverableRelatedPrincipalsModel",
                type: {
                    name: "Composite",
                    className: "DeliverableSetExternalRestApiResponseModelDeliverableRelatedPrincipalsModel"
                }
            }
        }
    }
};
export var DeliverableRestApiModel = {
    serializedName: "DeliverableRestApiModel",
    type: {
        name: "Composite",
        className: "DeliverableRestApiModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export var DeliverableSetStatusRestApiModel = {
    serializedName: "DeliverableSetStatusRestApiModel",
    type: {
        name: "Composite",
        className: "DeliverableSetStatusRestApiModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            comment: {
                serializedName: "comment",
                type: {
                    name: "String"
                }
            },
            rating: {
                serializedName: "rating",
                type: {
                    name: "String"
                }
            },
            approvalFunctionCode: {
                serializedName: "approvalFunctionCode",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var DeliverableSetStatusRestApiResponseModel = {
    serializedName: "DeliverableSetStatusRestApiResponseModel",
    type: {
        name: "Composite",
        className: "DeliverableSetStatusRestApiResponseModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            isSuccess: {
                required: true,
                serializedName: "isSuccess",
                type: {
                    name: "Boolean"
                }
            },
            errorReason: {
                serializedName: "errorReason",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var DocumentsCategoryModel = {
    serializedName: "DocumentsCategoryModel",
    type: {
        name: "Composite",
        className: "DocumentsCategoryModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            name: {
                serializedName: "name",
                type: {
                    name: "String"
                }
            },
            isActiveInProject: {
                required: true,
                serializedName: "isActiveInProject",
                type: {
                    name: "Boolean"
                }
            },
            hasRelatedDocuments: {
                required: true,
                serializedName: "hasRelatedDocuments",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var DocumentCategoryCreateModel = {
    serializedName: "DocumentCategoryCreateModel",
    type: {
        name: "Composite",
        className: "DocumentCategoryCreateModel",
        modelProperties: {
            name: {
                required: true,
                serializedName: "name",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var DocumentCategoryUpdateModel = {
    serializedName: "DocumentCategoryUpdateModel",
    type: {
        name: "Composite",
        className: "DocumentCategoryUpdateModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            name: {
                required: true,
                serializedName: "name",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var DocumentDefinitionModel = {
    serializedName: "DocumentDefinitionModel",
    type: {
        name: "Composite",
        className: "DocumentDefinitionModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            name: {
                required: true,
                serializedName: "name",
                type: {
                    name: "String"
                }
            },
            description: {
                required: true,
                serializedName: "description",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var EnumModel = {
    serializedName: "EnumModel",
    type: {
        name: "Composite",
        className: "EnumModel",
        modelProperties: {
            label: {
                serializedName: "label",
                type: {
                    name: "String"
                }
            },
            value: {
                serializedName: "value",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var FavoriteProjectModel = {
    serializedName: "FavoriteProjectModel",
    type: {
        name: "Composite",
        className: "FavoriteProjectModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            principalId: {
                required: true,
                serializedName: "principalId",
                type: {
                    name: "Number"
                }
            },
            projectId: {
                required: true,
                serializedName: "projectId",
                type: {
                    name: "Number"
                }
            },
            projectDisplayName: {
                required: true,
                serializedName: "projectDisplayName",
                type: {
                    name: "String"
                }
            },
            projectCode: {
                required: true,
                serializedName: "projectCode",
                type: {
                    name: "String"
                }
            },
            responsibleOrgUnitCodeName: {
                required: true,
                serializedName: "responsibleOrgUnitCodeName",
                type: {
                    name: "String"
                }
            },
            responsibleProjectLeader: {
                required: true,
                serializedName: "responsibleProjectLeader",
                type: {
                    name: "String"
                }
            },
            currentGate: {
                serializedName: "currentGate",
                type: {
                    name: "String"
                }
            },
            functions: {
                serializedName: "functions",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var FileInformationDownloadResponse = {
    serializedName: "FileInformationDownloadResponse",
    type: {
        name: "Composite",
        className: "FileInformationDownloadResponse",
        modelProperties: {
            url: {
                serializedName: "url",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var GateRecommendationStatusModel = {
    serializedName: "GateRecommendationStatusModel",
    type: {
        name: "Composite",
        className: "GateRecommendationStatusModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            status: {
                required: true,
                serializedName: "status",
                type: {
                    name: "String"
                }
            },
            decision: {
                required: true,
                serializedName: "decision",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var GateModelDecision = {
    serializedName: "GateModel_decision",
    type: {
        name: "Composite",
        className: "GateModelDecision",
        modelProperties: __assign({}, GateRecommendationStatusModel.type.modelProperties)
    }
};
export var GateModelRecommendation = {
    serializedName: "GateModel_recommendation",
    type: {
        name: "Composite",
        className: "GateModelRecommendation",
        modelProperties: __assign({}, GateRecommendationStatusModel.type.modelProperties)
    }
};
export var GateModel = {
    serializedName: "GateModel",
    type: {
        name: "Composite",
        className: "GateModel",
        modelProperties: {
            prevGateForecastOrActualDate: {
                serializedName: "prevGateForecastOrActualDate",
                type: {
                    name: "DateTime"
                }
            },
            gateShortDescription: {
                required: true,
                serializedName: "gateShortDescription",
                type: {
                    name: "String"
                }
            },
            projectId: {
                required: true,
                serializedName: "projectId",
                type: {
                    name: "Number"
                }
            },
            containerNumber: {
                required: true,
                serializedName: "containerNumber",
                type: {
                    name: "Number"
                }
            },
            startContainerNumber: {
                required: true,
                serializedName: "startContainerNumber",
                type: {
                    name: "Number"
                }
            },
            isSplitted: {
                required: true,
                serializedName: "isSplitted",
                type: {
                    name: "Boolean"
                }
            },
            isRedone: {
                required: true,
                serializedName: "isRedone",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalEditGateDuration: {
                required: true,
                serializedName: "canPrincipalEditGateDuration",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalEditForecastOrActualDate: {
                required: true,
                serializedName: "canPrincipalEditForecastOrActualDate",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalEditGateDisplayName: {
                required: true,
                serializedName: "canPrincipalEditGateDisplayName",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalEditIsSyncWithNorrdDisabled: {
                required: true,
                serializedName: "canPrincipalEditIsSyncWithNorrdDisabled",
                type: {
                    name: "Boolean"
                }
            },
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            displayName: {
                required: true,
                serializedName: "displayName",
                type: {
                    name: "String"
                }
            },
            gateDescription: {
                required: true,
                serializedName: "gateDescription",
                type: {
                    name: "String"
                }
            },
            scopeLimitation: {
                serializedName: "scopeLimitation",
                type: {
                    name: "String"
                }
            },
            startWeekOffset: {
                required: true,
                serializedName: "startWeekOffset",
                type: {
                    name: "Number"
                }
            },
            endWeekOffset: {
                required: true,
                serializedName: "endWeekOffset",
                type: {
                    name: "Number"
                }
            },
            lengthInWeek: {
                required: true,
                serializedName: "lengthInWeek",
                type: {
                    name: "Number"
                }
            },
            isSyncWithNorrdDisabled: {
                required: true,
                serializedName: "isSyncWithNorrdDisabled",
                type: {
                    name: "Boolean"
                }
            },
            isEnabled: {
                required: true,
                serializedName: "isEnabled",
                type: {
                    name: "Boolean"
                }
            },
            status: {
                required: true,
                serializedName: "status",
                type: {
                    name: "String"
                }
            },
            setupStatus: {
                required: true,
                serializedName: "setupStatus",
                type: {
                    name: "String"
                }
            },
            processStage: {
                required: true,
                serializedName: "processStage",
                type: {
                    name: "String"
                }
            },
            completionTargetDate: {
                serializedName: "completionTargetDate",
                type: {
                    name: "DateTime"
                }
            },
            forecastOrActualDate: {
                required: true,
                serializedName: "forecastOrActualDate",
                type: {
                    name: "DateTime"
                }
            },
            gateMeetingDate: {
                serializedName: "gateMeetingDate",
                type: {
                    name: "DateTime"
                }
            },
            startDate: {
                serializedName: "startDate",
                type: {
                    name: "DateTime"
                }
            },
            skipReason: {
                serializedName: "skipReason",
                type: {
                    name: "String"
                }
            },
            gateScheduleStatus: {
                required: true,
                serializedName: "gateScheduleStatus",
                type: {
                    name: "String"
                }
            },
            decision: {
                serializedName: "decision",
                type: {
                    name: "Composite",
                    className: "GateModelDecision"
                }
            },
            recommendation: {
                serializedName: "recommendation",
                type: {
                    name: "Composite",
                    className: "GateModelRecommendation"
                }
            }
        }
    }
};
export var GateCombineOption = {
    serializedName: "GateCombineOption",
    type: {
        name: "Composite",
        className: "GateCombineOption",
        modelProperties: {
            gateContainerNumber: {
                required: true,
                serializedName: "gateContainerNumber",
                type: {
                    name: "Number"
                }
            },
            gateDisplayName: {
                serializedName: "gateDisplayName",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var GateCreateModel = {
    serializedName: "GateCreateModel",
    type: {
        name: "Composite",
        className: "GateCreateModel",
        modelProperties: {
            projectId: {
                required: true,
                serializedName: "projectId",
                type: {
                    name: "Number"
                }
            },
            previousGateId: {
                serializedName: "previousGateId",
                type: {
                    name: "Number"
                }
            },
            number: {
                serializedName: "number",
                type: {
                    name: "Number"
                }
            },
            name: {
                serializedName: "name",
                type: {
                    name: "String"
                }
            },
            lengthInWeeks: {
                required: true,
                serializedName: "lengthInWeeks",
                type: {
                    name: "Number"
                }
            },
            gateDescription: {
                serializedName: "gateDescription",
                type: {
                    name: "String"
                }
            },
            startWeekOffset: {
                required: true,
                serializedName: "startWeekOffset",
                type: {
                    name: "Number"
                }
            },
            endWeekOffset: {
                required: true,
                serializedName: "endWeekOffset",
                type: {
                    name: "Number"
                }
            },
            isEnabled: {
                required: true,
                serializedName: "isEnabled",
                type: {
                    name: "Boolean"
                }
            },
            norrdGateAssessors: {
                serializedName: "norrdGateAssessors",
                type: {
                    name: "String"
                }
            },
            norrdCompleted: {
                serializedName: "norrdCompleted",
                type: {
                    name: "Boolean"
                }
            },
            completionTargetDate: {
                serializedName: "completionTargetDate",
                type: {
                    name: "DateTime"
                }
            },
            forecastOrActualDate: {
                serializedName: "forecastOrActualDate",
                type: {
                    name: "DateTime"
                }
            },
            minimumAllowedStartDate: {
                serializedName: "minimumAllowedStartDate",
                type: {
                    name: "DateTime"
                }
            },
            gateDefinition: {
                serializedName: "gateDefinition",
                type: {
                    name: "String"
                }
            },
            scopeLimitation: {
                serializedName: "scopeLimitation",
                type: {
                    name: "String"
                }
            },
            combineGateContainerNumber: {
                serializedName: "combineGateContainerNumber",
                type: {
                    name: "Number"
                }
            },
            gateCombineOptions: {
                serializedName: "gateCombineOptions",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GateCombineOption"
                        }
                    }
                }
            },
            isForecastOrActualDateEditable: {
                required: true,
                serializedName: "isForecastOrActualDateEditable",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var ErrorResult = {
    serializedName: "ErrorResult",
    type: {
        name: "Composite",
        className: "ErrorResult",
        modelProperties: {
            errorMessages: {
                serializedName: "errorMessages",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            nextGateId: {
                serializedName: "nextGateId",
                type: {
                    name: "Number"
                }
            },
            gateId: {
                serializedName: "gateId",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export var UpdateGateModel = {
    serializedName: "UpdateGateModel",
    type: {
        name: "Composite",
        className: "UpdateGateModel",
        modelProperties: {
            gateId: {
                required: true,
                serializedName: "gateId",
                type: {
                    name: "Number"
                }
            },
            displayName: {
                serializedName: "displayName",
                type: {
                    name: "String"
                }
            },
            lengthInWeeks: {
                serializedName: "lengthInWeeks",
                type: {
                    name: "Number"
                }
            },
            forecastOrActualDate: {
                serializedName: "forecastOrActualDate",
                type: {
                    name: "DateTime"
                }
            },
            isSyncWithNorrdDisabled: {
                serializedName: "isSyncWithNorrdDisabled",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var GateDisplayNameLookupModel = {
    serializedName: "GateDisplayNameLookupModel",
    type: {
        name: "Composite",
        className: "GateDisplayNameLookupModel",
        modelProperties: {
            number: {
                required: true,
                serializedName: "number",
                type: {
                    name: "Number"
                }
            },
            label: {
                required: true,
                serializedName: "label",
                type: {
                    name: "String"
                }
            },
            isMilestone: {
                required: true,
                serializedName: "isMilestone",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var GateListItemModelDecision = {
    serializedName: "GateListItemModel_decision",
    type: {
        name: "Composite",
        className: "GateListItemModelDecision",
        modelProperties: __assign({}, GateRecommendationStatusModel.type.modelProperties)
    }
};
export var GateListItemModelRecommendation = {
    serializedName: "GateListItemModel_recommendation",
    type: {
        name: "Composite",
        className: "GateListItemModelRecommendation",
        modelProperties: __assign({}, GateRecommendationStatusModel.type.modelProperties)
    }
};
export var GateListItemModel = {
    serializedName: "GateListItemModel",
    type: {
        name: "Composite",
        className: "GateListItemModel",
        modelProperties: {
            isDeleted: {
                required: true,
                serializedName: "isDeleted",
                type: {
                    name: "Boolean"
                }
            },
            isRedone: {
                required: true,
                serializedName: "isRedone",
                type: {
                    name: "Boolean"
                }
            },
            nextGates: {
                serializedName: "nextGates",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            nextGateIds: {
                serializedName: "nextGateIds",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Number"
                        }
                    }
                }
            },
            comment: {
                serializedName: "comment",
                type: {
                    name: "String"
                }
            },
            gateDefinition: {
                serializedName: "gateDefinition",
                type: {
                    name: "String"
                }
            },
            containerNumber: {
                required: true,
                serializedName: "containerNumber",
                type: {
                    name: "Number"
                }
            },
            canPrincipalSetInApprovalGate: {
                required: true,
                serializedName: "canPrincipalSetInApprovalGate",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalApproveGate: {
                required: true,
                serializedName: "canPrincipalApproveGate",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalEditFunctions: {
                required: true,
                serializedName: "canPrincipalEditFunctions",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalEditApprovals: {
                required: true,
                serializedName: "canPrincipalEditApprovals",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalEditChecklists: {
                required: true,
                serializedName: "canPrincipalEditChecklists",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalRemoveGate: {
                required: true,
                serializedName: "canPrincipalRemoveGate",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalAddGate: {
                required: true,
                serializedName: "canPrincipalAddGate",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalSplitGate: {
                required: true,
                serializedName: "canPrincipalSplitGate",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalSetOnHoldGate: {
                required: true,
                serializedName: "canPrincipalSetOnHoldGate",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalClickOnHoldGate: {
                required: true,
                serializedName: "canPrincipalClickOnHoldGate",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalUndoOnHoldGate: {
                required: true,
                serializedName: "canPrincipalUndoOnHoldGate",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalEditIsSyncWithNorrdDisabled: {
                required: true,
                serializedName: "canPrincipalEditIsSyncWithNorrdDisabled",
                type: {
                    name: "Boolean"
                }
            },
            canRedo: {
                required: true,
                serializedName: "canRedo",
                type: {
                    name: "Boolean"
                }
            },
            canJoin: {
                required: true,
                serializedName: "canJoin",
                type: {
                    name: "Boolean"
                }
            },
            isReadyForApproval: {
                required: true,
                serializedName: "isReadyForApproval",
                type: {
                    name: "Boolean"
                }
            },
            validationErrors: {
                required: true,
                serializedName: "validationErrors",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            displayName: {
                required: true,
                serializedName: "displayName",
                type: {
                    name: "String"
                }
            },
            gateDescription: {
                required: true,
                serializedName: "gateDescription",
                type: {
                    name: "String"
                }
            },
            scopeLimitation: {
                serializedName: "scopeLimitation",
                type: {
                    name: "String"
                }
            },
            startWeekOffset: {
                required: true,
                serializedName: "startWeekOffset",
                type: {
                    name: "Number"
                }
            },
            endWeekOffset: {
                required: true,
                serializedName: "endWeekOffset",
                type: {
                    name: "Number"
                }
            },
            lengthInWeek: {
                required: true,
                serializedName: "lengthInWeek",
                type: {
                    name: "Number"
                }
            },
            isSyncWithNorrdDisabled: {
                required: true,
                serializedName: "isSyncWithNorrdDisabled",
                type: {
                    name: "Boolean"
                }
            },
            isEnabled: {
                required: true,
                serializedName: "isEnabled",
                type: {
                    name: "Boolean"
                }
            },
            status: {
                required: true,
                serializedName: "status",
                type: {
                    name: "String"
                }
            },
            setupStatus: {
                required: true,
                serializedName: "setupStatus",
                type: {
                    name: "String"
                }
            },
            processStage: {
                required: true,
                serializedName: "processStage",
                type: {
                    name: "String"
                }
            },
            completionTargetDate: {
                serializedName: "completionTargetDate",
                type: {
                    name: "DateTime"
                }
            },
            forecastOrActualDate: {
                required: true,
                serializedName: "forecastOrActualDate",
                type: {
                    name: "DateTime"
                }
            },
            gateMeetingDate: {
                serializedName: "gateMeetingDate",
                type: {
                    name: "DateTime"
                }
            },
            startDate: {
                serializedName: "startDate",
                type: {
                    name: "DateTime"
                }
            },
            skipReason: {
                serializedName: "skipReason",
                type: {
                    name: "String"
                }
            },
            gateScheduleStatus: {
                required: true,
                serializedName: "gateScheduleStatus",
                type: {
                    name: "String"
                }
            },
            decision: {
                serializedName: "decision",
                type: {
                    name: "Composite",
                    className: "GateListItemModelDecision"
                }
            },
            recommendation: {
                serializedName: "recommendation",
                type: {
                    name: "Composite",
                    className: "GateListItemModelRecommendation"
                }
            }
        }
    }
};
export var GateListModel = {
    serializedName: "GateListModel",
    type: {
        name: "Composite",
        className: "GateListModel",
        modelProperties: {
            gates: {
                required: true,
                serializedName: "gates",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GateListItemModel"
                        }
                    }
                }
            },
            isGateApprovePjmOnlyEnabled: {
                required: true,
                serializedName: "isGateApprovePjmOnlyEnabled",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var GateSplitDescriptionModel = {
    serializedName: "GateSplitDescriptionModel",
    type: {
        name: "Composite",
        className: "GateSplitDescriptionModel",
        modelProperties: {
            name: {
                serializedName: "name",
                type: {
                    name: "String"
                }
            },
            scope: {
                serializedName: "scope",
                type: {
                    name: "String"
                }
            },
            forecastOrActualDate: {
                required: true,
                serializedName: "forecastOrActualDate",
                type: {
                    name: "DateTime"
                }
            },
            lengthInWeeks: {
                required: true,
                serializedName: "lengthInWeeks",
                type: {
                    name: "Number"
                }
            },
            isSyncWithNorrdDisabled: {
                required: true,
                serializedName: "isSyncWithNorrdDisabled",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var GateSplitModel = {
    serializedName: "GateSplitModel",
    type: {
        name: "Composite",
        className: "GateSplitModel",
        modelProperties: {
            gateId: {
                required: true,
                serializedName: "gateId",
                type: {
                    name: "Number"
                }
            },
            splitCount: {
                required: true,
                serializedName: "splitCount",
                type: {
                    name: "Number"
                }
            },
            splitParts: {
                required: true,
                serializedName: "splitParts",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GateSplitDescriptionModel"
                        }
                    }
                }
            }
        }
    }
};
export var GateShortModel = {
    serializedName: "GateShortModel",
    type: {
        name: "Composite",
        className: "GateShortModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            displayName: {
                required: true,
                serializedName: "displayName",
                type: {
                    name: "String"
                }
            },
            setupStatus: {
                required: true,
                serializedName: "setupStatus",
                type: {
                    name: "String"
                }
            },
            isApproved: {
                required: true,
                serializedName: "isApproved",
                type: {
                    name: "Boolean"
                }
            },
            hasRecommendation: {
                required: true,
                serializedName: "hasRecommendation",
                type: {
                    name: "Boolean"
                }
            },
            hasMeeting: {
                required: true,
                serializedName: "hasMeeting",
                type: {
                    name: "Boolean"
                }
            },
            canMakeRecommendation: {
                required: true,
                serializedName: "canMakeRecommendation",
                type: {
                    name: "Boolean"
                }
            },
            canMakeDecision: {
                required: true,
                serializedName: "canMakeDecision",
                type: {
                    name: "Boolean"
                }
            },
            canCreateGateMeetingActionItems: {
                required: true,
                serializedName: "canCreateGateMeetingActionItems",
                type: {
                    name: "Boolean"
                }
            },
            canCreateGateAssessmentActionItems: {
                required: true,
                serializedName: "canCreateGateAssessmentActionItems",
                type: {
                    name: "Boolean"
                }
            },
            hasApprovedRecommendation: {
                required: true,
                serializedName: "hasApprovedRecommendation",
                type: {
                    name: "Boolean"
                }
            },
            gateSkipReason: {
                serializedName: "gateSkipReason",
                type: {
                    name: "String"
                }
            },
            gateStage: {
                required: true,
                serializedName: "gateStage",
                type: {
                    name: "String"
                }
            },
            forecastOrActualDate: {
                required: true,
                serializedName: "forecastOrActualDate",
                type: {
                    name: "DateTime"
                }
            },
            gateMeetingDate: {
                serializedName: "gateMeetingDate",
                type: {
                    name: "DateTime"
                }
            },
            masterCheckListId: {
                required: true,
                serializedName: "masterCheckListId",
                type: {
                    name: "Number"
                }
            },
            hasWarning: {
                required: true,
                serializedName: "hasWarning",
                type: {
                    name: "Boolean"
                }
            },
            warningMessage: {
                serializedName: "warningMessage",
                type: {
                    name: "String"
                }
            },
            canPrincipalUpdateGateAssessmentAssessorPart: {
                required: true,
                serializedName: "canPrincipalUpdateGateAssessmentAssessorPart",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalUpdateGateMeetingAssessorPart: {
                required: true,
                serializedName: "canPrincipalUpdateGateMeetingAssessorPart",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var GateDescriptionModel = {
    serializedName: "GateDescriptionModel",
    type: {
        name: "Composite",
        className: "GateDescriptionModel",
        modelProperties: {
            gateDescription: {
                serializedName: "gateDescription",
                type: {
                    name: "String"
                }
            },
            gateDefinition: {
                serializedName: "gateDefinition",
                type: {
                    name: "String"
                }
            },
            norrdGateAssessors: {
                serializedName: "norrdGateAssessors",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var RelatedFunctionModelFunction = {
    serializedName: "RelatedFunctionModel_function",
    type: {
        name: "Composite",
        className: "RelatedFunctionModelFunction",
        modelProperties: __assign({}, FunctionModel.type.modelProperties)
    }
};
export var RelatedFunctionModelPrincipal = {
    serializedName: "RelatedFunctionModel_principal",
    type: {
        name: "Composite",
        className: "RelatedFunctionModelPrincipal",
        modelProperties: __assign({}, PrincipalModel.type.modelProperties)
    }
};
export var RelatedFunctionModel = {
    serializedName: "RelatedFunctionModel",
    type: {
        name: "Composite",
        className: "RelatedFunctionModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            projectId: {
                required: true,
                serializedName: "projectId",
                type: {
                    name: "Number"
                }
            },
            gateId: {
                serializedName: "gateId",
                type: {
                    name: "Number"
                }
            },
            functionProperty: {
                required: true,
                serializedName: "function",
                type: {
                    name: "Composite",
                    className: "RelatedFunctionModelFunction"
                }
            },
            isEnabled: {
                required: true,
                serializedName: "isEnabled",
                type: {
                    name: "Boolean"
                }
            },
            isDeleted: {
                required: true,
                serializedName: "isDeleted",
                type: {
                    name: "Boolean"
                }
            },
            isEditable: {
                required: true,
                serializedName: "isEditable",
                type: {
                    name: "Boolean"
                }
            },
            assignToName: {
                serializedName: "assignToName",
                type: {
                    name: "String"
                }
            },
            principal: {
                serializedName: "principal",
                type: {
                    name: "Composite",
                    className: "RelatedFunctionModelPrincipal"
                }
            }
        }
    }
};
export var PrincipalConfigurationValueModel = {
    serializedName: "PrincipalConfigurationValueModel",
    type: {
        name: "Composite",
        className: "PrincipalConfigurationValueModel",
        modelProperties: {
            value: {
                serializedName: "value",
                type: {
                    name: "String"
                }
            },
            projectId: {
                serializedName: "projectId",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export var CheckListItemComment = {
    serializedName: "CheckListItemComment",
    type: {
        name: "Composite",
        className: "CheckListItemComment",
        modelProperties: {
            code: {
                serializedName: "code",
                type: {
                    name: "String"
                }
            },
            comment: {
                serializedName: "comment",
                type: {
                    name: "String"
                }
            },
            text: {
                serializedName: "text",
                type: {
                    name: "String"
                }
            },
            fileId: {
                serializedName: "fileId",
                type: {
                    name: "Number"
                }
            },
            needAttention: {
                required: true,
                serializedName: "needAttention",
                type: {
                    name: "Boolean"
                }
            },
            isDeliverable: {
                required: true,
                serializedName: "isDeliverable",
                type: {
                    name: "Boolean"
                }
            },
            hasRedDot: {
                required: true,
                serializedName: "hasRedDot",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var CheckListItemShortModel = {
    serializedName: "CheckListItemShortModel",
    type: {
        name: "Composite",
        className: "CheckListItemShortModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            checkListId: {
                required: true,
                serializedName: "checkListId",
                type: {
                    name: "Number"
                }
            },
            code: {
                serializedName: "code",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var GateAssessmentKeyDeliverableModelMasterDeliverable = {
    serializedName: "GateAssessmentKeyDeliverableModel_masterDeliverable",
    type: {
        name: "Composite",
        className: "GateAssessmentKeyDeliverableModelMasterDeliverable",
        modelProperties: __assign({}, GateDeliverableModel.type.modelProperties)
    }
};
export var GateAssessmentKeyDeliverableModel = {
    serializedName: "GateAssessmentKeyDeliverableModel",
    type: {
        name: "Composite",
        className: "GateAssessmentKeyDeliverableModel",
        modelProperties: {
            checkListItemType: {
                required: true,
                serializedName: "checkListItemType",
                type: {
                    name: "String"
                }
            },
            gateId: {
                required: true,
                serializedName: "gateId",
                type: {
                    name: "Number"
                }
            },
            gateDisplayName: {
                serializedName: "gateDisplayName",
                type: {
                    name: "String"
                }
            },
            keyDeliverables: {
                required: true,
                serializedName: "keyDeliverables",
                type: {
                    name: "String"
                }
            },
            hasDocuments: {
                required: true,
                serializedName: "hasDocuments",
                type: {
                    name: "Boolean"
                }
            },
            hasChildren: {
                required: true,
                serializedName: "hasChildren",
                type: {
                    name: "Boolean"
                }
            },
            funtionNotApplicableRatingPredicate: {
                required: true,
                serializedName: "funtionNotApplicableRatingPredicate",
                type: {
                    name: "Boolean"
                }
            },
            projectManagerNotApplicableRatingPredicate: {
                required: true,
                serializedName: "projectManagerNotApplicableRatingPredicate",
                type: {
                    name: "Boolean"
                }
            },
            assessorNotApplicableRatingPredicate: {
                required: true,
                serializedName: "assessorNotApplicableRatingPredicate",
                type: {
                    name: "Boolean"
                }
            },
            masterCheckListItemOnly: {
                required: true,
                serializedName: "masterCheckListItemOnly",
                type: {
                    name: "Boolean"
                }
            },
            functionalComments: {
                serializedName: "functionalComments",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemComment"
                        }
                    }
                }
            },
            masterDeliverable: {
                serializedName: "masterDeliverable",
                type: {
                    name: "Composite",
                    className: "GateAssessmentKeyDeliverableModelMasterDeliverable"
                }
            },
            isDocumentUploadVisible: {
                required: true,
                serializedName: "isDocumentUploadVisible",
                type: {
                    name: "Boolean"
                }
            },
            canDocumentBeUploadByPjm: {
                required: true,
                serializedName: "canDocumentBeUploadByPjm",
                type: {
                    name: "Boolean"
                }
            },
            taskId: {
                serializedName: "taskId",
                type: {
                    name: "Number"
                }
            },
            taskStatus: {
                serializedName: "taskStatus",
                type: {
                    name: "String"
                }
            },
            isGateEditable: {
                required: true,
                serializedName: "isGateEditable",
                type: {
                    name: "Boolean"
                }
            },
            assessorOriginalComment: {
                serializedName: "assessorOriginalComment",
                type: {
                    name: "String"
                }
            },
            assessorOriginalRating: {
                required: true,
                serializedName: "assessorOriginalRating",
                type: {
                    name: "String"
                }
            },
            childCount: {
                readOnly: true,
                serializedName: "childCount",
                type: {
                    name: "Number"
                }
            },
            greenRatingCount: {
                required: true,
                serializedName: "greenRatingCount",
                type: {
                    name: "Number"
                }
            },
            yellowRatingCount: {
                required: true,
                serializedName: "yellowRatingCount",
                type: {
                    name: "Number"
                }
            },
            redRatingCount: {
                required: true,
                serializedName: "redRatingCount",
                type: {
                    name: "Number"
                }
            },
            notApplicableRatingCount: {
                required: true,
                serializedName: "notApplicableRatingCount",
                type: {
                    name: "Number"
                }
            },
            notRatingCount: {
                required: true,
                serializedName: "notRatingCount",
                type: {
                    name: "Number"
                }
            },
            notRatingItemsCode: {
                serializedName: "notRatingItemsCode",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            notCommentedCount: {
                required: true,
                serializedName: "notCommentedCount",
                type: {
                    name: "Number"
                }
            },
            notRatingCheckListItems: {
                serializedName: "notRatingCheckListItems",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemShortModel"
                        }
                    }
                }
            },
            notCommentedCheckListItems: {
                serializedName: "notCommentedCheckListItems",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemShortModel"
                        }
                    }
                }
            },
            functionalRating: {
                required: true,
                serializedName: "functionalRating",
                type: {
                    name: "String"
                }
            },
            canPrincipalUpdateProjectManagerPart: {
                required: true,
                serializedName: "canPrincipalUpdateProjectManagerPart",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalUpdateGateAssessorPart: {
                required: true,
                serializedName: "canPrincipalUpdateGateAssessorPart",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalAddActionItem: {
                required: true,
                serializedName: "canPrincipalAddActionItem",
                type: {
                    name: "Boolean"
                }
            },
            projectManagerRating: {
                required: true,
                serializedName: "projectManagerRating",
                type: {
                    name: "String"
                }
            },
            projectManagerGreenRatingCount: {
                required: true,
                serializedName: "projectManagerGreenRatingCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerYellowRatingCount: {
                required: true,
                serializedName: "projectManagerYellowRatingCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerRedRatingCount: {
                required: true,
                serializedName: "projectManagerRedRatingCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerNotApplicableRatingCount: {
                required: true,
                serializedName: "projectManagerNotApplicableRatingCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerNotCommentedCount: {
                required: true,
                serializedName: "projectManagerNotCommentedCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerNotRatingCount: {
                required: true,
                serializedName: "projectManagerNotRatingCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerComments: {
                required: true,
                serializedName: "projectManagerComments",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemComment"
                        }
                    }
                }
            },
            projectManagerOriginalComment: {
                serializedName: "projectManagerOriginalComment",
                type: {
                    name: "String"
                }
            },
            projectManagerOriginalRating: {
                required: true,
                serializedName: "projectManagerOriginalRating",
                type: {
                    name: "String"
                }
            },
            projectManagerNotRatingItemsCode: {
                serializedName: "projectManagerNotRatingItemsCode",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            projectManagerNotRatingCheckListItems: {
                serializedName: "projectManagerNotRatingCheckListItems",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemShortModel"
                        }
                    }
                }
            },
            projectManagerNotCommentedCheckListItems: {
                serializedName: "projectManagerNotCommentedCheckListItems",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemShortModel"
                        }
                    }
                }
            },
            assessorRating: {
                required: true,
                serializedName: "assessorRating",
                type: {
                    name: "String"
                }
            },
            assessorComments: {
                required: true,
                serializedName: "assessorComments",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemComment"
                        }
                    }
                }
            },
            assessorGreenRatingCount: {
                required: true,
                serializedName: "assessorGreenRatingCount",
                type: {
                    name: "Number"
                }
            },
            assessorYellowRatingCount: {
                required: true,
                serializedName: "assessorYellowRatingCount",
                type: {
                    name: "Number"
                }
            },
            assessorRedRatingCount: {
                required: true,
                serializedName: "assessorRedRatingCount",
                type: {
                    name: "Number"
                }
            },
            assessorNotApplicableRatingCount: {
                required: true,
                serializedName: "assessorNotApplicableRatingCount",
                type: {
                    name: "Number"
                }
            },
            assessorNotRatingCount: {
                required: true,
                serializedName: "assessorNotRatingCount",
                type: {
                    name: "Number"
                }
            },
            assessorNotRatingItemsCode: {
                serializedName: "assessorNotRatingItemsCode",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            assessorNotCommentedCount: {
                required: true,
                serializedName: "assessorNotCommentedCount",
                type: {
                    name: "Number"
                }
            },
            assessorNotRatingCheckListItems: {
                serializedName: "assessorNotRatingCheckListItems",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemShortModel"
                        }
                    }
                }
            },
            assessorNotCommentedCheckListItems: {
                serializedName: "assessorNotCommentedCheckListItems",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemShortModel"
                        }
                    }
                }
            },
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            checkListId: {
                required: true,
                serializedName: "checkListId",
                type: {
                    name: "Number"
                }
            },
            code: {
                serializedName: "code",
                type: {
                    name: "String"
                }
            },
            question: {
                serializedName: "question",
                type: {
                    name: "String"
                }
            },
            guideline: {
                serializedName: "guideline",
                type: {
                    name: "String"
                }
            },
            functions: {
                required: true,
                serializedName: "functions",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "FunctionWithEditAbility"
                        }
                    }
                }
            },
            isEnabled: {
                required: true,
                serializedName: "isEnabled",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var GateAssessmentFunctionSummary = {
    serializedName: "GateAssessmentFunctionSummary",
    type: {
        name: "Composite",
        className: "GateAssessmentFunctionSummary",
        modelProperties: {
            isMasterFunctionSummary: {
                required: true,
                serializedName: "isMasterFunctionSummary",
                type: {
                    name: "Boolean"
                }
            },
            status: {
                serializedName: "status",
                type: {
                    name: "String"
                }
            },
            approved: {
                serializedName: "approved",
                type: {
                    name: "DateTime"
                }
            },
            funtionNotApplicableRatingPredicate: {
                required: true,
                serializedName: "funtionNotApplicableRatingPredicate",
                type: {
                    name: "Boolean"
                }
            },
            projectManagerNotApplicableRatingPredicate: {
                required: true,
                serializedName: "projectManagerNotApplicableRatingPredicate",
                type: {
                    name: "Boolean"
                }
            },
            assessorNotApplicableRatingPredicate: {
                required: true,
                serializedName: "assessorNotApplicableRatingPredicate",
                type: {
                    name: "Boolean"
                }
            },
            assessorOriginalComment: {
                serializedName: "assessorOriginalComment",
                type: {
                    name: "String"
                }
            },
            assessorOriginalRating: {
                required: true,
                serializedName: "assessorOriginalRating",
                type: {
                    name: "String"
                }
            },
            childCount: {
                readOnly: true,
                serializedName: "childCount",
                type: {
                    name: "Number"
                }
            },
            functionalComments: {
                required: true,
                serializedName: "functionalComments",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemComment"
                        }
                    }
                }
            },
            greenRatingCount: {
                required: true,
                serializedName: "greenRatingCount",
                type: {
                    name: "Number"
                }
            },
            yellowRatingCount: {
                required: true,
                serializedName: "yellowRatingCount",
                type: {
                    name: "Number"
                }
            },
            redRatingCount: {
                required: true,
                serializedName: "redRatingCount",
                type: {
                    name: "Number"
                }
            },
            notApplicableRatingCount: {
                required: true,
                serializedName: "notApplicableRatingCount",
                type: {
                    name: "Number"
                }
            },
            notRatingCount: {
                required: true,
                serializedName: "notRatingCount",
                type: {
                    name: "Number"
                }
            },
            notRatingItemsCode: {
                serializedName: "notRatingItemsCode",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            notCommentedCount: {
                required: true,
                serializedName: "notCommentedCount",
                type: {
                    name: "Number"
                }
            },
            notRatingCheckListItems: {
                serializedName: "notRatingCheckListItems",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemShortModel"
                        }
                    }
                }
            },
            notCommentedCheckListItems: {
                serializedName: "notCommentedCheckListItems",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemShortModel"
                        }
                    }
                }
            },
            functionalRating: {
                required: true,
                serializedName: "functionalRating",
                type: {
                    name: "String"
                }
            },
            canPrincipalUpdateProjectManagerPart: {
                required: true,
                serializedName: "canPrincipalUpdateProjectManagerPart",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalUpdateGateAssessorPart: {
                required: true,
                serializedName: "canPrincipalUpdateGateAssessorPart",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalAddActionItem: {
                required: true,
                serializedName: "canPrincipalAddActionItem",
                type: {
                    name: "Boolean"
                }
            },
            projectManagerRating: {
                required: true,
                serializedName: "projectManagerRating",
                type: {
                    name: "String"
                }
            },
            projectManagerGreenRatingCount: {
                required: true,
                serializedName: "projectManagerGreenRatingCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerYellowRatingCount: {
                required: true,
                serializedName: "projectManagerYellowRatingCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerRedRatingCount: {
                required: true,
                serializedName: "projectManagerRedRatingCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerNotApplicableRatingCount: {
                required: true,
                serializedName: "projectManagerNotApplicableRatingCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerNotCommentedCount: {
                required: true,
                serializedName: "projectManagerNotCommentedCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerNotRatingCount: {
                required: true,
                serializedName: "projectManagerNotRatingCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerComments: {
                required: true,
                serializedName: "projectManagerComments",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemComment"
                        }
                    }
                }
            },
            projectManagerOriginalComment: {
                serializedName: "projectManagerOriginalComment",
                type: {
                    name: "String"
                }
            },
            projectManagerOriginalRating: {
                required: true,
                serializedName: "projectManagerOriginalRating",
                type: {
                    name: "String"
                }
            },
            projectManagerNotRatingItemsCode: {
                serializedName: "projectManagerNotRatingItemsCode",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            projectManagerNotRatingCheckListItems: {
                serializedName: "projectManagerNotRatingCheckListItems",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemShortModel"
                        }
                    }
                }
            },
            projectManagerNotCommentedCheckListItems: {
                serializedName: "projectManagerNotCommentedCheckListItems",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemShortModel"
                        }
                    }
                }
            },
            assessorRating: {
                required: true,
                serializedName: "assessorRating",
                type: {
                    name: "String"
                }
            },
            assessorComments: {
                required: true,
                serializedName: "assessorComments",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemComment"
                        }
                    }
                }
            },
            assessorGreenRatingCount: {
                required: true,
                serializedName: "assessorGreenRatingCount",
                type: {
                    name: "Number"
                }
            },
            assessorYellowRatingCount: {
                required: true,
                serializedName: "assessorYellowRatingCount",
                type: {
                    name: "Number"
                }
            },
            assessorRedRatingCount: {
                required: true,
                serializedName: "assessorRedRatingCount",
                type: {
                    name: "Number"
                }
            },
            assessorNotApplicableRatingCount: {
                required: true,
                serializedName: "assessorNotApplicableRatingCount",
                type: {
                    name: "Number"
                }
            },
            assessorNotRatingCount: {
                required: true,
                serializedName: "assessorNotRatingCount",
                type: {
                    name: "Number"
                }
            },
            assessorNotRatingItemsCode: {
                serializedName: "assessorNotRatingItemsCode",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            assessorNotCommentedCount: {
                required: true,
                serializedName: "assessorNotCommentedCount",
                type: {
                    name: "Number"
                }
            },
            assessorNotRatingCheckListItems: {
                serializedName: "assessorNotRatingCheckListItems",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemShortModel"
                        }
                    }
                }
            },
            assessorNotCommentedCheckListItems: {
                serializedName: "assessorNotCommentedCheckListItems",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemShortModel"
                        }
                    }
                }
            },
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            checkListId: {
                required: true,
                serializedName: "checkListId",
                type: {
                    name: "Number"
                }
            },
            code: {
                serializedName: "code",
                type: {
                    name: "String"
                }
            },
            question: {
                serializedName: "question",
                type: {
                    name: "String"
                }
            },
            guideline: {
                serializedName: "guideline",
                type: {
                    name: "String"
                }
            },
            functions: {
                required: true,
                serializedName: "functions",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "FunctionWithEditAbility"
                        }
                    }
                }
            },
            isEnabled: {
                required: true,
                serializedName: "isEnabled",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var GateAssessmentExecutiveQuestion = {
    serializedName: "GateAssessmentExecutiveQuestion",
    type: {
        name: "Composite",
        className: "GateAssessmentExecutiveQuestion",
        modelProperties: {
            hasChildren: {
                required: true,
                serializedName: "hasChildren",
                type: {
                    name: "Boolean"
                }
            },
            funtionNotApplicableRatingPredicate: {
                required: true,
                serializedName: "funtionNotApplicableRatingPredicate",
                type: {
                    name: "Boolean"
                }
            },
            projectManagerNotApplicableRatingPredicate: {
                required: true,
                serializedName: "projectManagerNotApplicableRatingPredicate",
                type: {
                    name: "Boolean"
                }
            },
            assessorNotApplicableRatingPredicate: {
                required: true,
                serializedName: "assessorNotApplicableRatingPredicate",
                type: {
                    name: "Boolean"
                }
            },
            taskId: {
                serializedName: "taskId",
                type: {
                    name: "Number"
                }
            },
            taskStatus: {
                serializedName: "taskStatus",
                type: {
                    name: "String"
                }
            },
            isGateEditable: {
                required: true,
                serializedName: "isGateEditable",
                type: {
                    name: "Boolean"
                }
            },
            assessorOriginalComment: {
                serializedName: "assessorOriginalComment",
                type: {
                    name: "String"
                }
            },
            assessorOriginalRating: {
                required: true,
                serializedName: "assessorOriginalRating",
                type: {
                    name: "String"
                }
            },
            childCount: {
                readOnly: true,
                serializedName: "childCount",
                type: {
                    name: "Number"
                }
            },
            functionalComments: {
                required: true,
                serializedName: "functionalComments",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemComment"
                        }
                    }
                }
            },
            greenRatingCount: {
                required: true,
                serializedName: "greenRatingCount",
                type: {
                    name: "Number"
                }
            },
            yellowRatingCount: {
                required: true,
                serializedName: "yellowRatingCount",
                type: {
                    name: "Number"
                }
            },
            redRatingCount: {
                required: true,
                serializedName: "redRatingCount",
                type: {
                    name: "Number"
                }
            },
            notApplicableRatingCount: {
                required: true,
                serializedName: "notApplicableRatingCount",
                type: {
                    name: "Number"
                }
            },
            notRatingCount: {
                required: true,
                serializedName: "notRatingCount",
                type: {
                    name: "Number"
                }
            },
            notRatingItemsCode: {
                serializedName: "notRatingItemsCode",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            notCommentedCount: {
                required: true,
                serializedName: "notCommentedCount",
                type: {
                    name: "Number"
                }
            },
            notRatingCheckListItems: {
                serializedName: "notRatingCheckListItems",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemShortModel"
                        }
                    }
                }
            },
            notCommentedCheckListItems: {
                serializedName: "notCommentedCheckListItems",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemShortModel"
                        }
                    }
                }
            },
            functionalRating: {
                required: true,
                serializedName: "functionalRating",
                type: {
                    name: "String"
                }
            },
            canPrincipalUpdateProjectManagerPart: {
                required: true,
                serializedName: "canPrincipalUpdateProjectManagerPart",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalUpdateGateAssessorPart: {
                required: true,
                serializedName: "canPrincipalUpdateGateAssessorPart",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalAddActionItem: {
                required: true,
                serializedName: "canPrincipalAddActionItem",
                type: {
                    name: "Boolean"
                }
            },
            projectManagerRating: {
                required: true,
                serializedName: "projectManagerRating",
                type: {
                    name: "String"
                }
            },
            projectManagerGreenRatingCount: {
                required: true,
                serializedName: "projectManagerGreenRatingCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerYellowRatingCount: {
                required: true,
                serializedName: "projectManagerYellowRatingCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerRedRatingCount: {
                required: true,
                serializedName: "projectManagerRedRatingCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerNotApplicableRatingCount: {
                required: true,
                serializedName: "projectManagerNotApplicableRatingCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerNotCommentedCount: {
                required: true,
                serializedName: "projectManagerNotCommentedCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerNotRatingCount: {
                required: true,
                serializedName: "projectManagerNotRatingCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerComments: {
                required: true,
                serializedName: "projectManagerComments",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemComment"
                        }
                    }
                }
            },
            projectManagerOriginalComment: {
                serializedName: "projectManagerOriginalComment",
                type: {
                    name: "String"
                }
            },
            projectManagerOriginalRating: {
                required: true,
                serializedName: "projectManagerOriginalRating",
                type: {
                    name: "String"
                }
            },
            projectManagerNotRatingItemsCode: {
                serializedName: "projectManagerNotRatingItemsCode",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            projectManagerNotRatingCheckListItems: {
                serializedName: "projectManagerNotRatingCheckListItems",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemShortModel"
                        }
                    }
                }
            },
            projectManagerNotCommentedCheckListItems: {
                serializedName: "projectManagerNotCommentedCheckListItems",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemShortModel"
                        }
                    }
                }
            },
            assessorRating: {
                required: true,
                serializedName: "assessorRating",
                type: {
                    name: "String"
                }
            },
            assessorComments: {
                required: true,
                serializedName: "assessorComments",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemComment"
                        }
                    }
                }
            },
            assessorGreenRatingCount: {
                required: true,
                serializedName: "assessorGreenRatingCount",
                type: {
                    name: "Number"
                }
            },
            assessorYellowRatingCount: {
                required: true,
                serializedName: "assessorYellowRatingCount",
                type: {
                    name: "Number"
                }
            },
            assessorRedRatingCount: {
                required: true,
                serializedName: "assessorRedRatingCount",
                type: {
                    name: "Number"
                }
            },
            assessorNotApplicableRatingCount: {
                required: true,
                serializedName: "assessorNotApplicableRatingCount",
                type: {
                    name: "Number"
                }
            },
            assessorNotRatingCount: {
                required: true,
                serializedName: "assessorNotRatingCount",
                type: {
                    name: "Number"
                }
            },
            assessorNotRatingItemsCode: {
                serializedName: "assessorNotRatingItemsCode",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            assessorNotCommentedCount: {
                required: true,
                serializedName: "assessorNotCommentedCount",
                type: {
                    name: "Number"
                }
            },
            assessorNotRatingCheckListItems: {
                serializedName: "assessorNotRatingCheckListItems",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemShortModel"
                        }
                    }
                }
            },
            assessorNotCommentedCheckListItems: {
                serializedName: "assessorNotCommentedCheckListItems",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemShortModel"
                        }
                    }
                }
            },
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            checkListId: {
                required: true,
                serializedName: "checkListId",
                type: {
                    name: "Number"
                }
            },
            code: {
                serializedName: "code",
                type: {
                    name: "String"
                }
            },
            question: {
                serializedName: "question",
                type: {
                    name: "String"
                }
            },
            guideline: {
                serializedName: "guideline",
                type: {
                    name: "String"
                }
            },
            functions: {
                required: true,
                serializedName: "functions",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "FunctionWithEditAbility"
                        }
                    }
                }
            },
            isEnabled: {
                required: true,
                serializedName: "isEnabled",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var GateMeetingModel = {
    serializedName: "GateMeetingModel",
    type: {
        name: "Composite",
        className: "GateMeetingModel",
        modelProperties: {
            canPrincipalApproveDecision: {
                required: true,
                serializedName: "canPrincipalApproveDecision",
                type: {
                    name: "Boolean"
                }
            },
            canDecisionBeDirectlyApproved: {
                required: true,
                serializedName: "canDecisionBeDirectlyApproved",
                type: {
                    name: "Boolean"
                }
            },
            denyComment: {
                serializedName: "denyComment",
                type: {
                    name: "String"
                }
            },
            gateId: {
                required: true,
                serializedName: "gateId",
                type: {
                    name: "Number"
                }
            },
            gateName: {
                serializedName: "gateName",
                type: {
                    name: "String"
                }
            },
            masterCheckListId: {
                required: true,
                serializedName: "masterCheckListId",
                type: {
                    name: "Number"
                }
            },
            masterCheckListCode: {
                serializedName: "masterCheckListCode",
                type: {
                    name: "String"
                }
            },
            canPrincipalUpdateDecision: {
                required: true,
                serializedName: "canPrincipalUpdateDecision",
                type: {
                    name: "Boolean"
                }
            },
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            status: {
                required: true,
                serializedName: "status",
                type: {
                    name: "String"
                }
            },
            decision: {
                required: true,
                serializedName: "decision",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var GateKeyDeliverableModel = {
    serializedName: "GateKeyDeliverableModel",
    type: {
        name: "Composite",
        className: "GateKeyDeliverableModel",
        modelProperties: {
            checkListItemType: {
                required: true,
                serializedName: "checkListItemType",
                type: {
                    name: "String"
                }
            },
            keyDeliverables: {
                required: true,
                serializedName: "keyDeliverables",
                type: {
                    name: "String"
                }
            },
            hasDocuments: {
                required: true,
                serializedName: "hasDocuments",
                type: {
                    name: "Boolean"
                }
            },
            hasRedDot: {
                required: true,
                serializedName: "hasRedDot",
                type: {
                    name: "Boolean"
                }
            },
            hasChildren: {
                required: true,
                serializedName: "hasChildren",
                type: {
                    name: "Boolean"
                }
            },
            projectManagerNotApplicableRatingPredicate: {
                required: true,
                serializedName: "projectManagerNotApplicableRatingPredicate",
                type: {
                    name: "Boolean"
                }
            },
            assessorNotApplicableRatingPredicate: {
                required: true,
                serializedName: "assessorNotApplicableRatingPredicate",
                type: {
                    name: "Boolean"
                }
            },
            hasFunctionalCheckListItems: {
                required: true,
                serializedName: "hasFunctionalCheckListItems",
                type: {
                    name: "Boolean"
                }
            },
            assessorComment: {
                serializedName: "assessorComment",
                type: {
                    name: "String"
                }
            },
            assessorOriginalRating: {
                required: true,
                serializedName: "assessorOriginalRating",
                type: {
                    name: "String"
                }
            },
            canPrincipalUpdateMeetingAssessorPart: {
                required: true,
                serializedName: "canPrincipalUpdateMeetingAssessorPart",
                type: {
                    name: "Boolean"
                }
            },
            projectManagerRating: {
                required: true,
                serializedName: "projectManagerRating",
                type: {
                    name: "String"
                }
            },
            projectManagerGreenRatingCount: {
                required: true,
                serializedName: "projectManagerGreenRatingCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerYellowRatingCount: {
                required: true,
                serializedName: "projectManagerYellowRatingCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerRedRatingCount: {
                required: true,
                serializedName: "projectManagerRedRatingCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerNotApplicableRatingCount: {
                required: true,
                serializedName: "projectManagerNotApplicableRatingCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerNotCommentedCount: {
                required: true,
                serializedName: "projectManagerNotCommentedCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerNotRatingCount: {
                required: true,
                serializedName: "projectManagerNotRatingCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerComments: {
                required: true,
                serializedName: "projectManagerComments",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemComment"
                        }
                    }
                }
            },
            projectManagerOriginalComment: {
                serializedName: "projectManagerOriginalComment",
                type: {
                    name: "String"
                }
            },
            projectManagerOriginalRating: {
                required: true,
                serializedName: "projectManagerOriginalRating",
                type: {
                    name: "String"
                }
            },
            projectManagerNotRatingItemsCode: {
                serializedName: "projectManagerNotRatingItemsCode",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            projectManagerNotRatingCheckListItems: {
                serializedName: "projectManagerNotRatingCheckListItems",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemShortModel"
                        }
                    }
                }
            },
            projectManagerNotCommentedCheckListItems: {
                serializedName: "projectManagerNotCommentedCheckListItems",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemShortModel"
                        }
                    }
                }
            },
            assessorRating: {
                required: true,
                serializedName: "assessorRating",
                type: {
                    name: "String"
                }
            },
            assessorComments: {
                required: true,
                serializedName: "assessorComments",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemComment"
                        }
                    }
                }
            },
            assessorGreenRatingCount: {
                required: true,
                serializedName: "assessorGreenRatingCount",
                type: {
                    name: "Number"
                }
            },
            assessorYellowRatingCount: {
                required: true,
                serializedName: "assessorYellowRatingCount",
                type: {
                    name: "Number"
                }
            },
            assessorRedRatingCount: {
                required: true,
                serializedName: "assessorRedRatingCount",
                type: {
                    name: "Number"
                }
            },
            assessorNotApplicableRatingCount: {
                required: true,
                serializedName: "assessorNotApplicableRatingCount",
                type: {
                    name: "Number"
                }
            },
            assessorNotRatingCount: {
                required: true,
                serializedName: "assessorNotRatingCount",
                type: {
                    name: "Number"
                }
            },
            assessorNotRatingItemsCode: {
                serializedName: "assessorNotRatingItemsCode",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            assessorNotCommentedCount: {
                required: true,
                serializedName: "assessorNotCommentedCount",
                type: {
                    name: "Number"
                }
            },
            assessorNotRatingCheckListItems: {
                serializedName: "assessorNotRatingCheckListItems",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemShortModel"
                        }
                    }
                }
            },
            assessorNotCommentedCheckListItems: {
                serializedName: "assessorNotCommentedCheckListItems",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemShortModel"
                        }
                    }
                }
            },
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            checkListId: {
                required: true,
                serializedName: "checkListId",
                type: {
                    name: "Number"
                }
            },
            code: {
                serializedName: "code",
                type: {
                    name: "String"
                }
            },
            question: {
                serializedName: "question",
                type: {
                    name: "String"
                }
            },
            guideline: {
                serializedName: "guideline",
                type: {
                    name: "String"
                }
            },
            functions: {
                required: true,
                serializedName: "functions",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "FunctionWithEditAbility"
                        }
                    }
                }
            },
            childCount: {
                serializedName: "childCount",
                type: {
                    name: "Number"
                }
            },
            isEnabled: {
                required: true,
                serializedName: "isEnabled",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var GateFunctionSummaryModel = {
    serializedName: "GateFunctionSummaryModel",
    type: {
        name: "Composite",
        className: "GateFunctionSummaryModel",
        modelProperties: {
            isMasterFunctionSummary: {
                required: true,
                serializedName: "isMasterFunctionSummary",
                type: {
                    name: "Boolean"
                }
            },
            hasFunctionalCheckListItems: {
                required: true,
                serializedName: "hasFunctionalCheckListItems",
                type: {
                    name: "Boolean"
                }
            },
            projectManagerNotApplicableRatingPredicate: {
                required: true,
                serializedName: "projectManagerNotApplicableRatingPredicate",
                type: {
                    name: "Boolean"
                }
            },
            assessorNotApplicableRatingPredicate: {
                required: true,
                serializedName: "assessorNotApplicableRatingPredicate",
                type: {
                    name: "Boolean"
                }
            },
            assessorComment: {
                serializedName: "assessorComment",
                type: {
                    name: "String"
                }
            },
            assessorOriginalRating: {
                required: true,
                serializedName: "assessorOriginalRating",
                type: {
                    name: "String"
                }
            },
            canPrincipalUpdateMeetingAssessorPart: {
                required: true,
                serializedName: "canPrincipalUpdateMeetingAssessorPart",
                type: {
                    name: "Boolean"
                }
            },
            projectManagerRating: {
                required: true,
                serializedName: "projectManagerRating",
                type: {
                    name: "String"
                }
            },
            projectManagerGreenRatingCount: {
                required: true,
                serializedName: "projectManagerGreenRatingCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerYellowRatingCount: {
                required: true,
                serializedName: "projectManagerYellowRatingCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerRedRatingCount: {
                required: true,
                serializedName: "projectManagerRedRatingCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerNotApplicableRatingCount: {
                required: true,
                serializedName: "projectManagerNotApplicableRatingCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerNotCommentedCount: {
                required: true,
                serializedName: "projectManagerNotCommentedCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerNotRatingCount: {
                required: true,
                serializedName: "projectManagerNotRatingCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerComments: {
                required: true,
                serializedName: "projectManagerComments",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemComment"
                        }
                    }
                }
            },
            projectManagerOriginalComment: {
                serializedName: "projectManagerOriginalComment",
                type: {
                    name: "String"
                }
            },
            projectManagerOriginalRating: {
                required: true,
                serializedName: "projectManagerOriginalRating",
                type: {
                    name: "String"
                }
            },
            projectManagerNotRatingItemsCode: {
                serializedName: "projectManagerNotRatingItemsCode",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            projectManagerNotRatingCheckListItems: {
                serializedName: "projectManagerNotRatingCheckListItems",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemShortModel"
                        }
                    }
                }
            },
            projectManagerNotCommentedCheckListItems: {
                serializedName: "projectManagerNotCommentedCheckListItems",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemShortModel"
                        }
                    }
                }
            },
            assessorRating: {
                required: true,
                serializedName: "assessorRating",
                type: {
                    name: "String"
                }
            },
            assessorComments: {
                required: true,
                serializedName: "assessorComments",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemComment"
                        }
                    }
                }
            },
            assessorGreenRatingCount: {
                required: true,
                serializedName: "assessorGreenRatingCount",
                type: {
                    name: "Number"
                }
            },
            assessorYellowRatingCount: {
                required: true,
                serializedName: "assessorYellowRatingCount",
                type: {
                    name: "Number"
                }
            },
            assessorRedRatingCount: {
                required: true,
                serializedName: "assessorRedRatingCount",
                type: {
                    name: "Number"
                }
            },
            assessorNotApplicableRatingCount: {
                required: true,
                serializedName: "assessorNotApplicableRatingCount",
                type: {
                    name: "Number"
                }
            },
            assessorNotRatingCount: {
                required: true,
                serializedName: "assessorNotRatingCount",
                type: {
                    name: "Number"
                }
            },
            assessorNotRatingItemsCode: {
                serializedName: "assessorNotRatingItemsCode",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            assessorNotCommentedCount: {
                required: true,
                serializedName: "assessorNotCommentedCount",
                type: {
                    name: "Number"
                }
            },
            assessorNotRatingCheckListItems: {
                serializedName: "assessorNotRatingCheckListItems",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemShortModel"
                        }
                    }
                }
            },
            assessorNotCommentedCheckListItems: {
                serializedName: "assessorNotCommentedCheckListItems",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemShortModel"
                        }
                    }
                }
            },
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            checkListId: {
                required: true,
                serializedName: "checkListId",
                type: {
                    name: "Number"
                }
            },
            code: {
                serializedName: "code",
                type: {
                    name: "String"
                }
            },
            question: {
                serializedName: "question",
                type: {
                    name: "String"
                }
            },
            guideline: {
                serializedName: "guideline",
                type: {
                    name: "String"
                }
            },
            functions: {
                required: true,
                serializedName: "functions",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "FunctionWithEditAbility"
                        }
                    }
                }
            },
            childCount: {
                serializedName: "childCount",
                type: {
                    name: "Number"
                }
            },
            isEnabled: {
                required: true,
                serializedName: "isEnabled",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var GateExecutiveQuestionModel = {
    serializedName: "GateExecutiveQuestionModel",
    type: {
        name: "Composite",
        className: "GateExecutiveQuestionModel",
        modelProperties: {
            hasChildren: {
                required: true,
                serializedName: "hasChildren",
                type: {
                    name: "Boolean"
                }
            },
            projectManagerNotApplicableRatingPredicate: {
                required: true,
                serializedName: "projectManagerNotApplicableRatingPredicate",
                type: {
                    name: "Boolean"
                }
            },
            assessorNotApplicableRatingPredicate: {
                required: true,
                serializedName: "assessorNotApplicableRatingPredicate",
                type: {
                    name: "Boolean"
                }
            },
            assessorComment: {
                serializedName: "assessorComment",
                type: {
                    name: "String"
                }
            },
            assessorOriginalRating: {
                required: true,
                serializedName: "assessorOriginalRating",
                type: {
                    name: "String"
                }
            },
            canPrincipalUpdateMeetingAssessorPart: {
                required: true,
                serializedName: "canPrincipalUpdateMeetingAssessorPart",
                type: {
                    name: "Boolean"
                }
            },
            projectManagerRating: {
                required: true,
                serializedName: "projectManagerRating",
                type: {
                    name: "String"
                }
            },
            projectManagerGreenRatingCount: {
                required: true,
                serializedName: "projectManagerGreenRatingCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerYellowRatingCount: {
                required: true,
                serializedName: "projectManagerYellowRatingCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerRedRatingCount: {
                required: true,
                serializedName: "projectManagerRedRatingCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerNotApplicableRatingCount: {
                required: true,
                serializedName: "projectManagerNotApplicableRatingCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerNotCommentedCount: {
                required: true,
                serializedName: "projectManagerNotCommentedCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerNotRatingCount: {
                required: true,
                serializedName: "projectManagerNotRatingCount",
                type: {
                    name: "Number"
                }
            },
            projectManagerComments: {
                required: true,
                serializedName: "projectManagerComments",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemComment"
                        }
                    }
                }
            },
            projectManagerOriginalComment: {
                serializedName: "projectManagerOriginalComment",
                type: {
                    name: "String"
                }
            },
            projectManagerOriginalRating: {
                required: true,
                serializedName: "projectManagerOriginalRating",
                type: {
                    name: "String"
                }
            },
            projectManagerNotRatingItemsCode: {
                serializedName: "projectManagerNotRatingItemsCode",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            projectManagerNotRatingCheckListItems: {
                serializedName: "projectManagerNotRatingCheckListItems",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemShortModel"
                        }
                    }
                }
            },
            projectManagerNotCommentedCheckListItems: {
                serializedName: "projectManagerNotCommentedCheckListItems",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemShortModel"
                        }
                    }
                }
            },
            assessorRating: {
                required: true,
                serializedName: "assessorRating",
                type: {
                    name: "String"
                }
            },
            assessorComments: {
                required: true,
                serializedName: "assessorComments",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemComment"
                        }
                    }
                }
            },
            assessorGreenRatingCount: {
                required: true,
                serializedName: "assessorGreenRatingCount",
                type: {
                    name: "Number"
                }
            },
            assessorYellowRatingCount: {
                required: true,
                serializedName: "assessorYellowRatingCount",
                type: {
                    name: "Number"
                }
            },
            assessorRedRatingCount: {
                required: true,
                serializedName: "assessorRedRatingCount",
                type: {
                    name: "Number"
                }
            },
            assessorNotApplicableRatingCount: {
                required: true,
                serializedName: "assessorNotApplicableRatingCount",
                type: {
                    name: "Number"
                }
            },
            assessorNotRatingCount: {
                required: true,
                serializedName: "assessorNotRatingCount",
                type: {
                    name: "Number"
                }
            },
            assessorNotRatingItemsCode: {
                serializedName: "assessorNotRatingItemsCode",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            assessorNotCommentedCount: {
                required: true,
                serializedName: "assessorNotCommentedCount",
                type: {
                    name: "Number"
                }
            },
            assessorNotRatingCheckListItems: {
                serializedName: "assessorNotRatingCheckListItems",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemShortModel"
                        }
                    }
                }
            },
            assessorNotCommentedCheckListItems: {
                serializedName: "assessorNotCommentedCheckListItems",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CheckListItemShortModel"
                        }
                    }
                }
            },
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            checkListId: {
                required: true,
                serializedName: "checkListId",
                type: {
                    name: "Number"
                }
            },
            code: {
                serializedName: "code",
                type: {
                    name: "String"
                }
            },
            question: {
                serializedName: "question",
                type: {
                    name: "String"
                }
            },
            guideline: {
                serializedName: "guideline",
                type: {
                    name: "String"
                }
            },
            functions: {
                required: true,
                serializedName: "functions",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "FunctionWithEditAbility"
                        }
                    }
                }
            },
            childCount: {
                serializedName: "childCount",
                type: {
                    name: "Number"
                }
            },
            isEnabled: {
                required: true,
                serializedName: "isEnabled",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var GateRecommendationModel = {
    serializedName: "GateRecommendationModel",
    type: {
        name: "Composite",
        className: "GateRecommendationModel",
        modelProperties: {
            gateId: {
                required: true,
                serializedName: "gateId",
                type: {
                    name: "Number"
                }
            },
            gateName: {
                serializedName: "gateName",
                type: {
                    name: "String"
                }
            },
            masterCheckListId: {
                required: true,
                serializedName: "masterCheckListId",
                type: {
                    name: "Number"
                }
            },
            masterCheckListCode: {
                serializedName: "masterCheckListCode",
                type: {
                    name: "String"
                }
            },
            canPrincipalUpdateDecision: {
                required: true,
                serializedName: "canPrincipalUpdateDecision",
                type: {
                    name: "Boolean"
                }
            },
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            status: {
                required: true,
                serializedName: "status",
                type: {
                    name: "String"
                }
            },
            decision: {
                required: true,
                serializedName: "decision",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var GateRecommendationRestApiModel = {
    serializedName: "GateRecommendationRestApiModel",
    type: {
        name: "Composite",
        className: "GateRecommendationRestApiModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            uniqueId: {
                serializedName: "uniqueId",
                type: {
                    name: "String"
                }
            },
            dateOfRecomendation: {
                serializedName: "dateOfRecomendation",
                type: {
                    name: "DateTime"
                }
            },
            status: {
                required: true,
                serializedName: "status",
                type: {
                    name: "String"
                }
            },
            approvedAtDateTime: {
                serializedName: "approvedAtDateTime",
                type: {
                    name: "DateTime"
                }
            },
            recommendationDecision: {
                required: true,
                serializedName: "recommendationDecision",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var GateMeetingRestApiModel = {
    serializedName: "GateMeetingRestApiModel",
    type: {
        name: "Composite",
        className: "GateMeetingRestApiModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            uniqueId: {
                serializedName: "uniqueId",
                type: {
                    name: "String"
                }
            },
            status: {
                required: true,
                serializedName: "status",
                type: {
                    name: "String"
                }
            },
            approvedAtDateTime: {
                serializedName: "approvedAtDateTime",
                type: {
                    name: "DateTime"
                }
            },
            meetingDecision: {
                required: true,
                serializedName: "meetingDecision",
                type: {
                    name: "String"
                }
            },
            meetingStartDateTime: {
                serializedName: "meetingStartDateTime",
                type: {
                    name: "DateTime"
                }
            },
            meetingEndDateTime: {
                serializedName: "meetingEndDateTime",
                type: {
                    name: "DateTime"
                }
            }
        }
    }
};
export var GateRestApiModelGateRecommendation = {
    serializedName: "GateRestApiModel_gateRecommendation",
    type: {
        name: "Composite",
        className: "GateRestApiModelGateRecommendation",
        modelProperties: __assign({}, GateRecommendationRestApiModel.type.modelProperties)
    }
};
export var GateRestApiModelGateMeeting = {
    serializedName: "GateRestApiModel_gateMeeting",
    type: {
        name: "Composite",
        className: "GateRestApiModelGateMeeting",
        modelProperties: __assign({}, GateMeetingRestApiModel.type.modelProperties)
    }
};
export var GateRestApiModel = {
    serializedName: "GateRestApiModel",
    type: {
        name: "Composite",
        className: "GateRestApiModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            uniqueId: {
                serializedName: "uniqueId",
                type: {
                    name: "String"
                }
            },
            number: {
                required: true,
                serializedName: "number",
                type: {
                    name: "Number"
                }
            },
            description: {
                serializedName: "description",
                type: {
                    name: "String"
                }
            },
            gateScopeLimitation: {
                serializedName: "gateScopeLimitation",
                type: {
                    name: "String"
                }
            },
            startWeekOffset: {
                required: true,
                serializedName: "startWeekOffset",
                type: {
                    name: "Number"
                }
            },
            endWeekOffset: {
                required: true,
                serializedName: "endWeekOffset",
                type: {
                    name: "Number"
                }
            },
            isEnabled: {
                required: true,
                serializedName: "isEnabled",
                type: {
                    name: "Boolean"
                }
            },
            isDeleted: {
                required: true,
                serializedName: "isDeleted",
                type: {
                    name: "Boolean"
                }
            },
            gateTemplateId: {
                serializedName: "gateTemplateId",
                type: {
                    name: "Number"
                }
            },
            displayName: {
                serializedName: "displayName",
                type: {
                    name: "String"
                }
            },
            setupStatus: {
                required: true,
                serializedName: "setupStatus",
                type: {
                    name: "String"
                }
            },
            status: {
                required: true,
                serializedName: "status",
                type: {
                    name: "String"
                }
            },
            processStage: {
                required: true,
                serializedName: "processStage",
                type: {
                    name: "String"
                }
            },
            projectId: {
                required: true,
                serializedName: "projectId",
                type: {
                    name: "Number"
                }
            },
            projectCodeName: {
                serializedName: "projectCodeName",
                type: {
                    name: "String"
                }
            },
            projectDisplayName: {
                serializedName: "projectDisplayName",
                type: {
                    name: "String"
                }
            },
            containerNumber: {
                required: true,
                serializedName: "containerNumber",
                type: {
                    name: "Number"
                }
            },
            lengthInWeeks: {
                required: true,
                serializedName: "lengthInWeeks",
                type: {
                    name: "Number"
                }
            },
            completionTargetDate: {
                serializedName: "completionTargetDate",
                type: {
                    name: "DateTime"
                }
            },
            forecastOrActualDate: {
                required: true,
                serializedName: "forecastOrActualDate",
                type: {
                    name: "DateTime"
                }
            },
            gateDefinition: {
                serializedName: "gateDefinition",
                type: {
                    name: "String"
                }
            },
            isRedone: {
                required: true,
                serializedName: "isRedone",
                type: {
                    name: "Boolean"
                }
            },
            skipReason: {
                serializedName: "skipReason",
                type: {
                    name: "String"
                }
            },
            startDate: {
                serializedName: "startDate",
                type: {
                    name: "DateTime"
                }
            },
            startContainerNumber: {
                required: true,
                serializedName: "startContainerNumber",
                type: {
                    name: "Number"
                }
            },
            isRedoneBySystem: {
                required: true,
                serializedName: "isRedoneBySystem",
                type: {
                    name: "Boolean"
                }
            },
            isSplitted: {
                required: true,
                serializedName: "isSplitted",
                type: {
                    name: "Boolean"
                }
            },
            isSyncWithNorrdDisabled: {
                required: true,
                serializedName: "isSyncWithNorrdDisabled",
                type: {
                    name: "Boolean"
                }
            },
            pjMPrincipalEmail: {
                serializedName: "pjMPrincipalEmail",
                type: {
                    name: "String"
                }
            },
            pjMPrincipalDisplayName: {
                serializedName: "pjMPrincipalDisplayName",
                type: {
                    name: "String"
                }
            },
            gaPrincipalEmail: {
                serializedName: "gaPrincipalEmail",
                type: {
                    name: "String"
                }
            },
            gaPrincipalDisplayName: {
                serializedName: "gaPrincipalDisplayName",
                type: {
                    name: "String"
                }
            },
            goPrincipalEmail: {
                serializedName: "goPrincipalEmail",
                type: {
                    name: "String"
                }
            },
            goPrincipalDisplayName: {
                serializedName: "goPrincipalDisplayName",
                type: {
                    name: "String"
                }
            },
            url: {
                serializedName: "url",
                type: {
                    name: "String"
                }
            },
            gateMeetingDate: {
                serializedName: "gateMeetingDate",
                type: {
                    name: "DateTime"
                }
            },
            gateScheduleStatus: {
                required: true,
                serializedName: "gateScheduleStatus",
                type: {
                    name: "String"
                }
            },
            gateRecommendation: {
                serializedName: "gateRecommendation",
                type: {
                    name: "Composite",
                    className: "GateRestApiModelGateRecommendation"
                }
            },
            gateMeeting: {
                serializedName: "gateMeeting",
                type: {
                    name: "Composite",
                    className: "GateRestApiModelGateMeeting"
                }
            }
        }
    }
};
export var GateShortInfoRestApiModel = {
    serializedName: "GateShortInfoRestApiModel",
    type: {
        name: "Composite",
        className: "GateShortInfoRestApiModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            displayName: {
                required: true,
                serializedName: "displayName",
                type: {
                    name: "String"
                }
            },
            startDate: {
                serializedName: "startDate",
                type: {
                    name: "DateTime"
                }
            },
            forecastOrActualDate: {
                required: true,
                serializedName: "forecastOrActualDate",
                type: {
                    name: "DateTime"
                }
            },
            containerNumber: {
                required: true,
                serializedName: "containerNumber",
                type: {
                    name: "Number"
                }
            },
            startContainerNumber: {
                required: true,
                serializedName: "startContainerNumber",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export var AppVersionModel = {
    serializedName: "AppVersionModel",
    type: {
        name: "Composite",
        className: "AppVersionModel",
        modelProperties: {
            version: {
                serializedName: "version",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var HelpLinkModel = {
    serializedName: "HelpLinkModel",
    type: {
        name: "Composite",
        className: "HelpLinkModel",
        modelProperties: {
            helpLink: {
                serializedName: "helpLink",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var JobParameterDescriptionModel = {
    serializedName: "JobParameterDescriptionModel",
    type: {
        name: "Composite",
        className: "JobParameterDescriptionModel",
        modelProperties: {
            name: {
                required: true,
                serializedName: "name",
                type: {
                    name: "String"
                }
            },
            value: {
                serializedName: "value",
                type: {
                    name: "String"
                }
            },
            displayName: {
                serializedName: "displayName",
                type: {
                    name: "String"
                }
            },
            type: {
                required: true,
                serializedName: "type",
                type: {
                    name: "String"
                }
            },
            isRequired: {
                required: true,
                serializedName: "isRequired",
                type: {
                    name: "Boolean"
                }
            },
            enumValues: {
                required: true,
                serializedName: "enumValues",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            }
        }
    }
};
export var ApiV1JobTrigger = {
    serializedName: "ApiV1JobTrigger",
    type: {
        name: "Composite",
        className: "ApiV1JobTrigger",
        modelProperties: {
            executionId: {
                serializedName: "executionId",
                type: {
                    name: "Uuid"
                }
            },
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "String"
                }
            },
            nextRunDateTime: {
                serializedName: "nextRunDateTime",
                type: {
                    name: "DateTime"
                }
            },
            lastRunDateTime: {
                serializedName: "lastRunDateTime",
                type: {
                    name: "DateTime"
                }
            },
            lastErrorMessage: {
                serializedName: "lastErrorMessage",
                type: {
                    name: "String"
                }
            },
            status: {
                serializedName: "status",
                type: {
                    name: "String"
                }
            },
            isActive: {
                required: true,
                serializedName: "isActive",
                type: {
                    name: "Boolean"
                }
            },
            cronSchedule: {
                serializedName: "cronSchedule",
                type: {
                    name: "String"
                }
            },
            progressMessage: {
                serializedName: "progressMessage",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var ApiV1Job = {
    serializedName: "ApiV1Job",
    type: {
        name: "Composite",
        className: "ApiV1Job",
        modelProperties: {
            id: {
                serializedName: "id",
                type: {
                    name: "String"
                }
            },
            name: {
                required: true,
                serializedName: "name",
                type: {
                    name: "String"
                }
            },
            description: {
                serializedName: "description",
                type: {
                    name: "String"
                }
            },
            jobType: {
                serializedName: "jobType",
                type: {
                    name: "String"
                }
            },
            lastRunDateTimeUtc: {
                serializedName: "lastRunDateTimeUtc",
                type: {
                    name: "DateTime"
                }
            },
            nextRunDateTimeUtc: {
                serializedName: "nextRunDateTimeUtc",
                type: {
                    name: "DateTime"
                }
            },
            lastRunTriggerName: {
                serializedName: "lastRunTriggerName",
                type: {
                    name: "String"
                }
            },
            nextRunTriggerName: {
                serializedName: "nextRunTriggerName",
                type: {
                    name: "String"
                }
            },
            lastErrorMessage: {
                serializedName: "lastErrorMessage",
                type: {
                    name: "String"
                }
            },
            lastStatus: {
                serializedName: "lastStatus",
                type: {
                    name: "String"
                }
            },
            parameters: {
                required: true,
                serializedName: "parameters",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "JobParameterDescriptionModel"
                        }
                    }
                }
            },
            timestampDateTimeUtc: {
                required: true,
                serializedName: "timestampDateTimeUtc",
                type: {
                    name: "DateTime"
                }
            },
            triggers: {
                required: true,
                serializedName: "triggers",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ApiV1JobTrigger"
                        }
                    }
                }
            }
        }
    }
};
export var ApiV1JobList = {
    serializedName: "ApiV1JobList",
    type: {
        name: "Composite",
        className: "ApiV1JobList",
        modelProperties: {
            jobs: {
                serializedName: "jobs",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ApiV1Job"
                        }
                    }
                }
            },
            status: {
                required: true,
                serializedName: "status",
                type: {
                    name: "String"
                }
            },
            startupException: {
                serializedName: "startupException",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var JobParameterModel = {
    serializedName: "JobParameterModel",
    type: {
        name: "Composite",
        className: "JobParameterModel",
        modelProperties: {
            name: {
                required: true,
                serializedName: "name",
                type: {
                    name: "String"
                }
            },
            value: {
                serializedName: "value",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var JobStartModel = {
    serializedName: "JobStartModel",
    type: {
        name: "Composite",
        className: "JobStartModel",
        modelProperties: {
            parameters: {
                serializedName: "parameters",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "JobParameterModel"
                        }
                    }
                }
            }
        }
    }
};
export var JobDetailsModel = {
    serializedName: "JobDetailsModel",
    type: {
        name: "Composite",
        className: "JobDetailsModel",
        modelProperties: {
            name: {
                required: true,
                serializedName: "name",
                type: {
                    name: "String"
                }
            },
            isActive: {
                required: true,
                serializedName: "isActive",
                type: {
                    name: "Boolean"
                }
            },
            cronSchedule: {
                serializedName: "cronSchedule",
                type: {
                    name: "String"
                }
            },
            dateTimeSchedule: {
                serializedName: "dateTimeSchedule",
                type: {
                    name: "DateTime"
                }
            },
            parameters: {
                required: true,
                serializedName: "parameters",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "JobParameterDescriptionModel"
                        }
                    }
                }
            }
        }
    }
};
export var JobSaveParametersModel = {
    serializedName: "JobSaveParametersModel",
    type: {
        name: "Composite",
        className: "JobSaveParametersModel",
        modelProperties: {
            jobName: {
                required: true,
                serializedName: "jobName",
                type: {
                    name: "String"
                }
            },
            triggerName: {
                required: true,
                serializedName: "triggerName",
                type: {
                    name: "String"
                }
            },
            isActive: {
                required: true,
                serializedName: "isActive",
                type: {
                    name: "Boolean"
                }
            },
            cronSchedule: {
                serializedName: "cronSchedule",
                type: {
                    name: "String"
                }
            },
            dateTimeSchedule: {
                serializedName: "dateTimeSchedule",
                type: {
                    name: "DateTime"
                }
            },
            parameters: {
                serializedName: "parameters",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "JobParameterModel"
                        }
                    }
                }
            }
        }
    }
};
export var JobSaveParametersResult = {
    serializedName: "JobSaveParametersResult",
    type: {
        name: "Composite",
        className: "JobSaveParametersResult",
        modelProperties: {
            errors: {
                serializedName: "errors",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            hasErrors: {
                required: true,
                serializedName: "hasErrors",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var ExtendTimeLockModel = {
    serializedName: "ExtendTimeLockModel",
    type: {
        name: "Composite",
        className: "ExtendTimeLockModel",
        modelProperties: {
            lockedUntilDateTimeUtc: {
                serializedName: "lockedUntilDateTimeUtc",
                type: {
                    name: "DateTime"
                }
            }
        }
    }
};
export var LockCreateModel = {
    serializedName: "LockCreateModel",
    type: {
        name: "Composite",
        className: "LockCreateModel",
        modelProperties: {
            objectUniqueId: {
                required: true,
                serializedName: "objectUniqueId",
                type: {
                    name: "String"
                }
            },
            section: {
                serializedName: "section",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var LockModelWithStatusCurrentEditor = {
    serializedName: "LockModelWithStatus_currentEditor",
    type: {
        name: "Composite",
        className: "LockModelWithStatusCurrentEditor",
        modelProperties: __assign({}, PrincipalModel.type.modelProperties)
    }
};
export var LockModelWithStatus = {
    serializedName: "LockModelWithStatus",
    type: {
        name: "Composite",
        className: "LockModelWithStatus",
        modelProperties: {
            lockStatus: {
                required: true,
                serializedName: "lockStatus",
                type: {
                    name: "String"
                }
            },
            currentEditor: {
                serializedName: "currentEditor",
                type: {
                    name: "Composite",
                    className: "LockModelWithStatusCurrentEditor"
                }
            },
            lockedSinceDateTimeUtc: {
                serializedName: "lockedSinceDateTimeUtc",
                type: {
                    name: "DateTime"
                }
            },
            section: {
                required: true,
                serializedName: "section",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var ManualTaskDenyComment = {
    serializedName: "ManualTaskDenyComment",
    type: {
        name: "Composite",
        className: "ManualTaskDenyComment",
        modelProperties: {
            date: {
                required: true,
                serializedName: "date",
                type: {
                    name: "DateTime"
                }
            },
            principalId: {
                required: true,
                serializedName: "principalId",
                type: {
                    name: "Number"
                }
            },
            principalName: {
                serializedName: "principalName",
                type: {
                    name: "String"
                }
            },
            comment: {
                serializedName: "comment",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var ManualTaskModelCreatedBy = {
    serializedName: "ManualTaskModel_createdBy",
    type: {
        name: "Composite",
        className: "ManualTaskModelCreatedBy",
        modelProperties: __assign({}, LookupModel.type.modelProperties)
    }
};
export var ManualTaskModelClosedBy = {
    serializedName: "ManualTaskModel_closedBy",
    type: {
        name: "Composite",
        className: "ManualTaskModelClosedBy",
        modelProperties: __assign({}, LookupModel.type.modelProperties)
    }
};
export var ManualTaskModelProject = {
    serializedName: "ManualTaskModel_project",
    type: {
        name: "Composite",
        className: "ManualTaskModelProject",
        modelProperties: __assign({}, LookupModel.type.modelProperties)
    }
};
export var ManualTaskModelGate = {
    serializedName: "ManualTaskModel_gate",
    type: {
        name: "Composite",
        className: "ManualTaskModelGate",
        modelProperties: __assign({}, LookupModel.type.modelProperties)
    }
};
export var ManualTaskModelCheckListItem = {
    serializedName: "ManualTaskModel_checkListItem",
    type: {
        name: "Composite",
        className: "ManualTaskModelCheckListItem",
        modelProperties: __assign({}, LookupModel.type.modelProperties)
    }
};
export var ManualTaskModelAssignee = {
    serializedName: "ManualTaskModel_assignee",
    type: {
        name: "Composite",
        className: "ManualTaskModelAssignee",
        modelProperties: __assign({}, PrincipalModel.type.modelProperties)
    }
};
export var ManualTaskModelApprover = {
    serializedName: "ManualTaskModel_approver",
    type: {
        name: "Composite",
        className: "ManualTaskModelApprover",
        modelProperties: __assign({}, PrincipalModel.type.modelProperties)
    }
};
export var ManualTaskModel = {
    serializedName: "ManualTaskModel",
    type: {
        name: "Composite",
        className: "ManualTaskModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            title: {
                serializedName: "title",
                type: {
                    name: "String"
                }
            },
            description: {
                serializedName: "description",
                type: {
                    name: "String"
                }
            },
            dueDate: {
                required: true,
                serializedName: "dueDate",
                type: {
                    name: "DateTime"
                }
            },
            answer: {
                serializedName: "answer",
                type: {
                    name: "String"
                }
            },
            createdBy: {
                required: true,
                serializedName: "createdBy",
                type: {
                    name: "Composite",
                    className: "ManualTaskModelCreatedBy"
                }
            },
            closedBy: {
                serializedName: "closedBy",
                type: {
                    name: "Composite",
                    className: "ManualTaskModelClosedBy"
                }
            },
            project: {
                required: true,
                serializedName: "project",
                type: {
                    name: "Composite",
                    className: "ManualTaskModelProject"
                }
            },
            gate: {
                serializedName: "gate",
                type: {
                    name: "Composite",
                    className: "ManualTaskModelGate"
                }
            },
            checkListItem: {
                serializedName: "checkListItem",
                type: {
                    name: "Composite",
                    className: "ManualTaskModelCheckListItem"
                }
            },
            checklistId: {
                serializedName: "checklistId",
                type: {
                    name: "Number"
                }
            },
            projectId: {
                required: true,
                serializedName: "projectId",
                type: {
                    name: "Number"
                }
            },
            projectState: {
                required: true,
                serializedName: "projectState",
                type: {
                    name: "String"
                }
            },
            projectStatus: {
                required: true,
                serializedName: "projectStatus",
                type: {
                    name: "String"
                }
            },
            projectCodeName: {
                serializedName: "projectCodeName",
                type: {
                    name: "String"
                }
            },
            status: {
                required: true,
                serializedName: "status",
                type: {
                    name: "String"
                }
            },
            state: {
                required: true,
                serializedName: "state",
                type: {
                    name: "String"
                }
            },
            type: {
                required: true,
                serializedName: "type",
                type: {
                    name: "String"
                }
            },
            attachments: {
                serializedName: "attachments",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "DocumentModel"
                        }
                    }
                }
            },
            assignee: {
                required: true,
                serializedName: "assignee",
                type: {
                    name: "Composite",
                    className: "ManualTaskModelAssignee"
                }
            },
            approver: {
                required: true,
                serializedName: "approver",
                type: {
                    name: "Composite",
                    className: "ManualTaskModelApprover"
                }
            },
            uniqueId: {
                required: true,
                serializedName: "uniqueId",
                type: {
                    name: "String"
                }
            },
            denyComments: {
                serializedName: "denyComments",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ManualTaskDenyComment"
                        }
                    }
                }
            },
            canPrincipalUpdateMainPart: {
                required: true,
                serializedName: "canPrincipalUpdateMainPart",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalUpdateWorkerPart: {
                required: true,
                serializedName: "canPrincipalUpdateWorkerPart",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalApproveManualTask: {
                required: true,
                serializedName: "canPrincipalApproveManualTask",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var ManualTaskCreateModel = {
    serializedName: "ManualTaskCreateModel",
    type: {
        name: "Composite",
        className: "ManualTaskCreateModel",
        modelProperties: {
            projectId: {
                required: true,
                serializedName: "projectId",
                type: {
                    name: "Number"
                }
            },
            gateId: {
                serializedName: "gateId",
                type: {
                    name: "Number"
                }
            },
            checklistItemId: {
                serializedName: "checklistItemId",
                type: {
                    name: "Number"
                }
            },
            description: {
                serializedName: "description",
                type: {
                    name: "String"
                }
            },
            dueDate: {
                required: true,
                serializedName: "dueDate",
                type: {
                    name: "DateTime"
                }
            },
            assignedToPrincipalId: {
                required: true,
                serializedName: "assignedToPrincipalId",
                type: {
                    name: "Number"
                }
            },
            approverPrincipalId: {
                required: true,
                serializedName: "approverPrincipalId",
                type: {
                    name: "Number"
                }
            },
            comment: {
                serializedName: "comment",
                type: {
                    name: "String"
                }
            },
            title: {
                serializedName: "title",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var ManualTaskUpdateModel = {
    serializedName: "ManualTaskUpdateModel",
    type: {
        name: "Composite",
        className: "ManualTaskUpdateModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            gateId: {
                serializedName: "gateId",
                type: {
                    name: "Number"
                }
            },
            checklistItemId: {
                serializedName: "checklistItemId",
                type: {
                    name: "Number"
                }
            },
            description: {
                serializedName: "description",
                type: {
                    name: "String"
                }
            },
            dueDate: {
                required: true,
                serializedName: "dueDate",
                type: {
                    name: "DateTime"
                }
            },
            assignedToPrincipalId: {
                required: true,
                serializedName: "assignedToPrincipalId",
                type: {
                    name: "Number"
                }
            },
            approverPrincipalId: {
                required: true,
                serializedName: "approverPrincipalId",
                type: {
                    name: "Number"
                }
            },
            comment: {
                serializedName: "comment",
                type: {
                    name: "String"
                }
            },
            title: {
                serializedName: "title",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var GateMilestoneDeliverableModelMasterDeliverable = {
    serializedName: "GateMilestoneDeliverableModel_masterDeliverable",
    type: {
        name: "Composite",
        className: "GateMilestoneDeliverableModelMasterDeliverable",
        modelProperties: __assign({}, GateDeliverableModel.type.modelProperties)
    }
};
export var GateMilestoneDeliverableModel = {
    serializedName: "GateMilestoneDeliverableModel",
    type: {
        name: "Composite",
        className: "GateMilestoneDeliverableModel",
        modelProperties: {
            checkListItemType: {
                required: true,
                serializedName: "checkListItemType",
                type: {
                    name: "String"
                }
            },
            masterDeliverable: {
                serializedName: "masterDeliverable",
                type: {
                    name: "Composite",
                    className: "GateMilestoneDeliverableModelMasterDeliverable"
                }
            },
            keyDeliverables: {
                serializedName: "keyDeliverables",
                type: {
                    name: "String"
                }
            },
            hasDocuments: {
                required: true,
                serializedName: "hasDocuments",
                type: {
                    name: "Boolean"
                }
            },
            hasChildren: {
                required: true,
                serializedName: "hasChildren",
                type: {
                    name: "Boolean"
                }
            },
            taskId: {
                serializedName: "taskId",
                type: {
                    name: "Number"
                }
            },
            taskStatus: {
                serializedName: "taskStatus",
                type: {
                    name: "String"
                }
            },
            isGateEditable: {
                required: true,
                serializedName: "isGateEditable",
                type: {
                    name: "Boolean"
                }
            },
            projectManagerRating: {
                required: true,
                serializedName: "projectManagerRating",
                type: {
                    name: "String"
                }
            },
            projectManagerOriginalComment: {
                serializedName: "projectManagerOriginalComment",
                type: {
                    name: "String"
                }
            },
            canPrincipalUpdateProjectManagerPart: {
                required: true,
                serializedName: "canPrincipalUpdateProjectManagerPart",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalAddActionItem: {
                required: true,
                serializedName: "canPrincipalAddActionItem",
                type: {
                    name: "Boolean"
                }
            },
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            checkListId: {
                required: true,
                serializedName: "checkListId",
                type: {
                    name: "Number"
                }
            },
            code: {
                serializedName: "code",
                type: {
                    name: "String"
                }
            },
            question: {
                serializedName: "question",
                type: {
                    name: "String"
                }
            },
            guideline: {
                serializedName: "guideline",
                type: {
                    name: "String"
                }
            },
            functions: {
                required: true,
                serializedName: "functions",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "FunctionWithEditAbility"
                        }
                    }
                }
            },
            childCount: {
                serializedName: "childCount",
                type: {
                    name: "Number"
                }
            },
            isEnabled: {
                required: true,
                serializedName: "isEnabled",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var GateMilestoneQuestionModel = {
    serializedName: "GateMilestoneQuestionModel",
    type: {
        name: "Composite",
        className: "GateMilestoneQuestionModel",
        modelProperties: {
            checkListItemType: {
                required: true,
                serializedName: "checkListItemType",
                type: {
                    name: "String"
                }
            },
            taskId: {
                serializedName: "taskId",
                type: {
                    name: "Number"
                }
            },
            taskStatus: {
                serializedName: "taskStatus",
                type: {
                    name: "String"
                }
            },
            isGateEditable: {
                required: true,
                serializedName: "isGateEditable",
                type: {
                    name: "Boolean"
                }
            },
            projectManagerRating: {
                required: true,
                serializedName: "projectManagerRating",
                type: {
                    name: "String"
                }
            },
            projectManagerOriginalComment: {
                serializedName: "projectManagerOriginalComment",
                type: {
                    name: "String"
                }
            },
            canPrincipalUpdateProjectManagerPart: {
                required: true,
                serializedName: "canPrincipalUpdateProjectManagerPart",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalAddActionItem: {
                required: true,
                serializedName: "canPrincipalAddActionItem",
                type: {
                    name: "Boolean"
                }
            },
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            checkListId: {
                required: true,
                serializedName: "checkListId",
                type: {
                    name: "Number"
                }
            },
            code: {
                serializedName: "code",
                type: {
                    name: "String"
                }
            },
            question: {
                serializedName: "question",
                type: {
                    name: "String"
                }
            },
            guideline: {
                serializedName: "guideline",
                type: {
                    name: "String"
                }
            },
            functions: {
                required: true,
                serializedName: "functions",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "FunctionWithEditAbility"
                        }
                    }
                }
            },
            childCount: {
                serializedName: "childCount",
                type: {
                    name: "Number"
                }
            },
            isEnabled: {
                required: true,
                serializedName: "isEnabled",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var MeetingShortModel = {
    serializedName: "MeetingShortModel",
    type: {
        name: "Composite",
        className: "MeetingShortModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            gateName: {
                serializedName: "gateName",
                type: {
                    name: "String"
                }
            },
            projectId: {
                required: true,
                serializedName: "projectId",
                type: {
                    name: "Number"
                }
            },
            gateId: {
                required: true,
                serializedName: "gateId",
                type: {
                    name: "Number"
                }
            },
            subject: {
                required: true,
                serializedName: "subject",
                type: {
                    name: "String"
                }
            },
            startDateTime: {
                serializedName: "startDateTime",
                type: {
                    name: "DateTime"
                }
            },
            endDateTime: {
                serializedName: "endDateTime",
                type: {
                    name: "DateTime"
                }
            },
            meetingType: {
                required: true,
                serializedName: "meetingType",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var MeetingParticipantModel = {
    serializedName: "MeetingParticipantModel",
    type: {
        name: "Composite",
        className: "MeetingParticipantModel",
        modelProperties: {
            principalId: {
                required: true,
                serializedName: "principalId",
                type: {
                    name: "Number"
                }
            },
            principalDisplayName: {
                required: true,
                serializedName: "principalDisplayName",
                type: {
                    name: "String"
                }
            },
            principalFunction: {
                serializedName: "principalFunction",
                type: {
                    name: "String"
                }
            },
            isMandatory: {
                required: true,
                serializedName: "isMandatory",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var MeetingModel = {
    serializedName: "MeetingModel",
    type: {
        name: "Composite",
        className: "MeetingModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            gateName: {
                required: true,
                serializedName: "gateName",
                type: {
                    name: "String"
                }
            },
            attachments: {
                required: true,
                serializedName: "attachments",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "DocumentModel"
                        }
                    }
                }
            },
            participants: {
                required: true,
                serializedName: "participants",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "MeetingParticipantModel"
                        }
                    }
                }
            },
            canUpdateMeeting: {
                required: true,
                serializedName: "canUpdateMeeting",
                type: {
                    name: "Boolean"
                }
            },
            agenda: {
                serializedName: "agenda",
                type: {
                    name: "String"
                }
            },
            minutesOfMeeting: {
                serializedName: "minutesOfMeeting",
                type: {
                    name: "String"
                }
            },
            projectId: {
                required: true,
                serializedName: "projectId",
                type: {
                    name: "Number"
                }
            },
            gateId: {
                required: true,
                serializedName: "gateId",
                type: {
                    name: "Number"
                }
            },
            subject: {
                required: true,
                serializedName: "subject",
                type: {
                    name: "String"
                }
            },
            startDateTime: {
                serializedName: "startDateTime",
                type: {
                    name: "DateTime"
                }
            },
            endDateTime: {
                serializedName: "endDateTime",
                type: {
                    name: "DateTime"
                }
            },
            meetingType: {
                required: true,
                serializedName: "meetingType",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var MeetingUpdateModel = {
    serializedName: "MeetingUpdateModel",
    type: {
        name: "Composite",
        className: "MeetingUpdateModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            subject: {
                serializedName: "subject",
                type: {
                    name: "String"
                }
            },
            startDateTime: {
                serializedName: "startDateTime",
                type: {
                    name: "DateTime"
                }
            },
            endDateTime: {
                serializedName: "endDateTime",
                type: {
                    name: "DateTime"
                }
            },
            agenda: {
                serializedName: "agenda",
                type: {
                    name: "String"
                }
            },
            minutesOfMeeting: {
                serializedName: "minutesOfMeeting",
                type: {
                    name: "String"
                }
            },
            participantIds: {
                serializedName: "participantIds",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Number"
                        }
                    }
                }
            }
        }
    }
};
export var MFilesDeliverableSetExternalRestApiModel = {
    serializedName: "MFilesDeliverableSetExternalRestApiModel",
    type: {
        name: "Composite",
        className: "MFilesDeliverableSetExternalRestApiModel",
        modelProperties: {
            externalLink: {
                required: true,
                serializedName: "externalLink",
                type: {
                    name: "String"
                }
            },
            norrdProjectNumber: {
                required: true,
                serializedName: "norrdProjectNumber",
                type: {
                    name: "String"
                }
            },
            gateDisplayName: {
                required: true,
                serializedName: "gateDisplayName",
                type: {
                    name: "String"
                }
            },
            deliverableCode: {
                required: true,
                serializedName: "deliverableCode",
                type: {
                    name: "String"
                }
            },
            userEmail: {
                required: true,
                serializedName: "userEmail",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var MFilesDeliverableRestApiResponseModel = {
    serializedName: "MFilesDeliverableRestApiResponseModel",
    type: {
        name: "Composite",
        className: "MFilesDeliverableRestApiResponseModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            isSuccess: {
                required: true,
                serializedName: "isSuccess",
                type: {
                    name: "Boolean"
                }
            },
            errorReason: {
                serializedName: "errorReason",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var MFilesDeliverableResetExternalRestApiModel = {
    serializedName: "MFilesDeliverableResetExternalRestApiModel",
    type: {
        name: "Composite",
        className: "MFilesDeliverableResetExternalRestApiModel",
        modelProperties: {
            norrdProjectNumber: {
                required: true,
                serializedName: "norrdProjectNumber",
                type: {
                    name: "String"
                }
            },
            gateDisplayName: {
                required: true,
                serializedName: "gateDisplayName",
                type: {
                    name: "String"
                }
            },
            deliverableCode: {
                required: true,
                serializedName: "deliverableCode",
                type: {
                    name: "String"
                }
            },
            userEmail: {
                required: true,
                serializedName: "userEmail",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var MFilesDeliverableDenyApprovalRestApiModel = {
    serializedName: "MFilesDeliverableDenyApprovalRestApiModel",
    type: {
        name: "Composite",
        className: "MFilesDeliverableDenyApprovalRestApiModel",
        modelProperties: {
            message: {
                required: true,
                serializedName: "message",
                type: {
                    name: "String"
                }
            },
            norrdProjectNumber: {
                required: true,
                serializedName: "norrdProjectNumber",
                type: {
                    name: "String"
                }
            },
            gateDisplayName: {
                required: true,
                serializedName: "gateDisplayName",
                type: {
                    name: "String"
                }
            },
            deliverableCode: {
                required: true,
                serializedName: "deliverableCode",
                type: {
                    name: "String"
                }
            },
            userEmail: {
                required: true,
                serializedName: "userEmail",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var MFilesDeliverableSetInApprovalRestApiModel = {
    serializedName: "MFilesDeliverableSetInApprovalRestApiModel",
    type: {
        name: "Composite",
        className: "MFilesDeliverableSetInApprovalRestApiModel",
        modelProperties: {
            rating: {
                required: true,
                serializedName: "rating",
                type: {
                    name: "String"
                }
            },
            comment: {
                required: true,
                serializedName: "comment",
                type: {
                    name: "String"
                }
            },
            norrdProjectNumber: {
                required: true,
                serializedName: "norrdProjectNumber",
                type: {
                    name: "String"
                }
            },
            gateDisplayName: {
                required: true,
                serializedName: "gateDisplayName",
                type: {
                    name: "String"
                }
            },
            deliverableCode: {
                required: true,
                serializedName: "deliverableCode",
                type: {
                    name: "String"
                }
            },
            userEmail: {
                required: true,
                serializedName: "userEmail",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var MFilesDeliverableSetApprovedRestApiModel = {
    serializedName: "MFilesDeliverableSetApprovedRestApiModel",
    type: {
        name: "Composite",
        className: "MFilesDeliverableSetApprovedRestApiModel",
        modelProperties: {
            norrdProjectNumber: {
                required: true,
                serializedName: "norrdProjectNumber",
                type: {
                    name: "String"
                }
            },
            gateDisplayName: {
                required: true,
                serializedName: "gateDisplayName",
                type: {
                    name: "String"
                }
            },
            deliverableCode: {
                required: true,
                serializedName: "deliverableCode",
                type: {
                    name: "String"
                }
            },
            userEmail: {
                required: true,
                serializedName: "userEmail",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var MFilesDeliverableUndoApprovalRestApiModel = {
    serializedName: "MFilesDeliverableUndoApprovalRestApiModel",
    type: {
        name: "Composite",
        className: "MFilesDeliverableUndoApprovalRestApiModel",
        modelProperties: {
            message: {
                required: true,
                serializedName: "message",
                type: {
                    name: "String"
                }
            },
            norrdProjectNumber: {
                required: true,
                serializedName: "norrdProjectNumber",
                type: {
                    name: "String"
                }
            },
            gateDisplayName: {
                required: true,
                serializedName: "gateDisplayName",
                type: {
                    name: "String"
                }
            },
            deliverableCode: {
                required: true,
                serializedName: "deliverableCode",
                type: {
                    name: "String"
                }
            },
            userEmail: {
                required: true,
                serializedName: "userEmail",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var OrgUnitShortModel = {
    serializedName: "OrgUnitShortModel",
    type: {
        name: "Composite",
        className: "OrgUnitShortModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            uniqueId: {
                required: true,
                serializedName: "uniqueId",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var OrgUnitRestApiModel = {
    serializedName: "OrgUnitRestApiModel",
    type: {
        name: "Composite",
        className: "OrgUnitRestApiModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            uniqueId: {
                serializedName: "uniqueId",
                type: {
                    name: "String"
                }
            },
            displayName: {
                serializedName: "displayName",
                type: {
                    name: "String"
                }
            },
            codeName: {
                serializedName: "codeName",
                type: {
                    name: "String"
                }
            },
            isActive: {
                required: true,
                serializedName: "isActive",
                type: {
                    name: "Boolean"
                }
            },
            objectType: {
                serializedName: "objectType",
                type: {
                    name: "String"
                }
            },
            isRoot: {
                required: true,
                serializedName: "isRoot",
                type: {
                    name: "Boolean"
                }
            },
            isArtificial: {
                required: true,
                serializedName: "isArtificial",
                type: {
                    name: "Boolean"
                }
            },
            objectTypeDisplayName: {
                serializedName: "objectTypeDisplayName",
                type: {
                    name: "String"
                }
            },
            sortOrder: {
                required: true,
                serializedName: "sortOrder",
                type: {
                    name: "Number"
                }
            },
            modified: {
                required: true,
                serializedName: "modified",
                type: {
                    name: "DateTime"
                }
            },
            involveBUTecManager: {
                required: true,
                serializedName: "involveBUTecManager",
                type: {
                    name: "Boolean"
                }
            },
            techManagerId: {
                serializedName: "techManagerId",
                type: {
                    name: "Number"
                }
            },
            techManagerPrincipalDisplayName: {
                serializedName: "techManagerPrincipalDisplayName",
                type: {
                    name: "String"
                }
            },
            rdControllerId: {
                serializedName: "rdControllerId",
                type: {
                    name: "Number"
                }
            },
            rdControllerPrincipalDisplayName: {
                serializedName: "rdControllerPrincipalDisplayName",
                type: {
                    name: "String"
                }
            },
            testManagerId: {
                serializedName: "testManagerId",
                type: {
                    name: "Number"
                }
            },
            testManagerPrincipalDisplayName: {
                serializedName: "testManagerPrincipalDisplayName",
                type: {
                    name: "String"
                }
            },
            productManagerId: {
                serializedName: "productManagerId",
                type: {
                    name: "Number"
                }
            },
            productManagerPrincipalDisplayName: {
                serializedName: "productManagerPrincipalDisplayName",
                type: {
                    name: "String"
                }
            },
            virtOrgOwnerId: {
                serializedName: "virtOrgOwnerId",
                type: {
                    name: "Number"
                }
            },
            virtOrgOwnerPrincipalDisplayName: {
                serializedName: "virtOrgOwnerPrincipalDisplayName",
                type: {
                    name: "String"
                }
            },
            patentOfficerId: {
                serializedName: "patentOfficerId",
                type: {
                    name: "Number"
                }
            },
            patentOfficerPrincipalDisplayName: {
                serializedName: "patentOfficerPrincipalDisplayName",
                type: {
                    name: "String"
                }
            },
            parentOrgUnitId: {
                serializedName: "parentOrgUnitId",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export var PrincipalRestApiModel = {
    serializedName: "PrincipalRestApiModel",
    type: {
        name: "Composite",
        className: "PrincipalRestApiModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            uniqueId: {
                serializedName: "uniqueId",
                type: {
                    name: "String"
                }
            },
            email: {
                serializedName: "email",
                type: {
                    name: "String"
                }
            },
            displayName: {
                serializedName: "displayName",
                type: {
                    name: "String"
                }
            },
            codeName: {
                serializedName: "codeName",
                type: {
                    name: "String"
                }
            },
            isSystem: {
                required: true,
                serializedName: "isSystem",
                type: {
                    name: "Boolean"
                }
            },
            country: {
                serializedName: "country",
                type: {
                    name: "String"
                }
            },
            abbBaShortName: {
                serializedName: "abbBaShortName",
                type: {
                    name: "String"
                }
            },
            abbDivShortName: {
                serializedName: "abbDivShortName",
                type: {
                    name: "String"
                }
            },
            firstName: {
                serializedName: "firstName",
                type: {
                    name: "String"
                }
            },
            lastName: {
                serializedName: "lastName",
                type: {
                    name: "String"
                }
            },
            department: {
                serializedName: "department",
                type: {
                    name: "String"
                }
            },
            company: {
                serializedName: "company",
                type: {
                    name: "String"
                }
            },
            employeeNbr: {
                serializedName: "employeeNbr",
                type: {
                    name: "String"
                }
            },
            title: {
                serializedName: "title",
                type: {
                    name: "String"
                }
            },
            notesFullName: {
                serializedName: "notesFullName",
                type: {
                    name: "String"
                }
            },
            created: {
                required: true,
                serializedName: "created",
                type: {
                    name: "DateTime"
                }
            },
            createdByPrincipalId: {
                serializedName: "createdByPrincipalId",
                type: {
                    name: "Number"
                }
            },
            modified: {
                required: true,
                serializedName: "modified",
                type: {
                    name: "DateTime"
                }
            },
            modifiedByPrincipalId: {
                serializedName: "modifiedByPrincipalId",
                type: {
                    name: "Number"
                }
            },
            distinguishedName: {
                serializedName: "distinguishedName",
                type: {
                    name: "String"
                }
            },
            objectId: {
                serializedName: "objectId",
                type: {
                    name: "String"
                }
            },
            isInternal: {
                required: true,
                serializedName: "isInternal",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var ProjectShortModel = {
    serializedName: "ProjectShortModel",
    type: {
        name: "Composite",
        className: "ProjectShortModel",
        modelProperties: {
            leadResponsibleOrgUnitDisplayText: {
                required: true,
                serializedName: "leadResponsibleOrgUnitDisplayText",
                type: {
                    name: "String"
                }
            },
            responsibleProjectLeader: {
                required: true,
                serializedName: "responsibleProjectLeader",
                type: {
                    name: "String"
                }
            },
            isProjectFavorite: {
                required: true,
                serializedName: "isProjectFavorite",
                type: {
                    name: "Boolean"
                }
            },
            responsibleOrgUnitId: {
                required: true,
                serializedName: "responsibleOrgUnitId",
                type: {
                    name: "Number"
                }
            },
            gates: {
                serializedName: "gates",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GateModel"
                        }
                    }
                }
            },
            lastLinkOnProject: {
                serializedName: "lastLinkOnProject",
                type: {
                    name: "String"
                }
            },
            state: {
                required: true,
                serializedName: "state",
                type: {
                    name: "String"
                }
            },
            status: {
                serializedName: "status",
                type: {
                    name: "String"
                }
            },
            gateActionItemLinks: {
                serializedName: "gateActionItemLinks",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GateLinkModel"
                        }
                    }
                }
            },
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            displayName: {
                required: true,
                serializedName: "displayName",
                type: {
                    name: "String"
                }
            },
            codeName: {
                required: true,
                serializedName: "codeName",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var ProjectCreationDataFromNorrdModel = {
    serializedName: "ProjectCreationDataFromNorrdModel",
    type: {
        name: "Composite",
        className: "ProjectCreationDataFromNorrdModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            projectNumber: {
                required: true,
                serializedName: "projectNumber",
                type: {
                    name: "String"
                }
            },
            projectName: {
                required: true,
                serializedName: "projectName",
                type: {
                    name: "String"
                }
            },
            gateModel: {
                required: true,
                serializedName: "gateModel",
                type: {
                    name: "String"
                }
            },
            leadResponsibleOrgUnitDisplayText: {
                required: true,
                serializedName: "leadResponsibleOrgUnitDisplayText",
                type: {
                    name: "String"
                }
            },
            leadResponsibleOrgUnitId: {
                required: true,
                serializedName: "leadResponsibleOrgUnitId",
                type: {
                    name: "Number"
                }
            },
            plannedStartDate: {
                serializedName: "plannedStartDate",
                type: {
                    name: "DateTime"
                }
            },
            plannedEndDate: {
                serializedName: "plannedEndDate",
                type: {
                    name: "DateTime"
                }
            },
            totalBudget: {
                serializedName: "totalBudget",
                type: {
                    name: "Number"
                }
            },
            totalBudgetForecast: {
                serializedName: "totalBudgetForecast",
                type: {
                    name: "Number"
                }
            },
            norrdProjectLink: {
                serializedName: "norrdProjectLink",
                type: {
                    name: "String"
                }
            },
            securityLevel: {
                required: true,
                serializedName: "securityLevel",
                type: {
                    name: "String"
                }
            },
            projectLeader: {
                serializedName: "projectLeader",
                type: {
                    name: "String"
                }
            },
            ignored: {
                required: true,
                serializedName: "ignored",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var ProjectDetailsModelLeaderPrincipalModel = {
    serializedName: "ProjectDetailsModel_leaderPrincipalModel",
    type: {
        name: "Composite",
        className: "ProjectDetailsModelLeaderPrincipalModel",
        modelProperties: __assign({}, PrincipalModel.type.modelProperties)
    }
};
export var ProjectDetailsModel = {
    serializedName: "ProjectDetailsModel",
    type: {
        name: "Composite",
        className: "ProjectDetailsModel",
        modelProperties: {
            allowedOnlyActiveCategories: {
                required: true,
                serializedName: "allowedOnlyActiveCategories",
                type: {
                    name: "Boolean"
                }
            },
            plannedStartDate: {
                required: true,
                serializedName: "plannedStartDate",
                type: {
                    name: "DateTime"
                }
            },
            plannedEndDate: {
                required: true,
                serializedName: "plannedEndDate",
                type: {
                    name: "DateTime"
                }
            },
            securityLevel: {
                required: true,
                serializedName: "securityLevel",
                type: {
                    name: "String"
                }
            },
            category: {
                required: true,
                serializedName: "category",
                type: {
                    name: "String"
                }
            },
            totalBudget: {
                required: true,
                serializedName: "totalBudget",
                type: {
                    name: "Number"
                }
            },
            totalBudgetForecast: {
                serializedName: "totalBudgetForecast",
                type: {
                    name: "Number"
                }
            },
            projectState: {
                required: true,
                serializedName: "projectState",
                type: {
                    name: "String"
                }
            },
            leaderPrincipalModel: {
                serializedName: "leaderPrincipalModel",
                type: {
                    name: "Composite",
                    className: "ProjectDetailsModelLeaderPrincipalModel"
                }
            },
            norrdProjectLink: {
                serializedName: "norrdProjectLink",
                type: {
                    name: "String"
                }
            },
            uniqueId: {
                serializedName: "uniqueId",
                type: {
                    name: "String"
                }
            },
            gatesMaxNumber: {
                required: true,
                serializedName: "gatesMaxNumber",
                type: {
                    name: "Number"
                }
            },
            templateId: {
                serializedName: "templateId",
                type: {
                    name: "Number"
                }
            },
            canPrincipalInitFromTemplate: {
                required: true,
                serializedName: "canPrincipalInitFromTemplate",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalRemoveProject: {
                required: true,
                serializedName: "canPrincipalRemoveProject",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalAddGate: {
                required: true,
                serializedName: "canPrincipalAddGate",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalManipulateWithReader: {
                required: true,
                serializedName: "canPrincipalManipulateWithReader",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalRedoGate: {
                required: true,
                serializedName: "canPrincipalRedoGate",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalUpdateGeneralInfo: {
                required: true,
                serializedName: "canPrincipalUpdateGeneralInfo",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalAddManualTask: {
                required: true,
                serializedName: "canPrincipalAddManualTask",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalUpdateStatus: {
                required: true,
                serializedName: "canPrincipalUpdateStatus",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalRollbackStatus: {
                required: true,
                serializedName: "canPrincipalRollbackStatus",
                type: {
                    name: "Boolean"
                }
            },
            isNotificationsEnabled: {
                required: true,
                serializedName: "isNotificationsEnabled",
                type: {
                    name: "Boolean"
                }
            },
            needToHideNAItems: {
                required: true,
                serializedName: "needToHideNAItems",
                type: {
                    name: "Boolean"
                }
            },
            needToShowRedDotDeliverable: {
                serializedName: "needToShowRedDotDeliverable",
                type: {
                    name: "Boolean"
                }
            },
            leadResponsibleOrgUnitDisplayText: {
                required: true,
                serializedName: "leadResponsibleOrgUnitDisplayText",
                type: {
                    name: "String"
                }
            },
            responsibleProjectLeader: {
                required: true,
                serializedName: "responsibleProjectLeader",
                type: {
                    name: "String"
                }
            },
            isProjectFavorite: {
                required: true,
                serializedName: "isProjectFavorite",
                type: {
                    name: "Boolean"
                }
            },
            responsibleOrgUnitId: {
                required: true,
                serializedName: "responsibleOrgUnitId",
                type: {
                    name: "Number"
                }
            },
            gates: {
                serializedName: "gates",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GateModel"
                        }
                    }
                }
            },
            lastLinkOnProject: {
                serializedName: "lastLinkOnProject",
                type: {
                    name: "String"
                }
            },
            state: {
                required: true,
                serializedName: "state",
                type: {
                    name: "String"
                }
            },
            status: {
                serializedName: "status",
                type: {
                    name: "String"
                }
            },
            gateActionItemLinks: {
                serializedName: "gateActionItemLinks",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GateLinkModel"
                        }
                    }
                }
            },
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            displayName: {
                required: true,
                serializedName: "displayName",
                type: {
                    name: "String"
                }
            },
            codeName: {
                required: true,
                serializedName: "codeName",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var GateLookupModel = {
    serializedName: "GateLookupModel",
    type: {
        name: "Composite",
        className: "GateLookupModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            displayName: {
                required: true,
                serializedName: "displayName",
                type: {
                    name: "String"
                }
            },
            forecastDate: {
                required: true,
                serializedName: "forecastDate",
                type: {
                    name: "DateTime"
                }
            }
        }
    }
};
export var ProjectGatesModel = {
    serializedName: "ProjectGatesModel",
    type: {
        name: "Composite",
        className: "ProjectGatesModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            displayName: {
                required: true,
                serializedName: "displayName",
                type: {
                    name: "String"
                }
            },
            gates: {
                serializedName: "gates",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GateLookupModel"
                        }
                    }
                }
            }
        }
    }
};
export var ProjectInfoModel = {
    serializedName: "ProjectInfoModel",
    type: {
        name: "Composite",
        className: "ProjectInfoModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            uniqueId: {
                required: true,
                serializedName: "uniqueId",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var ProjectCreateModel = {
    serializedName: "ProjectCreateModel",
    type: {
        name: "Composite",
        className: "ProjectCreateModel",
        modelProperties: {
            displayName: {
                serializedName: "displayName",
                type: {
                    name: "String"
                }
            },
            responsibleOrgUnitId: {
                required: true,
                serializedName: "responsibleOrgUnitId",
                type: {
                    name: "Number"
                }
            },
            leaderPrincipalId: {
                required: true,
                serializedName: "leaderPrincipalId",
                type: {
                    name: "Number"
                }
            },
            plannedStartDate: {
                required: true,
                serializedName: "plannedStartDate",
                type: {
                    name: "DateTime"
                }
            },
            plannedEndDate: {
                required: true,
                serializedName: "plannedEndDate",
                type: {
                    name: "DateTime"
                }
            },
            securityLevel: {
                required: true,
                serializedName: "securityLevel",
                type: {
                    name: "String"
                }
            },
            category: {
                required: true,
                serializedName: "category",
                type: {
                    name: "String"
                }
            },
            totalBudget: {
                required: true,
                serializedName: "totalBudget",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export var ProjectCreateResponseModel = {
    serializedName: "ProjectCreateResponseModel",
    type: {
        name: "Composite",
        className: "ProjectCreateResponseModel",
        modelProperties: {
            codeName: {
                serializedName: "codeName",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var ProjectUpdateModel = {
    serializedName: "ProjectUpdateModel",
    type: {
        name: "Composite",
        className: "ProjectUpdateModel",
        modelProperties: {
            projectId: {
                required: true,
                serializedName: "projectId",
                type: {
                    name: "Number"
                }
            },
            displayName: {
                serializedName: "displayName",
                type: {
                    name: "String"
                }
            },
            responsibleOrgUnitId: {
                required: true,
                serializedName: "responsibleOrgUnitId",
                type: {
                    name: "Number"
                }
            },
            leaderPrincipalId: {
                required: true,
                serializedName: "leaderPrincipalId",
                type: {
                    name: "Number"
                }
            },
            plannedStartDate: {
                required: true,
                serializedName: "plannedStartDate",
                type: {
                    name: "DateTime"
                }
            },
            plannedEndDate: {
                required: true,
                serializedName: "plannedEndDate",
                type: {
                    name: "DateTime"
                }
            },
            securityLevel: {
                required: true,
                serializedName: "securityLevel",
                type: {
                    name: "String"
                }
            },
            category: {
                required: true,
                serializedName: "category",
                type: {
                    name: "String"
                }
            },
            totalBudget: {
                required: true,
                serializedName: "totalBudget",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export var ProjectSearchModel = {
    serializedName: "ProjectSearchModel",
    type: {
        name: "Composite",
        className: "ProjectSearchModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            displayName: {
                required: true,
                serializedName: "displayName",
                type: {
                    name: "String"
                }
            },
            codeName: {
                required: true,
                serializedName: "codeName",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var ProjectSynchronizationWithNorrdResult = {
    serializedName: "ProjectSynchronizationWithNorrdResult",
    type: {
        name: "Composite",
        className: "ProjectSynchronizationWithNorrdResult",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            result: {
                required: true,
                serializedName: "result",
                type: {
                    name: "String"
                }
            },
            resultMessage: {
                required: true,
                serializedName: "resultMessage",
                type: {
                    name: "String"
                }
            },
            projectLeaderDisplayName: {
                serializedName: "projectLeaderDisplayName",
                type: {
                    name: "String"
                }
            },
            projectAuthorDisplayNames: {
                serializedName: "projectAuthorDisplayNames",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            },
            projectReaderDisplayNames: {
                serializedName: "projectReaderDisplayNames",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "String"
                        }
                    }
                }
            }
        }
    }
};
export var ProjectDocumentModel = {
    serializedName: "ProjectDocumentModel",
    type: {
        name: "Composite",
        className: "ProjectDocumentModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            gateId: {
                serializedName: "gateId",
                type: {
                    name: "Number"
                }
            },
            gateDisplayName: {
                serializedName: "gateDisplayName",
                type: {
                    name: "String"
                }
            },
            documentCategoryId: {
                serializedName: "documentCategoryId",
                type: {
                    name: "Number"
                }
            },
            documentIsDeleted: {
                required: true,
                serializedName: "documentIsDeleted",
                type: {
                    name: "Boolean"
                }
            },
            documentCategoryName: {
                serializedName: "documentCategoryName",
                type: {
                    name: "String"
                }
            },
            fileInformationId: {
                serializedName: "fileInformationId",
                type: {
                    name: "Number"
                }
            },
            originalFileName: {
                serializedName: "originalFileName",
                type: {
                    name: "String"
                }
            },
            name: {
                serializedName: "name",
                type: {
                    name: "String"
                }
            },
            version: {
                serializedName: "version",
                type: {
                    name: "Number"
                }
            },
            comment: {
                serializedName: "comment",
                type: {
                    name: "String"
                }
            },
            uploadedDate: {
                serializedName: "uploadedDate",
                type: {
                    name: "DateTime"
                }
            },
            uploadedBy: {
                serializedName: "uploadedBy",
                type: {
                    name: "String"
                }
            },
            canPrincipalUpdateProjectDocument: {
                required: true,
                serializedName: "canPrincipalUpdateProjectDocument",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalRestoreProjectDocument: {
                required: true,
                serializedName: "canPrincipalRestoreProjectDocument",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var ProjectDocumentsModel = {
    serializedName: "ProjectDocumentsModel",
    type: {
        name: "Composite",
        className: "ProjectDocumentsModel",
        modelProperties: {
            projectId: {
                required: true,
                serializedName: "projectId",
                type: {
                    name: "Number"
                }
            },
            projectCode: {
                serializedName: "projectCode",
                type: {
                    name: "String"
                }
            },
            projectDisplayName: {
                serializedName: "projectDisplayName",
                type: {
                    name: "String"
                }
            },
            documents: {
                serializedName: "documents",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ProjectDocumentModel"
                        }
                    }
                }
            },
            showDocumentCategoryWarning: {
                required: true,
                serializedName: "showDocumentCategoryWarning",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalManageProjectDocumentCategory: {
                required: true,
                serializedName: "canPrincipalManageProjectDocumentCategory",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalCreateProjectDocument: {
                required: true,
                serializedName: "canPrincipalCreateProjectDocument",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var UpdateProjectDocumentFileCommentModel = {
    serializedName: "UpdateProjectDocumentFileCommentModel",
    type: {
        name: "Composite",
        className: "UpdateProjectDocumentFileCommentModel",
        modelProperties: {
            projectDocumentId: {
                required: true,
                serializedName: "projectDocumentId",
                type: {
                    name: "Number"
                }
            },
            fileInformationId: {
                required: true,
                serializedName: "fileInformationId",
                type: {
                    name: "Number"
                }
            },
            comment: {
                serializedName: "comment",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var UpdateProjectDocumentFileNameModel = {
    serializedName: "UpdateProjectDocumentFileNameModel",
    type: {
        name: "Composite",
        className: "UpdateProjectDocumentFileNameModel",
        modelProperties: {
            projectDocumentId: {
                required: true,
                serializedName: "projectDocumentId",
                type: {
                    name: "Number"
                }
            },
            fileInformationId: {
                required: true,
                serializedName: "fileInformationId",
                type: {
                    name: "Number"
                }
            },
            fileName: {
                serializedName: "fileName",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var RestoreProjectDocumentFileModel = {
    serializedName: "RestoreProjectDocumentFileModel",
    type: {
        name: "Composite",
        className: "RestoreProjectDocumentFileModel",
        modelProperties: {
            projectDocumentId: {
                required: true,
                serializedName: "projectDocumentId",
                type: {
                    name: "Number"
                }
            },
            fileInformationId: {
                required: true,
                serializedName: "fileInformationId",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export var UpdateProjectDocumentCategoryModel = {
    serializedName: "UpdateProjectDocumentCategoryModel",
    type: {
        name: "Composite",
        className: "UpdateProjectDocumentCategoryModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            documentCategoryId: {
                serializedName: "documentCategoryId",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export var UpdateProjectDocumentGateModel = {
    serializedName: "UpdateProjectDocumentGateModel",
    type: {
        name: "Composite",
        className: "UpdateProjectDocumentGateModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            gateId: {
                serializedName: "gateId",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export var ProjectDocumentFileModel = {
    serializedName: "ProjectDocumentFileModel",
    type: {
        name: "Composite",
        className: "ProjectDocumentFileModel",
        modelProperties: {
            fileInformationId: {
                required: true,
                serializedName: "fileInformationId",
                type: {
                    name: "Number"
                }
            },
            originalFileName: {
                serializedName: "originalFileName",
                type: {
                    name: "String"
                }
            },
            documentFileIsDeleted: {
                required: true,
                serializedName: "documentFileIsDeleted",
                type: {
                    name: "Boolean"
                }
            },
            name: {
                serializedName: "name",
                type: {
                    name: "String"
                }
            },
            version: {
                required: true,
                serializedName: "version",
                type: {
                    name: "Number"
                }
            },
            comment: {
                serializedName: "comment",
                type: {
                    name: "String"
                }
            },
            uploadedDate: {
                required: true,
                serializedName: "uploadedDate",
                type: {
                    name: "DateTime"
                }
            },
            uploadedBy: {
                serializedName: "uploadedBy",
                type: {
                    name: "String"
                }
            },
            canPrincipalManageFile: {
                required: true,
                serializedName: "canPrincipalManageFile",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalDeleteFile: {
                required: true,
                serializedName: "canPrincipalDeleteFile",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalRestoreFile: {
                required: true,
                serializedName: "canPrincipalRestoreFile",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var ProjectDocumentFilesModel = {
    serializedName: "ProjectDocumentFilesModel",
    type: {
        name: "Composite",
        className: "ProjectDocumentFilesModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            projectId: {
                required: true,
                serializedName: "projectId",
                type: {
                    name: "Number"
                }
            },
            categoryId: {
                serializedName: "categoryId",
                type: {
                    name: "Number"
                }
            },
            categoryName: {
                serializedName: "categoryName",
                type: {
                    name: "String"
                }
            },
            files: {
                serializedName: "files",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "ProjectDocumentFileModel"
                        }
                    }
                }
            }
        }
    }
};
export var ProjectDocumentFileInfosModel = {
    serializedName: "ProjectDocumentFileInfosModel",
    type: {
        name: "Composite",
        className: "ProjectDocumentFileInfosModel",
        modelProperties: {
            canPrincipalManageDocument: {
                required: true,
                serializedName: "canPrincipalManageDocument",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalCreateDocument: {
                required: true,
                serializedName: "canPrincipalCreateDocument",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var ProjectReaderModel = {
    serializedName: "ProjectReaderModel",
    type: {
        name: "Composite",
        className: "ProjectReaderModel",
        modelProperties: {
            projectId: {
                required: true,
                serializedName: "projectId",
                type: {
                    name: "Number"
                }
            },
            functionsCodes: {
                serializedName: "functionsCodes",
                type: {
                    name: "String"
                }
            },
            assignToNames: {
                serializedName: "assignToNames",
                type: {
                    name: "String"
                }
            },
            principalId: {
                required: true,
                serializedName: "principalId",
                type: {
                    name: "Number"
                }
            },
            principalDisplayName: {
                required: true,
                serializedName: "principalDisplayName",
                type: {
                    name: "String"
                }
            },
            principalCode: {
                required: true,
                serializedName: "principalCode",
                type: {
                    name: "String"
                }
            },
            canPrincipalManipulateWithReader: {
                required: true,
                serializedName: "canPrincipalManipulateWithReader",
                type: {
                    name: "Boolean"
                }
            },
            isSystem: {
                required: true,
                serializedName: "isSystem",
                type: {
                    name: "Boolean"
                }
            },
            isProjectMember: {
                required: true,
                serializedName: "isProjectMember",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var ProjectReaderCreateModel = {
    serializedName: "ProjectReaderCreateModel",
    type: {
        name: "Composite",
        className: "ProjectReaderCreateModel",
        modelProperties: {
            projectId: {
                required: true,
                serializedName: "projectId",
                type: {
                    name: "Number"
                }
            },
            principalId: {
                required: true,
                serializedName: "principalId",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export var ProjectRestApiModel = {
    serializedName: "ProjectRestApiModel",
    type: {
        name: "Composite",
        className: "ProjectRestApiModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            uniqueId: {
                serializedName: "uniqueId",
                type: {
                    name: "String"
                }
            },
            isEnabled: {
                required: true,
                serializedName: "isEnabled",
                type: {
                    name: "Boolean"
                }
            },
            isDeleted: {
                required: true,
                serializedName: "isDeleted",
                type: {
                    name: "Boolean"
                }
            },
            displayName: {
                serializedName: "displayName",
                type: {
                    name: "String"
                }
            },
            gateModel: {
                required: true,
                serializedName: "gateModel",
                type: {
                    name: "String"
                }
            },
            responsibleOrgUnitId: {
                required: true,
                serializedName: "responsibleOrgUnitId",
                type: {
                    name: "Number"
                }
            },
            responsibleOrgUnitDisplayName: {
                serializedName: "responsibleOrgUnitDisplayName",
                type: {
                    name: "String"
                }
            },
            projectTemplateId: {
                serializedName: "projectTemplateId",
                type: {
                    name: "Number"
                }
            },
            projectTemplateDisplayName: {
                serializedName: "projectTemplateDisplayName",
                type: {
                    name: "String"
                }
            },
            plannedStartDate: {
                required: true,
                serializedName: "plannedStartDate",
                type: {
                    name: "DateTime"
                }
            },
            plannedEndDate: {
                required: true,
                serializedName: "plannedEndDate",
                type: {
                    name: "DateTime"
                }
            },
            totalBudget: {
                required: true,
                serializedName: "totalBudget",
                type: {
                    name: "Number"
                }
            },
            totalBudgetForecast: {
                serializedName: "totalBudgetForecast",
                type: {
                    name: "Number"
                }
            },
            securityLevel: {
                required: true,
                serializedName: "securityLevel",
                type: {
                    name: "String"
                }
            },
            ippmProjectId: {
                serializedName: "ippmProjectId",
                type: {
                    name: "String"
                }
            },
            leaderPrincipalId: {
                required: true,
                serializedName: "leaderPrincipalId",
                type: {
                    name: "Number"
                }
            },
            leaderPrincipalDisplayName: {
                serializedName: "leaderPrincipalDisplayName",
                type: {
                    name: "String"
                }
            },
            norrdId: {
                serializedName: "norrdId",
                type: {
                    name: "Number"
                }
            },
            category: {
                required: true,
                serializedName: "category",
                type: {
                    name: "String"
                }
            },
            deleted: {
                serializedName: "deleted",
                type: {
                    name: "DateTime"
                }
            },
            created: {
                required: true,
                serializedName: "created",
                type: {
                    name: "DateTime"
                }
            },
            norrdLeaderPrincipalCodeName: {
                serializedName: "norrdLeaderPrincipalCodeName",
                type: {
                    name: "String"
                }
            },
            gatesMaxNumber: {
                required: true,
                serializedName: "gatesMaxNumber",
                type: {
                    name: "Number"
                }
            },
            projectLifecycleStatus: {
                required: true,
                serializedName: "projectLifecycleStatus",
                type: {
                    name: "String"
                }
            },
            okComment: {
                serializedName: "okComment",
                type: {
                    name: "String"
                }
            },
            sendConfigurableMessages: {
                required: true,
                serializedName: "sendConfigurableMessages",
                type: {
                    name: "Boolean"
                }
            },
            codeName: {
                serializedName: "codeName",
                type: {
                    name: "String"
                }
            },
            state: {
                required: true,
                serializedName: "state",
                type: {
                    name: "String"
                }
            },
            lastNorrdSynchronizationDate: {
                serializedName: "lastNorrdSynchronizationDate",
                type: {
                    name: "DateTime"
                }
            },
            status: {
                required: true,
                serializedName: "status",
                type: {
                    name: "String"
                }
            },
            url: {
                serializedName: "url",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var ProjectShortInfoRestApiModel = {
    serializedName: "ProjectShortInfoRestApiModel",
    type: {
        name: "Composite",
        className: "ProjectShortInfoRestApiModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            displayName: {
                required: true,
                serializedName: "displayName",
                type: {
                    name: "String"
                }
            },
            codeName: {
                required: true,
                serializedName: "codeName",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var RelatedFunctionRestApiModel = {
    serializedName: "RelatedFunctionRestApiModel",
    type: {
        name: "Composite",
        className: "RelatedFunctionRestApiModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            uniqueId: {
                serializedName: "uniqueId",
                type: {
                    name: "String"
                }
            },
            functionId: {
                required: true,
                serializedName: "functionId",
                type: {
                    name: "Number"
                }
            },
            functionName: {
                serializedName: "functionName",
                type: {
                    name: "String"
                }
            },
            principalId: {
                serializedName: "principalId",
                type: {
                    name: "Number"
                }
            },
            principalIdDisplayName: {
                serializedName: "principalIdDisplayName",
                type: {
                    name: "String"
                }
            },
            isEnabled: {
                required: true,
                serializedName: "isEnabled",
                type: {
                    name: "Boolean"
                }
            },
            isDeleted: {
                required: true,
                serializedName: "isDeleted",
                type: {
                    name: "Boolean"
                }
            },
            projectId: {
                required: true,
                serializedName: "projectId",
                type: {
                    name: "Number"
                }
            },
            projectCodeName: {
                serializedName: "projectCodeName",
                type: {
                    name: "String"
                }
            },
            projectDisplayName: {
                serializedName: "projectDisplayName",
                type: {
                    name: "String"
                }
            },
            gateId: {
                required: true,
                serializedName: "gateId",
                type: {
                    name: "Number"
                }
            },
            gateDisplayName: {
                serializedName: "gateDisplayName",
                type: {
                    name: "String"
                }
            },
            relatedToDisplayText: {
                serializedName: "relatedToDisplayText",
                type: {
                    name: "String"
                }
            },
            isApprover: {
                required: true,
                serializedName: "isApprover",
                type: {
                    name: "Boolean"
                }
            },
            isMandatory: {
                required: true,
                serializedName: "isMandatory",
                type: {
                    name: "Boolean"
                }
            },
            url: {
                serializedName: "url",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var AppSecurityModelCurrentPrincipal = {
    serializedName: "AppSecurityModel_currentPrincipal",
    type: {
        name: "Composite",
        className: "AppSecurityModelCurrentPrincipal",
        modelProperties: __assign({}, PrincipalModel.type.modelProperties)
    }
};
export var AppSecurityModel = {
    serializedName: "AppSecurityModel",
    type: {
        name: "Composite",
        className: "AppSecurityModel",
        modelProperties: {
            canPrincipalViewJobList: {
                required: true,
                serializedName: "canPrincipalViewJobList",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalViewTemplates: {
                required: true,
                serializedName: "canPrincipalViewTemplates",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalViewHealthBoard: {
                required: true,
                serializedName: "canPrincipalViewHealthBoard",
                type: {
                    name: "Boolean"
                }
            },
            canPrincipalImpersonate: {
                required: true,
                serializedName: "canPrincipalImpersonate",
                type: {
                    name: "Boolean"
                }
            },
            hasPrincipalAdminAccess: {
                required: true,
                serializedName: "hasPrincipalAdminAccess",
                type: {
                    name: "Boolean"
                }
            },
            currentPrincipal: {
                serializedName: "currentPrincipal",
                type: {
                    name: "Composite",
                    className: "AppSecurityModelCurrentPrincipal"
                }
            }
        }
    }
};
export var ObjectRoleInfo = {
    serializedName: "ObjectRoleInfo",
    type: {
        name: "Composite",
        className: "ObjectRoleInfo",
        modelProperties: {
            objectUniqueId: {
                serializedName: "objectUniqueId",
                type: {
                    name: "String"
                }
            },
            originObjectUniqueId: {
                serializedName: "originObjectUniqueId",
                type: {
                    name: "String"
                }
            },
            originObjectType: {
                serializedName: "originObjectType",
                type: {
                    name: "String"
                }
            },
            originObjectTypeDisplayName: {
                serializedName: "originObjectTypeDisplayName",
                type: {
                    name: "String"
                }
            },
            originObjectTypeGroupName: {
                serializedName: "originObjectTypeGroupName",
                type: {
                    name: "String"
                }
            },
            roleId: {
                required: true,
                serializedName: "roleId",
                type: {
                    name: "Number"
                }
            },
            roleCodeName: {
                serializedName: "roleCodeName",
                type: {
                    name: "String"
                }
            },
            roleDisplayName: {
                serializedName: "roleDisplayName",
                type: {
                    name: "String"
                }
            },
            roleMembersCount: {
                required: true,
                serializedName: "roleMembersCount",
                type: {
                    name: "Number"
                }
            },
            sort: {
                required: true,
                serializedName: "sort",
                type: {
                    name: "Number"
                }
            },
            modalInfoBoxLink: {
                serializedName: "modalInfoBoxLink",
                type: {
                    name: "String"
                }
            },
            uniqueKey: {
                readOnly: true,
                serializedName: "uniqueKey",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var RoleMemberModel = {
    serializedName: "RoleMemberModel",
    type: {
        name: "Composite",
        className: "RoleMemberModel",
        modelProperties: {
            objectUniqueId: {
                serializedName: "objectUniqueId",
                type: {
                    name: "String"
                }
            },
            roleId: {
                required: true,
                serializedName: "roleId",
                type: {
                    name: "Number"
                }
            },
            principalId: {
                required: true,
                serializedName: "principalId",
                type: {
                    name: "Number"
                }
            },
            principalEmail: {
                serializedName: "principalEmail",
                type: {
                    name: "String"
                }
            },
            principalCodeName: {
                serializedName: "principalCodeName",
                type: {
                    name: "String"
                }
            },
            principalDisplayName: {
                serializedName: "principalDisplayName",
                type: {
                    name: "String"
                }
            },
            canDelete: {
                required: true,
                serializedName: "canDelete",
                type: {
                    name: "Boolean"
                }
            }
        }
    }
};
export var PrincipalRoleItem = {
    serializedName: "PrincipalRoleItem",
    type: {
        name: "Composite",
        className: "PrincipalRoleItem",
        modelProperties: {
            objectUid: {
                serializedName: "objectUid",
                type: {
                    name: "String"
                }
            },
            roleName: {
                serializedName: "roleName",
                type: {
                    name: "String"
                }
            },
            link: {
                serializedName: "link",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var PrincipalInRoleOnObjectItem = {
    serializedName: "PrincipalInRoleOnObjectItem",
    type: {
        name: "Composite",
        className: "PrincipalInRoleOnObjectItem",
        modelProperties: {
            objectUniqueId: {
                serializedName: "objectUniqueId",
                type: {
                    name: "String"
                }
            },
            originObjectUniqueId: {
                serializedName: "originObjectUniqueId",
                type: {
                    name: "String"
                }
            },
            principalId: {
                required: true,
                serializedName: "principalId",
                type: {
                    name: "Number"
                }
            },
            principalDisplayName: {
                serializedName: "principalDisplayName",
                type: {
                    name: "String"
                }
            },
            principalCodeName: {
                serializedName: "principalCodeName",
                type: {
                    name: "String"
                }
            },
            principalEmail: {
                serializedName: "principalEmail",
                type: {
                    name: "String"
                }
            },
            roleId: {
                required: true,
                serializedName: "roleId",
                type: {
                    name: "Number"
                }
            },
            roleDisplayName: {
                serializedName: "roleDisplayName",
                type: {
                    name: "String"
                }
            },
            isInherited: {
                required: true,
                serializedName: "isInherited",
                type: {
                    name: "Boolean"
                }
            },
            link: {
                serializedName: "link",
                type: {
                    name: "String"
                }
            }
        }
    }
};
export var ActivityStatisticsModel = {
    serializedName: "ActivityStatisticsModel",
    type: {
        name: "Composite",
        className: "ActivityStatisticsModel",
        modelProperties: {
            serverName: {
                required: true,
                serializedName: "serverName",
                type: {
                    name: "String"
                }
            },
            userName: {
                required: true,
                serializedName: "userName",
                type: {
                    name: "String"
                }
            },
            timestampUtc: {
                required: true,
                serializedName: "timestampUtc",
                type: {
                    name: "DateTime"
                }
            },
            isUserOnline: {
                required: true,
                serializedName: "isUserOnline",
                type: {
                    name: "Boolean"
                }
            },
            actionType: {
                required: true,
                serializedName: "actionType",
                type: {
                    name: "String"
                }
            },
            description: {
                required: true,
                serializedName: "description",
                type: {
                    name: "String"
                }
            },
            duration: {
                serializedName: "duration",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export var GraphPointModel = {
    serializedName: "GraphPointModel",
    type: {
        name: "Composite",
        className: "GraphPointModel",
        modelProperties: {
            key: {
                required: true,
                serializedName: "key",
                type: {
                    name: "String"
                }
            },
            value: {
                required: true,
                serializedName: "value",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export var LineGraphModel = {
    serializedName: "LineGraphModel",
    type: {
        name: "Composite",
        className: "LineGraphModel",
        modelProperties: {
            label: {
                required: true,
                serializedName: "label",
                type: {
                    name: "String"
                }
            },
            yAxisId: {
                required: true,
                serializedName: "yAxisId",
                type: {
                    name: "String"
                }
            },
            points: {
                required: true,
                serializedName: "points",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "GraphPointModel"
                        }
                    }
                }
            }
        }
    }
};
export var ActivityStatisticsGraphModel = {
    serializedName: "ActivityStatisticsGraphModel",
    type: {
        name: "Composite",
        className: "ActivityStatisticsGraphModel",
        modelProperties: {
            serverName: {
                required: true,
                serializedName: "serverName",
                type: {
                    name: "String"
                }
            },
            graphData: {
                required: true,
                serializedName: "graphData",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "LineGraphModel"
                        }
                    }
                }
            }
        }
    }
};
export var RequestStatisticsModel = {
    serializedName: "RequestStatisticsModel",
    type: {
        name: "Composite",
        className: "RequestStatisticsModel",
        modelProperties: {
            dateTimeUtc: {
                required: true,
                serializedName: "dateTimeUtc",
                type: {
                    name: "DateTime"
                }
            },
            serverName: {
                serializedName: "serverName",
                type: {
                    name: "String"
                }
            },
            requestMethod: {
                serializedName: "requestMethod",
                type: {
                    name: "String"
                }
            },
            actionCode: {
                serializedName: "actionCode",
                type: {
                    name: "String"
                }
            },
            requestUrl: {
                serializedName: "requestUrl",
                type: {
                    name: "String"
                }
            },
            bestDuration: {
                required: true,
                serializedName: "bestDuration",
                type: {
                    name: "Number"
                }
            },
            averageDuration: {
                required: true,
                serializedName: "averageDuration",
                type: {
                    name: "Number"
                }
            },
            worstDuration: {
                required: true,
                serializedName: "worstDuration",
                type: {
                    name: "Number"
                }
            },
            totalCount: {
                required: true,
                serializedName: "totalCount",
                type: {
                    name: "Number"
                }
            },
            totalCountMoreThanHalfASecond: {
                required: true,
                serializedName: "totalCountMoreThanHalfASecond",
                type: {
                    name: "Number"
                }
            },
            percentage: {
                required: true,
                serializedName: "percentage",
                type: {
                    name: "Number"
                }
            },
            impact: {
                required: true,
                serializedName: "impact",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export var RequestStatisticsGraphModel = {
    serializedName: "RequestStatisticsGraphModel",
    type: {
        name: "Composite",
        className: "RequestStatisticsGraphModel",
        modelProperties: {
            serverName: {
                required: true,
                serializedName: "serverName",
                type: {
                    name: "String"
                }
            },
            graphData: {
                required: true,
                serializedName: "graphData",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "LineGraphModel"
                        }
                    }
                }
            }
        }
    }
};
export var JobStatisticsModel = {
    serializedName: "JobStatisticsModel",
    type: {
        name: "Composite",
        className: "JobStatisticsModel",
        modelProperties: {
            dateTimeUtc: {
                required: true,
                serializedName: "dateTimeUtc",
                type: {
                    name: "DateTime"
                }
            },
            serverName: {
                serializedName: "serverName",
                type: {
                    name: "String"
                }
            },
            jobType: {
                serializedName: "jobType",
                type: {
                    name: "String"
                }
            },
            bestDuration: {
                required: true,
                serializedName: "bestDuration",
                type: {
                    name: "Number"
                }
            },
            averageDuration: {
                required: true,
                serializedName: "averageDuration",
                type: {
                    name: "Number"
                }
            },
            worstDuration: {
                required: true,
                serializedName: "worstDuration",
                type: {
                    name: "Number"
                }
            },
            totalCount: {
                required: true,
                serializedName: "totalCount",
                type: {
                    name: "Number"
                }
            },
            totalRunningTime: {
                required: true,
                serializedName: "totalRunningTime",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export var JobStatisticsGraphModel = {
    serializedName: "JobStatisticsGraphModel",
    type: {
        name: "Composite",
        className: "JobStatisticsGraphModel",
        modelProperties: {
            serverName: {
                required: true,
                serializedName: "serverName",
                type: {
                    name: "String"
                }
            },
            graphData: {
                required: true,
                serializedName: "graphData",
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "LineGraphModel"
                        }
                    }
                }
            }
        }
    }
};
export var UserStatisticsModel = {
    serializedName: "UserStatisticsModel",
    type: {
        name: "Composite",
        className: "UserStatisticsModel",
        modelProperties: {
            serverName: {
                serializedName: "serverName",
                type: {
                    name: "String"
                }
            },
            userName: {
                serializedName: "userName",
                type: {
                    name: "String"
                }
            },
            isOnline: {
                required: true,
                serializedName: "isOnline",
                type: {
                    name: "Boolean"
                }
            },
            isUniqueConnected: {
                required: true,
                serializedName: "isUniqueConnected",
                type: {
                    name: "Boolean"
                }
            },
            requestsCount: {
                required: true,
                serializedName: "requestsCount",
                type: {
                    name: "Number"
                }
            }
        }
    }
};
export var ProjectTemplateModel = {
    serializedName: "ProjectTemplateModel",
    type: {
        name: "Composite",
        className: "ProjectTemplateModel",
        modelProperties: {
            id: {
                required: true,
                serializedName: "id",
                type: {
                    name: "Number"
                }
            },
            code: {
                serializedName: "code",
                type: {
                    name: "String"
                }
            },
            displayName: {
                serializedName: "displayName",
                type: {
                    name: "String"
                }
            },
            description: {
                serializedName: "description",
                type: {
                    name: "String"
                }
            },
            orgUnitId: {
                required: true,
                serializedName: "orgUnitId",
                type: {
                    name: "Number"
                }
            },
            orgUnitName: {
                serializedName: "orgUnitName",
                type: {
                    name: "String"
                }
            },
            projectType: {
                serializedName: "projectType",
                type: {
                    name: "String"
                }
            },
            version: {
                serializedName: "version",
                type: {
                    name: "String"
                }
            },
            projectTemplateType: {
                required: true,
                serializedName: "projectTemplateType",
                type: {
                    name: "String"
                }
            },
            templateType: {
                readOnly: true,
                serializedName: "templateType",
                type: {
                    name: "String"
                }
            },
            isValid: {
                required: true,
                serializedName: "isValid",
                type: {
                    name: "Boolean"
                }
            },
            isEnabled: {
                required: true,
                serializedName: "isEnabled",
                type: {
                    name: "Boolean"
                }
            },
            isDefault: {
                required: true,
                serializedName: "isDefault",
                type: {
                    name: "Boolean"
                }
            },
            canBeRemoved: {
                required: true,
                serializedName: "canBeRemoved",
                type: {
                    name: "Boolean"
                }
            },
            gateDisplayNames: {
                serializedName: "gateDisplayNames",
                type: {
                    name: "String"
                }
            }
        }
    }
};
