var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styles from './ApprovedChecklistQuestionsTable.module.scss';
import columnSharedStyles from '@app/Components/Grid/SharedStyles/column-shared-styles.module.scss';
import { Column } from 'devextreme-react/data-grid';
import { ChecklistSections, Events, GateProcessTypeEnum, ObjectType, SignalREvents, StatusEnum } from '@app/AppConstants';
import { EditCell } from '@app/Components/Grid/EditCell/EditCell';
import { action, observable } from 'mobx';
import { createUniqueId } from '@app/Helpers/UidHelper/UidHelper';
import { Api } from '@app/Api';
import { AssessorRatingCell } from '../ApprovedChecklistDeliversTable/AssessorRaitingCell/AssessorRaitingCell';
import { observer } from 'mobx-react';
import { RatingCell } from '@app/Components/Grid/RatingCell/RatingCell';
import HTMLReactParser from 'html-react-parser';
import { signalRService } from '@app/Services/SignalRService';
import { ChangeLogDialog } from '@app/Components/Dialogs/ChangeLogDialog';
import { CodeCell } from '@app/Components/Grid/CodeCell/CodeCell';
import { FunctionList } from '@app/Components/Grid/FunctionList/FunctionList';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import { Button } from '@app/Components/Button/Button';
import { ImportantCommentWrapepr } from '@app/Components/ImportantCommentWrapper/ImportantCommentWrapper';
import { AddActionItemModal } from '@app/Widgets/AddActionItemModal/AddActionItemModal';
import { Link } from 'react-router-dom';
import { ExternalProviderButton } from '@app/Pages/ProjectView/ProjectViewTabs/GatePreparations/ApprovedGateView/ApprovedChecklistView/ExternalProviderButton/ExternalProviderButton';
import { Grid } from '@app/Components/Grid/Grid';
import { LoadingContext } from '@app/Context/LoadingContext';
import { Comment } from '@app/Components/Grid/CommentsSummary/Comment';
export var ApprovedChecklistQuestionsTable = /** @class */ (function (_super) {
    __extends(ApprovedChecklistQuestionsTable, _super);
    function ApprovedChecklistQuestionsTable() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._questions = [];
        _this._currentChecklistitem = null;
        _this._loading = false;
        _this._gridRef = React.createRef();
        _this._isAddActionItemOpen = false;
        _this._selectedActionItemModalData = null;
        _this._handleChecklistStatusChanged = function (eventName, data) { return __awaiter(_this, void 0, void 0, function () {
            var checklist;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        checklist = this.props.checklist;
                        if (!(data === checklist.id)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._loadQuestions()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    ApprovedChecklistQuestionsTable.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        signalRService.subscribe(SignalREvents.recommendationChanged, this._loadQuestions);
                        signalRService.subscribe(SignalREvents.checklistStatusChanged, this._handleChecklistStatusChanged);
                        document.addEventListener(Events.questionsConfirm, this._loadQuestions);
                        return [4 /*yield*/, this._loadQuestions()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistQuestionsTable.prototype.componentWillUnmount = function () {
        signalRService.unsubscribe(SignalREvents.recommendationChanged, this._loadQuestions);
        signalRService.unsubscribe(SignalREvents.checklistStatusChanged, this._handleChecklistStatusChanged);
        document.removeEventListener(Events.questionsConfirm, this._loadQuestions);
    };
    ApprovedChecklistQuestionsTable.prototype._loadQuestions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, , 2, 3]);
                        this._loading = true;
                        _a = this;
                        return [4 /*yield*/, Api.secured.getQuestionApprovedChecklistItems({
                                checkListId: this.props.checklist.id
                            })];
                    case 1:
                        _a._questions = _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this._loading = false;
                        return [7 /*endfinally*/];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistQuestionsTable.prototype._toggleLogModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._currentChecklistitem = null;
                return [2 /*return*/];
            });
        });
    };
    ApprovedChecklistQuestionsTable.prototype._saveChecklistFunction = function (rowData) {
        return __awaiter(this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 6]);
                        return [4 /*yield*/, Api.secured.updateFunctionListGatePreparationItem({ body: rowData })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.props.onRefreshCheckListInfo()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 3:
                        error_1 = _a.sent();
                        if (!error_1) return [3 /*break*/, 5];
                        console.error(error_1);
                        return [4 /*yield*/, this._loadQuestions()];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistQuestionsTable.prototype._saveChecklistFunctionRating = function (rowData) {
        return __awaiter(this, void 0, void 0, function () {
            var error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 7]);
                        return [4 /*yield*/, Api.secured.updateFunctionListGatePreparationItem({ body: rowData })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.props.onRefreshChecklistStatusOverview()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.props.onRefreshCheckListInfo()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 7];
                    case 4:
                        error_2 = _a.sent();
                        if (!error_2) return [3 /*break*/, 6];
                        console.error(error_2);
                        return [4 /*yield*/, this._loadQuestions()];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6: return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistQuestionsTable.prototype._saveChecklistAssessor = function (rowData) {
        return __awaiter(this, void 0, void 0, function () {
            var error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 6]);
                        return [4 /*yield*/, Api.secured.updateAssessorGatePreparationItem({ body: rowData })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.props.onRefreshCheckListInfo()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 3:
                        error_3 = _a.sent();
                        if (!error_3) return [3 /*break*/, 5];
                        console.error(error_3);
                        return [4 /*yield*/, this._loadQuestions()];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistQuestionsTable.prototype._saveChecklistAssessorRating = function (rowData) {
        return __awaiter(this, void 0, void 0, function () {
            var error_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 7]);
                        return [4 /*yield*/, Api.secured.updateAssessorGatePreparationItem({ body: rowData })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.props.onRefreshChecklistStatusOverview()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.props.onRefreshCheckListInfo()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 7];
                    case 4:
                        error_4 = _a.sent();
                        if (!error_4) return [3 /*break*/, 6];
                        console.error(error_4);
                        return [4 /*yield*/, this._loadQuestions()];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6: return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistQuestionsTable.prototype._saveChecklistPMRating = function (rowData) {
        return __awaiter(this, void 0, void 0, function () {
            var error_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 7]);
                        return [4 /*yield*/, Api.secured.updatePrjManagerPartGatePreparationItem({ body: rowData })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.props.onRefreshChecklistStatusOverview()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.props.onRefreshCheckListInfo()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 7];
                    case 4:
                        error_5 = _a.sent();
                        if (!error_5) return [3 /*break*/, 6];
                        console.error(error_5);
                        return [4 /*yield*/, this._loadQuestions()];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6: return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistQuestionsTable.prototype._saveChecklistPM = function (rowData) {
        return __awaiter(this, void 0, void 0, function () {
            var error_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 6]);
                        return [4 /*yield*/, Api.secured.updatePrjManagerPartGatePreparationItem({ body: rowData })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.props.onRefreshCheckListInfo()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 3:
                        error_6 = _a.sent();
                        if (!error_6) return [3 /*break*/, 5];
                        console.error(error_6);
                        return [4 /*yield*/, this._loadQuestions()];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistQuestionsTable.prototype._handleAssessorRatingCellChange = function (rowData, rating, rowIndex) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (rowData.assessorRating !== rating) {
                    rowData.assessorRating = rating;
                    if (this._gridRef.current) {
                        this._gridRef.current.repaintRows([rowIndex]);
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    ApprovedChecklistQuestionsTable.prototype._handlePrjManagerRatingCellChange = function (rowData, rating, rowIndex) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (rowData.projectManagerRating !== rating) {
                    rowData.projectManagerRating = rating;
                    if (this._gridRef.current) {
                        this._gridRef.current.repaintRows([rowIndex]);
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    ApprovedChecklistQuestionsTable.prototype._saveNeedAttentionPMPart = function (checkListItemId, value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.updatePrjManagerNeedAttention({ checkListItemId: checkListItemId, needAttention: value })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._loadQuestions()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistQuestionsTable.prototype._saveNeedAttentionAssessorPart = function (checkListItemId, value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.secured.updateAssessorNeedAttention({ checkListItemId: checkListItemId, needAttention: value })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._loadQuestions()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistQuestionsTable.prototype._saveOkAssessorPart = function (checkListItemId, rowData, rowIndex) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var row;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Api.secured.okAssessorPartGatePreparationItem({ checkListItemId: checkListItemId })];
                    case 1:
                        row = _b.sent();
                        (_a = Object.keys(rowData)) === null || _a === void 0 ? void 0 : _a.forEach(function (key) {
                            rowData[key] = row[key];
                        });
                        if (this._gridRef.current) {
                            this._gridRef.current.repaintRows([rowIndex]);
                        }
                        return [4 /*yield*/, Promise.all([this.props.onRefreshChecklistStatusOverview(), this.props.onRefreshCheckListInfo()])];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistQuestionsTable.prototype._saveOkPMPart = function (checkListItemId, rowData, rowIndex) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var row;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Api.secured.okPrjManagerPartGatePreparationItem({ checkListItemId: checkListItemId })];
                    case 1:
                        row = _b.sent();
                        (_a = Object.keys(rowData)) === null || _a === void 0 ? void 0 : _a.forEach(function (key) {
                            rowData[key] = row[key];
                        });
                        if (this._gridRef.current) {
                            this._gridRef.current.repaintRows([rowIndex]);
                        }
                        return [4 /*yield*/, Promise.all([this.props.onRefreshChecklistStatusOverview(), this.props.onRefreshCheckListInfo()])];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistQuestionsTable.prototype._toggleAddActionItemModal = function (isAssessor, rowData) {
        if (rowData) {
            var assessorComment = rowData.assessorComment, projectManagerComment = rowData.projectManagerComment;
            var actionText = isAssessor ? assessorComment : projectManagerComment;
            this._selectedActionItemModalData = this._isAddActionItemOpen
                ? null
                : { row: rowData, isAssessor: isAssessor, actionText: actionText };
        }
        this._isAddActionItemOpen = !this._isAddActionItemOpen;
    };
    ApprovedChecklistQuestionsTable.prototype._handleNeedAttentionToggle = function (id, needAttention, isAssessor) {
        return __awaiter(this, void 0, void 0, function () {
            var row, _a, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        row = this._questions.find(function (r) { return r.id === id; });
                        if (!isAssessor) return [3 /*break*/, 5];
                        if (row)
                            row.needAttention = needAttention;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this._saveNeedAttentionAssessorPart(id, needAttention)];
                    case 2:
                        _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        _a = _b.sent();
                        if (row)
                            row.needAttention = !needAttention;
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 9];
                    case 5:
                        if (row)
                            row.projectManagerNeedAttention = needAttention;
                        _b.label = 6;
                    case 6:
                        _b.trys.push([6, 8, , 9]);
                        return [4 /*yield*/, this._saveNeedAttentionPMPart(id, needAttention)];
                    case 7:
                        _b.sent();
                        return [3 /*break*/, 9];
                    case 8:
                        e_1 = _b.sent();
                        if (row)
                            row.projectManagerNeedAttention = !needAttention;
                        return [3 /*break*/, 9];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    ApprovedChecklistQuestionsTable.prototype.render = function () {
        var _a;
        var _this = this;
        var projectId = (_a = this.props, _a.projectId), projectCodeName = _a.projectCodeName, gateId = _a.gateId, gateCodeName = _a.gateCodeName, checklist = _a.checklist, isProjectTerminatedOrCompleted = _a.isProjectTerminatedOrCompleted;
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid, { ref: this._gridRef, dataSource: __spreadArray([], this._questions, true), expandableRows: true, rowsCount: this._questions.length, loading: this._loading },
                React.createElement(Column, { caption: "ID", dataField: "code", width: 150, allowEditing: false, cssClass: columnSharedStyles.colWithHistory, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(CodeCell, { code: data.code, onHistoryClick: function () { return (_this._currentChecklistitem = data); } }, data.canPrincipalUpdateFunctionalPart && (React.createElement(ExternalProviderButton, { projectName: projectCodeName, projectId: projectId, gateId: gateId, gateCodeName: gateCodeName, checkListItemId: data.id, taskId: data.taskId || null, taskStatus: data.taskStatus, onRefresh: _this._loadQuestions, disable: isProjectTerminatedOrCompleted }))));
                    } }),
                React.createElement(Column, { caption: "Functions", dataField: "functionNames", width: 90, cellRender: function (_a) {
                        var data = _a.data;
                        if (!data.functionNames)
                            return null;
                        return React.createElement(FunctionList, { functions: data.functionNames, rowId: '' + data.id });
                    } }),
                React.createElement(Column, { caption: "Question", dataField: "questionTitle", allowEditing: false, minWidth: 340, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement("div", null,
                            React.createElement("div", { className: styles.questionTitle }, data.questionTitle),
                            React.createElement("div", null, HTMLReactParser(data.question || ''))));
                    } }),
                React.createElement(Column, { caption: "Guideline", dataField: "guideline", allowEditing: false, minWidth: 250, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement("div", { style: { whiteSpace: 'pre-line' } }, HTMLReactParser(data.guideline || '')));
                    } }),
                React.createElement(Column, { cssClass: columnSharedStyles.commentColumn, caption: "Function Answer", width: 250, dataField: "comment", cellRender: function (_a) {
                        var data = _a.data, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
                        return (React.createElement(EditCell, { value: data.comment, onChange: function (value) {
                                data.comment = value;
                                if (_this._gridRef.current) {
                                    _this._gridRef.current.repaintRows([rowIndex]);
                                }
                            }, rowIndex: rowIndex, columnIndex: columnIndex, onSave: function () { return _this._saveChecklistFunction(data); }, objectUniqueId: createUniqueId(ObjectType.ChecklistItem, data.id), section: ChecklistSections.Function, disabled: !data.canPrincipalUpdateFunctionalPart || isProjectTerminatedOrCompleted }));
                    } }),
                React.createElement(Column, { headerCellRender: function () { return React.createElement("div", { title: "Function Rating" }, "Rate"); }, width: 100, dataField: "rating", cssClass: styles.ratingColumn, cellRender: function (_a) {
                        var data = _a.data, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
                        return (React.createElement(RatingCell, { onSave: function () { return _this._saveChecklistFunctionRating(data); }, onChange: function (value) {
                                data.rating = value;
                                if (_this._gridRef.current) {
                                    _this._gridRef.current.repaintRows([rowIndex]);
                                }
                            }, showValidationError: true, rating: data.rating, text: data.comment, textTitle: "Answer", rowIndex: rowIndex, columnIndex: columnIndex, objectUniqueId: createUniqueId(ObjectType.ChecklistItem, data.id), disabled: !data.canPrincipalUpdateFunctionalPart || isProjectTerminatedOrCompleted, section: ChecklistSections.Function, controlId: "fRating".concat(data.id) }));
                    } }),
                React.createElement(Column, { cssClass: columnSharedStyles.commentColumn, caption: "Project Manager Comment", width: 250, dataField: "projectManagerComment", cellRender: function (_a) {
                        var data = _a.data, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
                        return (React.createElement(ImportantCommentWrapepr, { isImportant: data.projectManagerNeedAttention },
                            React.createElement(EditCell, { value: data.projectManagerComment, rowIndex: rowIndex, columnIndex: columnIndex, onChange: function (value) {
                                    var _a;
                                    data.projectManagerComment = value;
                                    if (_this._gridRef.current) {
                                        (_a = _this._gridRef.current) === null || _a === void 0 ? void 0 : _a.repaintRows([rowIndex]);
                                    }
                                }, onSave: function () { return _this._saveChecklistPM(data); }, objectUniqueId: createUniqueId(ObjectType.ChecklistItem, data.id), section: ChecklistSections.ProjectManager, disabled: !data.canPrincipalUpdatePrjManagerPart || isProjectTerminatedOrCompleted })));
                    } }),
                React.createElement(Column, { headerCellRender: function () { return React.createElement("div", { title: "Project Manager Rating" }, "Rate"); }, width: 70, dataField: "projectManagerRating", cssClass: styles.projectManagerRatingCell, cellRender: function (_a) {
                        var data = _a.data, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
                        return (React.createElement(AssessorRatingCell, { onSave: function () { return _this._saveChecklistPMRating(data); }, onSaveOk: function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this._saveOkPMPart(data.id, data, rowIndex)];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); }, rowIndex: rowIndex, columnIndex: columnIndex, onChange: function (changedData) {
                                return _this._handlePrjManagerRatingCellChange(data, changedData, rowIndex);
                            }, showValidationErrors: checklist.status === StatusEnum.Draft, rating: data.projectManagerRating, text: data.projectManagerComment, textTitle: "Comment", objectUniqueId: createUniqueId(ObjectType.ChecklistItem, data.id), section: ChecklistSections.ProjectManager, isOkButtonVisible: true, disabled: !data.canPrincipalUpdatePrjManagerPart || isProjectTerminatedOrCompleted }));
                    } }),
                React.createElement(Column, { headerCellRender: function () { return (React.createElement("div", { title: "Need Attention" },
                        React.createElement(BsFillExclamationTriangleFill, { color: "orange" }))); }, width: 30, dataField: "projectManagerNeedAttention", alignment: "center", cssClass: styles.projectManagerRatingCell, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement("input", { type: "checkbox", title: "Need Attention", checked: data.projectManagerNeedAttention, disabled: !data.canPrincipalUpdatePrjManagerPart || isProjectTerminatedOrCompleted, onChange: function () {
                                return _this._handleNeedAttentionToggle(data.id, !data.projectManagerNeedAttention, false);
                            } }));
                    } }),
                React.createElement(Column, { width: 110, caption: "PM Actions", cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(React.Fragment, null,
                            data.canAddActionItem && (React.createElement(Button, { disabled: isProjectTerminatedOrCompleted, onClick: function () { return _this._toggleAddActionItemModal(false, data); } }, "Action Item")),
                            !!(data.actionItemsCount && data.actionItemsCount > 0) && (React.createElement("div", null,
                                React.createElement(Link, { to: "/projects/".concat(projectCodeName, "/assessments/g-").concat(gateId) },
                                    data.actionItemsCount,
                                    " Action Item (s)"))),
                            !!data.actionItemsDraftCount && (React.createElement(Comment, { id: "actionItemsDraft_".concat(data.id), errorText: "".concat(data.actionItemsDraftCount, " Draft.") })),
                            !!data.actionItemsInApprovalCount && (React.createElement(Comment, { id: "actionItemsInApproval_".concat(data.id), errorText: "".concat(data.actionItemsInApprovalCount, " In Approval.") })),
                            !!data.actionItemsApprovedCount && (React.createElement(Comment, { id: "actionItemsApproved_".concat(data.id), errorText: "".concat(data.actionItemsApprovedCount, " Approved.") })),
                            !!data.actionItemsNotApplicableCount && (React.createElement(Comment, { id: "actionItemsNotApplicable_".concat(data.id), errorText: "".concat(data.actionItemsNotApplicableCount, " Not Applicable.") }))));
                    } }),
                React.createElement(Column, { cssClass: columnSharedStyles.commentColumn, caption: "Gate Assessor Comment", width: 300, dataField: "assessorComment", cellRender: function (_a) {
                        var data = _a.data, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
                        return (React.createElement(ImportantCommentWrapepr, { isImportant: data.needAttention },
                            React.createElement(EditCell, { value: data.assessorComment, onChange: function (value) {
                                    data.assessorComment = value;
                                    if (_this._gridRef.current) {
                                        _this._gridRef.current.repaintRows([rowIndex]);
                                    }
                                }, rowIndex: rowIndex, columnIndex: columnIndex, onSave: function () { return _this._saveChecklistAssessor(data); }, objectUniqueId: createUniqueId(ObjectType.ChecklistItem, data.id), section: ChecklistSections.Assessor, disabled: !data.canPrincipalUpdateAssessorPart || isProjectTerminatedOrCompleted })));
                    } }),
                React.createElement(Column, { headerCellRender: function () { return React.createElement("div", { title: "Assessor Rating" }, "Rate"); }, width: 70, dataField: "assessorRating", cssClass: styles.projectManagerRatingCell, cellRender: function (_a) {
                        var data = _a.data, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
                        return (React.createElement(AssessorRatingCell, { onSave: function () { return _this._saveChecklistAssessorRating(data); }, onSaveOk: function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this._saveOkAssessorPart(data.id, data, rowIndex)];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); }, onChange: function (changedData) {
                                return _this._handleAssessorRatingCellChange(data, changedData, rowIndex);
                            }, showValidationErrors: checklist.status === StatusEnum.Draft, rating: data.assessorRating, rowIndex: rowIndex, columnIndex: columnIndex, objectUniqueId: createUniqueId(ObjectType.ChecklistItem, data.id), section: ChecklistSections.Assessor, isOkButtonVisible: true, disabled: !data.canPrincipalUpdateAssessorPart || isProjectTerminatedOrCompleted, text: data.assessorComment, textTitle: "Comment" }));
                    } }),
                React.createElement(Column, { headerCellRender: function () { return (React.createElement("div", { title: "Need Attention" },
                        React.createElement(BsFillExclamationTriangleFill, { color: "orange" }))); }, width: 30, dataField: "needAttention", alignment: "center", 
                    // cssClass={styles.projectManagerRatingCell}
                    cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement("input", { type: "checkbox", title: "Need Attention", checked: data.needAttention, disabled: !data.canPrincipalUpdateAssessorPart || isProjectTerminatedOrCompleted, onChange: function () { return _this._handleNeedAttentionToggle(data.id, !data.needAttention, true); } }));
                    } }),
                React.createElement(Column, { width: 110, caption: "GA Actions", cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(React.Fragment, null,
                            data.canAddActionItem && (React.createElement(Button, { disabled: isProjectTerminatedOrCompleted, onClick: function () { return _this._toggleAddActionItemModal(true, data); } }, "Action Item")),
                            !!(data.actionItemsCount && data.actionItemsCount > 0) && (React.createElement("div", null,
                                React.createElement(Link, { to: "/projects/".concat(projectCodeName, "/assessments/g-").concat(gateId) },
                                    data.actionItemsCount,
                                    " Action Item (s)")))));
                    } })),
            this._isAddActionItemOpen && this._selectedActionItemModalData && (React.createElement(AddActionItemModal, { projectId: projectId, gateId: gateId, selectedChecklistId: checklist.id, selectedChecklistItemId: this._selectedActionItemModalData.row.id, gateProcessType: GateProcessTypeEnum.GateAssessment, isOpen: this._isAddActionItemOpen, onToggle: function () { var _a; return _this._toggleAddActionItemModal(!!((_a = _this._selectedActionItemModalData) === null || _a === void 0 ? void 0 : _a.isAssessor)); }, onSave: this._loadQuestions, actionText: this._selectedActionItemModalData.actionText, selectedFunction: this._selectedActionItemModalData.row.functionNames &&
                    this._selectedActionItemModalData.row.functionNames.length === 1
                    ? this._selectedActionItemModalData.row.functionNames[0]
                    : undefined })),
            this._currentChecklistitem && (React.createElement(ChangeLogDialog, { onToggle: this._toggleLogModal, uniqueId: createUniqueId(ObjectType.ChecklistItem, this._currentChecklistitem.id) }))));
    };
    ApprovedChecklistQuestionsTable.contextType = LoadingContext;
    __decorate([
        observable
    ], ApprovedChecklistQuestionsTable.prototype, "_questions", void 0);
    __decorate([
        observable
    ], ApprovedChecklistQuestionsTable.prototype, "_currentChecklistitem", void 0);
    __decorate([
        observable
    ], ApprovedChecklistQuestionsTable.prototype, "_loading", void 0);
    __decorate([
        observable.ref
    ], ApprovedChecklistQuestionsTable.prototype, "_gridRef", void 0);
    __decorate([
        observable
    ], ApprovedChecklistQuestionsTable.prototype, "_isAddActionItemOpen", void 0);
    __decorate([
        observable
    ], ApprovedChecklistQuestionsTable.prototype, "_selectedActionItemModalData", void 0);
    __decorate([
        action.bound
    ], ApprovedChecklistQuestionsTable.prototype, "_loadQuestions", null);
    __decorate([
        action.bound
    ], ApprovedChecklistQuestionsTable.prototype, "_toggleLogModal", null);
    __decorate([
        action.bound
    ], ApprovedChecklistQuestionsTable.prototype, "_saveChecklistFunction", null);
    __decorate([
        action.bound
    ], ApprovedChecklistQuestionsTable.prototype, "_saveChecklistFunctionRating", null);
    __decorate([
        action.bound
    ], ApprovedChecklistQuestionsTable.prototype, "_saveChecklistAssessor", null);
    __decorate([
        action.bound
    ], ApprovedChecklistQuestionsTable.prototype, "_saveChecklistAssessorRating", null);
    __decorate([
        action.bound
    ], ApprovedChecklistQuestionsTable.prototype, "_saveChecklistPMRating", null);
    __decorate([
        action.bound
    ], ApprovedChecklistQuestionsTable.prototype, "_saveChecklistPM", null);
    __decorate([
        action.bound
    ], ApprovedChecklistQuestionsTable.prototype, "_handleAssessorRatingCellChange", null);
    __decorate([
        action.bound
    ], ApprovedChecklistQuestionsTable.prototype, "_handlePrjManagerRatingCellChange", null);
    __decorate([
        action.bound
    ], ApprovedChecklistQuestionsTable.prototype, "_saveNeedAttentionPMPart", null);
    __decorate([
        action.bound
    ], ApprovedChecklistQuestionsTable.prototype, "_saveNeedAttentionAssessorPart", null);
    __decorate([
        action.bound
    ], ApprovedChecklistQuestionsTable.prototype, "_saveOkAssessorPart", null);
    __decorate([
        action.bound
    ], ApprovedChecklistQuestionsTable.prototype, "_saveOkPMPart", null);
    __decorate([
        action.bound
    ], ApprovedChecklistQuestionsTable.prototype, "_toggleAddActionItemModal", null);
    __decorate([
        action.bound
    ], ApprovedChecklistQuestionsTable.prototype, "_handleNeedAttentionToggle", null);
    ApprovedChecklistQuestionsTable = __decorate([
        observer
    ], ApprovedChecklistQuestionsTable);
    return ApprovedChecklistQuestionsTable;
}(React.PureComponent));
