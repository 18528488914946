var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { observer } from 'mobx-react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { appStore } from '@app/AppStore';
import { Projects } from './Pages/Projects/Projects';
import { ProjectView } from './Pages/ProjectView/ProjectView';
import { DocumentPage } from './Pages/Document/DocumentPage';
import { Home } from './Pages/Home/Home';
import { NotFound } from './Pages/NotFound/NotFound';
import { NotAllow } from './Pages/NotAllow/NotAllow';
import { NorrdProjectsPage } from './Pages/NorrdProjectsPage/NorrdProjectsPage';
import { HealthBoard } from './Pages/HealthBoard/HealthBoard';
import { Templates } from './Pages/Templates/Templates';
import { JobList } from './Pages/JobList/JobList';
import { Configuration } from './Pages/Configuration/Configuration';
import RequestTimeline from './Pages/DevTools/RequestTimeline';
import { Security } from './Pages/Security/Security';
import { AppTasks } from './Pages/AppTasks/AppTasks';
import { Tasks } from './Pages/Tasks/Tasks';
import { Statistics } from './Pages/Statistics/Statistics';
import { ApiKeys } from './Pages/ApiKeys/ApiKeys';
var RouteWrapperC = /** @class */ (function (_super) {
    __extends(RouteWrapperC, _super);
    function RouteWrapperC() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RouteWrapperC.prototype.render = function () {
        var _a = appStore.appSecurityModel, canPrincipalViewTemplates = _a.canPrincipalViewTemplates, canPrincipalViewHealthBoard = _a.canPrincipalViewHealthBoard, canPrincipalViewJobList = _a.canPrincipalViewJobList, hasPrincipalAdminAccess = _a.hasPrincipalAdminAccess;
        return (React.createElement(Switch, null,
            React.createElement(Route, { path: "/", exact: true, component: Home }),
            canPrincipalViewHealthBoard && React.createElement(Route, { path: "/healthboard", component: HealthBoard }),
            React.createElement(Route, { path: "/tasks", component: Tasks }),
            React.createElement(Route, { path: "/projects/:codeName", component: ProjectView }),
            React.createElement(Route, { path: "/document/:fileId", component: DocumentPage }),
            React.createElement(Route, { path: "/projects", component: Projects }),
            canPrincipalViewTemplates && React.createElement(Route, { path: "/templates", component: Templates }),
            React.createElement(Route, { path: "/norrd-projects", component: NorrdProjectsPage }),
            canPrincipalViewJobList && React.createElement(Route, { path: "/jobs", component: JobList }),
            hasPrincipalAdminAccess && React.createElement(Route, { path: "/configuration", component: Configuration }),
            hasPrincipalAdminAccess && React.createElement(Route, { path: "/security", component: Security }),
            hasPrincipalAdminAccess && React.createElement(Route, { path: "/app-tasks", component: AppTasks }),
            hasPrincipalAdminAccess && React.createElement(Route, { path: "/statistics", component: Statistics }),
            hasPrincipalAdminAccess && React.createElement(Route, { path: "/api-keys", component: ApiKeys }),
            React.createElement(Route, { path: "/notallow", component: NotAllow }),
            React.createElement(Route, { path: "/notfound", component: NotFound }),
            hasPrincipalAdminAccess && React.createElement(Route, { path: "/rt", component: RequestTimeline }),
            React.createElement(Route, { path: "*", component: NotFound })));
    };
    RouteWrapperC = __decorate([
        observer
    ], RouteWrapperC);
    return RouteWrapperC;
}(React.PureComponent));
export var RouteWrapper = withRouter(RouteWrapperC);
