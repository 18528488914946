var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { TasksTable } from '@app/Widgets/TasksTable/TasksTable';
import { Link } from '@app/Components/Link/Link';
import { withRouter, Route, Switch } from 'react-router-dom';
import { Button } from '@app/Components/Button/Button';
import { Widget } from '@app/Components/Widget/Widget';
import styles from './Tasks.module.scss';
import { AddTaskModal } from '@app/Widgets/ManualTaskModals/AddTaskModal';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { Api } from '@app/Api';
import { Loader } from '@app/Components/Loader/Loader';
import { UpdateTaskModal } from '@app/Widgets/ManualTaskModals/UpdateTaskModal';
import { AppData } from '@app/AppConstants';
var TasksC = /** @class */ (function (_super) {
    __extends(TasksC, _super);
    function TasksC() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._project = null;
        return _this;
    }
    TasksC.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadTasks()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._loadProject()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TasksC.prototype._loadTasks = function () {
        return __awaiter(this, void 0, void 0, function () {
            var search, projectCodeName, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        search = this.props.location.search;
                        projectCodeName = new URLSearchParams(search).get(AppData.ProjectCodeNameQueryParameter);
                        _a = this;
                        return [4 /*yield*/, Api.secured.getManualTasks({
                                projectCodeName: projectCodeName || undefined
                            })];
                    case 1:
                        _a._tasks = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TasksC.prototype._loadProject = function () {
        return __awaiter(this, void 0, void 0, function () {
            var search, projectCodeName, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        search = this.props.location.search;
                        projectCodeName = new URLSearchParams(search).get(AppData.ProjectCodeNameQueryParameter);
                        if (!projectCodeName) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, Api.secured.getProject(projectCodeName)];
                    case 1:
                        _a._project = _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    TasksC.prototype._toggleModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, history, match, search;
            return __generator(this, function (_b) {
                _a = this.props, history = _a.history, match = _a.match, search = _a.location.search;
                history.push(match.url + search);
                return [2 /*return*/];
            });
        });
    };
    TasksC.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d;
        var _e = this.props, match = _e.match, search = _e.location.search;
        return (React.createElement(Widget, { title: "Project Tasks" },
            React.createElement(Link, { to: "".concat(match.url, "/add").concat(search), className: styles.addButtonWrap },
                React.createElement(Button, { size: "sm", color: "primary" }, "Create Task")),
            React.createElement("div", { className: styles.tableWrapper }, this._tasks ? (React.createElement(TasksTable, { onUpdate: this._loadTasks, tasks: this._tasks })) : (React.createElement(Loader, { style: { width: 100, height: 100 } }))),
            React.createElement(Switch, null,
                React.createElement(Route, { path: "".concat(match.url, "/add") },
                    React.createElement(AddTaskModal, { onToggle: this._toggleModal, projectId: (_a = this._project) === null || _a === void 0 ? void 0 : _a.id, afterSave: this._loadTasks, projectName: "".concat((_b = this._project) === null || _b === void 0 ? void 0 : _b.codeName, " - ").concat((_c = this._project) === null || _c === void 0 ? void 0 : _c.displayName), isOpen: true })), (_d = this._tasks) === null || _d === void 0 ? void 0 :
                _d.map(function (t) { return (React.createElement(Route, { key: t.id, path: "".concat(match.url, "/tsk-:id") },
                    React.createElement(UpdateTaskModal, { onToggle: _this._toggleModal, afterSave: _this._loadTasks, backUrl: match.url + search }))); }))));
    };
    __decorate([
        observable
    ], TasksC.prototype, "_tasks", void 0);
    __decorate([
        observable
    ], TasksC.prototype, "_project", void 0);
    __decorate([
        action.bound
    ], TasksC.prototype, "_loadTasks", null);
    __decorate([
        action.bound
    ], TasksC.prototype, "_loadProject", null);
    __decorate([
        action.bound
    ], TasksC.prototype, "_toggleModal", null);
    TasksC = __decorate([
        observer
    ], TasksC);
    return TasksC;
}(React.PureComponent));
export var Tasks = withRouter(TasksC);
