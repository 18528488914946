var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
//import styles from './MilestoneExecutiveQuestionsTable.module.scss';
import columnSharedStyles from '@app/Components/Grid/SharedStyles/column-shared-styles.module.scss';
import { observer } from 'mobx-react';
import { Grid } from '@app/Components/Grid/Grid';
import { Column } from 'devextreme-react/data-grid';
import { Api } from '@app/Api';
import { observable, action } from 'mobx';
import { FunctionFilterContext } from '@app/Components/FunctonFilter/FunctionFilterContext';
import { filterQuestionsTableRowsByFunction } from '@app/Components/FunctonFilter/FunctionFilter';
import { ChecklistSections, GateProcessTypeEnum, ObjectType, SignalREvents } from '@app/AppConstants';
import { RatingCell } from '@app/Components/Grid/RatingCell/RatingCell';
import { EditCell } from '@app/Components/Grid/EditCell/EditCell';
import { createUniqueId } from '@app/Helpers/UidHelper/UidHelper';
import HTMLReactParser from 'html-react-parser';
import { signalRService } from '@app/Services/SignalRService';
import { ChangeLogDialog } from '@app/Components/Dialogs/ChangeLogDialog';
import { AddActionItemWrapper } from '@app/Components/AddActionItemWrapper/AddActionItemWrapper';
import { CodeCell } from '@app/Components/Grid/CodeCell/CodeCell';
import { ExternalProviderButton } from '../../GatePreparations/ApprovedGateView/ApprovedChecklistView/ExternalProviderButton/ExternalProviderButton';
export var MilestoneExecutiveQuestionsTable = /** @class */ (function (_super) {
    __extends(MilestoneExecutiveQuestionsTable, _super);
    function MilestoneExecutiveQuestionsTable() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._executiveQuestions = [];
        _this._currentChecklistitem = null;
        _this._loading = false;
        _this._gridRef = React.createRef();
        return _this;
    }
    MilestoneExecutiveQuestionsTable.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadExecutiveQuestions()];
                    case 1:
                        _a.sent();
                        signalRService.subscribe(SignalREvents.decisionChanged, this._loadExecutiveQuestions);
                        signalRService.subscribe(SignalREvents.recommendationChanged, this._loadExecutiveQuestions);
                        return [2 /*return*/];
                }
            });
        });
    };
    MilestoneExecutiveQuestionsTable.prototype.componentWillUnmount = function () {
        signalRService.unsubscribe(SignalREvents.decisionChanged, this._loadExecutiveQuestions);
        signalRService.unsubscribe(SignalREvents.recommendationChanged, this._loadExecutiveQuestions);
    };
    MilestoneExecutiveQuestionsTable.prototype.componentDidUpdate = function (prevProps) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(prevProps.gateId !== this.props.gateId || prevProps.hideNAItems !== this.props.hideNAItems)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._loadExecutiveQuestions()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    MilestoneExecutiveQuestionsTable.prototype._saveQuestion = function (rowData) {
        return __awaiter(this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 5]);
                        return [4 /*yield*/, Api.secured.updateMilestoneQuestion({ body: rowData })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.props.onRefreshChecklistStatusOverview()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        console.error(error_1);
                        return [4 /*yield*/, this._loadExecutiveQuestions()];
                    case 4:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    MilestoneExecutiveQuestionsTable.prototype._loadExecutiveQuestions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var projectId, gateId, hideNAItems, _a;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        projectId = (_b = this.props, _b.projectId), gateId = _b.gateId, hideNAItems = _b.hideNAItems;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, , 3, 4]);
                        this._loading = true;
                        _a = this;
                        return [4 /*yield*/, Api.secured.getMilestoneQuestions({ projectId: projectId, gateId: gateId, hideNAItems: hideNAItems })];
                    case 2:
                        _a._executiveQuestions = _c.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        this._loading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    MilestoneExecutiveQuestionsTable.prototype._toggleLogModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._currentChecklistitem = null;
                return [2 /*return*/];
            });
        });
    };
    MilestoneExecutiveQuestionsTable.prototype._refreshQuestions = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._loadExecutiveQuestions()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.props.onRefreshChecklistStatusOverview()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MilestoneExecutiveQuestionsTable.prototype.render = function () {
        var _a;
        var _this = this;
        var projectId = (_a = this.props, _a.projectId), gateId = _a.gateId, projectCodeName = _a.projectCodeName, gateName = _a.gateName, onRefreshActionItems = _a.onRefreshActionItems, isProjectTerminatedOrCompleted = _a.isProjectTerminatedOrCompleted;
        var functionFilterValue = this.context;
        var rows = filterQuestionsTableRowsByFunction(this._executiveQuestions, functionFilterValue);
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid, { ref: this._gridRef, dataSource: __spreadArray([], rows, true), expandableRows: true, rowsCount: rows.length, loading: this._loading },
                React.createElement(Column, { caption: "ID", dataField: "code", width: 100, allowEditing: false, cssClass: columnSharedStyles.colWithHistory, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement(CodeCell, { code: data.code, onHistoryClick: function () { return (_this._currentChecklistitem = data); } }, data.canPrincipalUpdateProjectManagerPart && (React.createElement(ExternalProviderButton, { projectName: projectCodeName, projectId: projectId, gateId: gateId, gateCodeName: gateName, checkListItemId: data.id, taskId: data.taskId || null, taskStatus: data.taskStatus, onRefresh: _this._loadExecutiveQuestions, disable: isProjectTerminatedOrCompleted }))));
                    } }),
                React.createElement(Column, { caption: "Question / Expectation", dataField: "question", allowEditing: false, width: 350, cellRender: function (_a) {
                        var data = _a.data;
                        return React.createElement("div", null, HTMLReactParser(data.question || ''));
                    } }),
                React.createElement(Column, { caption: "Guideline", dataField: "guideline", allowEditing: false, minWidth: 300, cellRender: function (_a) {
                        var data = _a.data;
                        return (React.createElement("div", { style: { whiteSpace: 'pre-line' } }, HTMLReactParser(data.guideline || '')));
                    } }),
                React.createElement(Column, { caption: "Project Manager Answer", dataField: "projectManagementComment", width: 300, cssClass: columnSharedStyles.commentColumn, cellRender: function (_a) {
                        var data = _a.data, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
                        return (React.createElement(EditCell, { rowIndex: rowIndex, columnIndex: columnIndex, value: data.projectManagerOriginalComment, onChange: function (value) {
                                data.projectManagerOriginalComment = value;
                                if (_this._gridRef.current) {
                                    _this._gridRef.current.repaintRows([rowIndex]);
                                }
                            }, onSave: function () { return _this._saveQuestion(data); }, objectUniqueId: createUniqueId(ObjectType.ChecklistItem, data.id), withCopy: true, disabled: !data.canPrincipalUpdateProjectManagerPart || isProjectTerminatedOrCompleted, section: ChecklistSections.ProjectManager }));
                    } }),
                React.createElement(Column, { headerCellRender: function () { return React.createElement("div", { title: "Project Manager Rating" }, "Rating"); }, width: 110, cssClass: columnSharedStyles.commentColumn, cellRender: function (_a) {
                        var data = _a.data, rowIndex = _a.rowIndex, columnIndex = _a.columnIndex;
                        return (React.createElement(AddActionItemWrapper, { projectId: projectId, gateId: gateId, gateProcessType: GateProcessTypeEnum.GateMeeting, disabled: !data.canPrincipalAddActionItem || isProjectTerminatedOrCompleted, data: data, isForProjectManager: true, onActionItemSave: onRefreshActionItems },
                            React.createElement(RatingCell, { columnIndex: columnIndex, rowIndex: rowIndex, onSave: function () { return _this._saveQuestion(data); }, onChange: function (value) {
                                    data.projectManagerRating = value;
                                    if (_this._gridRef.current) {
                                        _this._gridRef.current.repaintRows([rowIndex]);
                                    }
                                }, rating: data.projectManagerRating, objectUniqueId: createUniqueId(ObjectType.ChecklistItem, data.id), disabled: !data.canPrincipalUpdateProjectManagerPart || isProjectTerminatedOrCompleted, section: ChecklistSections.ProjectManager, controlId: "pmRating".concat(data.id), text: data.projectManagerOriginalComment, textTitle: "Answer", showValidationError: data.canPrincipalUpdateProjectManagerPart })));
                    } })),
            this._currentChecklistitem && (React.createElement(ChangeLogDialog, { onToggle: this._toggleLogModal, uniqueId: createUniqueId(ObjectType.ChecklistItem, this._currentChecklistitem.id) }))));
    };
    MilestoneExecutiveQuestionsTable.contextType = FunctionFilterContext;
    __decorate([
        observable
    ], MilestoneExecutiveQuestionsTable.prototype, "_executiveQuestions", void 0);
    __decorate([
        observable
    ], MilestoneExecutiveQuestionsTable.prototype, "_currentChecklistitem", void 0);
    __decorate([
        observable
    ], MilestoneExecutiveQuestionsTable.prototype, "_loading", void 0);
    __decorate([
        observable.ref
    ], MilestoneExecutiveQuestionsTable.prototype, "_gridRef", void 0);
    __decorate([
        action.bound
    ], MilestoneExecutiveQuestionsTable.prototype, "_saveQuestion", null);
    __decorate([
        action.bound
    ], MilestoneExecutiveQuestionsTable.prototype, "_loadExecutiveQuestions", null);
    __decorate([
        action.bound
    ], MilestoneExecutiveQuestionsTable.prototype, "_toggleLogModal", null);
    __decorate([
        action.bound
    ], MilestoneExecutiveQuestionsTable.prototype, "_refreshQuestions", null);
    MilestoneExecutiveQuestionsTable = __decorate([
        observer
    ], MilestoneExecutiveQuestionsTable);
    return MilestoneExecutiveQuestionsTable;
}(React.PureComponent));
